import React from 'react';
import Init from './scenes/Init';
import Auth from './scenes/Auth';
import Dispatcher from './scenes/dispatcher/Dispatcher';
import Kitchen from './scenes/Kitchen/Kitchen';
import Weekly from './scenes/weekly';
import OrderForecast from './scenes/OrderForecast/OrderForecast';
import {
  ROLE_KITCHEN,
  ROLE_CHEF_SALAD,
  ROLE_CHEF_COLDBOWL,
  ROLE_CHEF_CLASSIC,
  ROLE_CHEF_CATERING,
  ROLE_CHEF_HOTBOWL,
  ROLE_STAFF
} from './config/constants';
import KitchenCountContextProvider from './scenes/KitchenCount/Context/Provider';
import VytalCountContextProvider from './scenes/VytalCount/Context/Provider';
import KitchenCount from './scenes/KitchenCount';
import VytalCount from './scenes/VytalCount';

export const layoutRoutes = [
  {
    path: '/kitchen',
    component: () => <Kitchen />,
    exact: true,
    name: 'kitchen',
    meta: {
      requireAuth: true,
      role: [
        ROLE_KITCHEN,
        ROLE_CHEF_SALAD,
        ROLE_CHEF_COLDBOWL,
        ROLE_CHEF_CLASSIC,
        ROLE_CHEF_CATERING,
        ROLE_CHEF_HOTBOWL,
        ROLE_STAFF
      ]
    }
  },
  {
    path: '/cateringkitchen',
    component: () => <Kitchen isCatering />,
    exact: true,
    name: 'cateringKitchen',
    meta: {
      requireAuth: true,
      role: [
        ROLE_KITCHEN,
        ROLE_CHEF_SALAD,
        ROLE_CHEF_COLDBOWL,
        ROLE_CHEF_CLASSIC,
        ROLE_CHEF_CATERING,
        ROLE_CHEF_HOTBOWL,
        ROLE_STAFF
      ]
    }
  },
  {
    path: '/dispatcher',
    component: () => <Dispatcher />,
    exact: true,
    name: 'dispatcher',
    meta: {
      requireAuth: true,
      role: [ROLE_KITCHEN, ROLE_STAFF]
    }
  },
  {
    path: '/weekly',
    component: () => <Weekly />,
    exact: true,
    name: 'weekly',
    meta: {
      requireAuth: true,
      role: [ROLE_KITCHEN]
    }
  },
  {
    path: '/orderForecast',
    component: () => <OrderForecast />,
    exact: true,
    name: 'Order Forecast',
    meta: {
      requireAuth: true,
      role: [ROLE_KITCHEN]
    }
  },
  {
    path: '/kitchenCount',
    component: () => (
      <KitchenCountContextProvider>
        <KitchenCount />
      </KitchenCountContextProvider>
    ),
    exact: true,
    name: 'Kitchen Count',
    meta: {
      requireAuth: true,
      role: [ROLE_KITCHEN]
    }
  },
  {
    path: '/vytalCount',
    component: () => (
      <VytalCountContextProvider>
        <VytalCount />
      </VytalCountContextProvider>
    ),
    exact: true,
    name: 'Vytal Count',
    meta: {
      requireAuth: true,
      role: [ROLE_KITCHEN]
    }
  }
];
export const routes = [
  {
    path: '/',
    component: () => <Init />,
    exact: true,
    name: 'kitchen',
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/auth',
    component: () => <Auth layoutRoutes={layoutRoutes} />,
    exact: true,
    name: 'auth',
    meta: {
      requireAuth: false
    }
  }
];
export default { routes, layoutRoutes };
