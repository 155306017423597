import jwtDecode from 'jwt-decode';
import { getLocalStorage } from './storageUtils';
import { JWT_TOKEN } from '../config/constants';

const decodeJWT = (token) => {
  if (token) {
    try {
      const decoded = jwtDecode(token);
      if (decoded) {
        return decoded;
      }
    } catch (e) {
      return false;
    }
    return false;
  }
  return false;
};

const getToken = () => {
  const tokenObj = getLocalStorage(JWT_TOKEN);
  return tokenObj && tokenObj.token ? tokenObj.token : null;
};

const isTokenExpired = () => {
  const token = getToken();
  const decoded = decodeJWT(token);
  // return !(decoded && decoded.exp > Date.now() / 1000);
  return !decoded;
};

export const getUserRole = () => {
  const token = getToken();
  const decoded = decodeJWT(token);
  return decoded && decoded.role;
};
export const isAuthenticated = () => !isTokenExpired();
