import React, { Component } from 'react';
/* import PropTypes from 'prop-types'; //OMS-v1 */
import { withStyles } from '@material-ui/core/styles';
import * as uiParams from './uiParams';
// import Header from '../common/header';
import MainDrawer from './mainDrawer';
import Footer from './footer';

const styles = (theme) => ({
  root: {
    width: '100%',
    height: 'auto',
    zIndex: 1,
    overflow: 'hidden',
    borderRadius: uiParams.RAD_GLOBAL
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  content: {
    width: '100%',
    flexGrow: 1,
    padding: 5,
    height: 'calc(100% - 30px)',
    minHeight: 'calc(100vh - 120px)',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 50px)',
      marginTop: 60
    },
    backgroundColor: '#fff'
  }
});

class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = { open: true };
  }

  // handleToggle = () => this.setState({ open: !this.state.open });

  render() {
    const { open } = this.state;
    const { classes, children } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          {/* <Header
						navDrawerOpen={open}
						handleToggleDrawer={this.handleToggle}
					/> */}
          <MainDrawer navDrawerOpen={open} />
          <main className={classes.content}>{children}</main>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(styles)(MainLayout);
