import React, { useState } from 'react';
import { ForecastEntry, MenuEntry } from '../../api/kitchen';
import { USER_SELECTED_DATE } from '../../config/constants';
import { getLocalStorage, setLocalStorage } from '../../utils/storageUtils';
import SearchBar from '../common/SearchBar';
import './orderForecast.css';
import { ForecastTimeRange, makeForecastDataQuery, makeMenuQuery } from './api';
import { mergeNetworkResponses, useQuery } from '../../hooks/network/Network';
import Query from '../../components/common/Query/Query';
import ForecastTableDaily from './ForecastTableDaily';
import NumbersOfToday from './NumbersOfToday';
import { Tab, Tabs } from '@material-ui/core';
import ForecastTableWeekly from './ForecastTableWeekly';
import { forecastTimeRangesQuery } from '../api';

export interface OrderForecastData {
  forecastData: ForecastEntry[];
  menu: MenuEntry[];
  forecastTimeRanges: ForecastTimeRange[];
}

type Mode = 'daily' | 'weekly';

export default function OrderForecast() {
  const [selectedDate, setSelectedDate] = useState(() => {
    const savedDate = getLocalStorage('USER_SELECTED_DATE');
    const date = savedDate || new Date();

    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
  });

  const [mode, setMode] = useState<Mode>('daily');
  const forecastQuery = makeForecastDataQuery(selectedDate);
  const menuQuery = makeMenuQuery(selectedDate);

  const { response: forecastResponse, retry: fetchForecastData } =
    useQuery<ForecastEntry[]>(forecastQuery);

  const { response: menuResponse, retry: fetchMenu } =
    useQuery<MenuEntry[]>(menuQuery);

  const { response: forecastTimeRangesResponse } = useQuery<
    ForecastTimeRange[]
  >(forecastTimeRangesQuery);

  const tableData = mergeNetworkResponses({
    forecastData: forecastResponse,
    menu: menuResponse,
    forecastTimeRanges: forecastTimeRangesResponse
  });

  const onDateChanged = (date: Date) => {
    setSelectedDate(date);
    setLocalStorage(USER_SELECTED_DATE, date);
  };

  const onUpdate = () => {
    fetchForecastData();
    fetchMenu();
  };

  return (
    <div className="wrapper">
      <SearchBar
        accumulatingData={false}
        currentSelectedDate={selectedDate}
        getting={false}
        onPickDate={onDateChanged}
        title="Order Forecast"
      />
      <Query
        query={tableData}
        shadowChanges={false}
        render={(data) => (
          <>
            <NumbersOfToday selectedDate={selectedDate} data={data} />
            <Tabs value={mode} onChange={(_, value) => setMode(value)} centered>
              <Tab label="Daily" value="daily" />
              <Tab label="Weekly" value="weekly" />
            </Tabs>
            <div className="screen_content">
              {(() => {
                switch (mode) {
                  case 'daily':
                    return (
                      <ForecastTableDaily
                        selectedDate={selectedDate}
                        data={data}
                        onUpdate={onUpdate}
                      />
                    );
                  case 'weekly':
                    return (
                      <ForecastTableWeekly
                        selectedDate={selectedDate}
                        data={data}
                        onUpdate={onUpdate}
                      />
                    );
                }
              })()}
            </div>
          </>
        )}
      />
    </div>
  );
}
