import { push } from 'connected-react-router';
import { layoutRoutes } from '../routesConfig';
import { getRedirectPath } from '../utils/commonUtils';
import { isAuthenticated } from '../utils/jwtUtil';

export const verifyToken = () => (dispatch) => {
  if (isAuthenticated()) {
    const redirectTo = getRedirectPath(layoutRoutes);
    dispatch(push(redirectTo));
  }
};
