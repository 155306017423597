import {
  makeGetNetworkRequest,
  makePatchNetworkRequest
} from '../../hooks/network/Network';

export function makeOrdersQuery(
  deliveryDate: Date,
  addonGroup?: string | undefined
) {
  const deliveryFromTime = new Date(
    deliveryDate.getFullYear(),
    deliveryDate.getMonth(),
    deliveryDate.getDate()
  )
    .getTime()
    .toString();

  const deliveryToTime = (
    new Date(
      deliveryDate.getFullYear(),
      deliveryDate.getMonth(),
      deliveryDate.getDate() + 1
    ).getTime() - 1
  ).toString();

  const searchParams = new URLSearchParams();

  searchParams.append('deliveryFromDate', deliveryFromTime);
  searchParams.append('deliveryToDate', deliveryToTime);
  searchParams.append('limit', '500');
  searchParams.append('page', '1');

  if (addonGroup) {
    searchParams.append('addonGroup', addonGroup);
  }

  const searchQuery = searchParams.toString();

  return makeGetNetworkRequest({
    path: `/api/v1/kitchen/orders/dishes?${searchQuery}`
  });
}

export function makeMenuQuery(deliveryDate: Date) {
  const deliveryDateSQLString = deliveryDate.toISOString().slice(0, 10);

  return makeGetNetworkRequest({
    path: `/api/v1/kitchen/orders/menu/${deliveryDateSQLString}`
  });
}

export function makeLanesDataQuery(deliveryDate: Date) {
  const deliveryDateSQLString = deliveryDate.toISOString().slice(0, 10);

  return makeGetNetworkRequest({
    path: `/api/v1/kitchen/orders/lanes/${deliveryDateSQLString}`
  });
}

export const updateDishCommand = makePatchNetworkRequest({
  path: '/api/v1/kitchen/orders/dishes/update'
});

export interface GetRatingsResponse {
  average_rating: number;
  average_taste: number;
  average_satisfaction: number;
}

export interface GetCommentsResponse {
  reviewId: string;
  comment: string;
}

export const getRatings = ({
  recipeVariantsId,
  deliveryDate
}: {
  recipeVariantsId: string;
  deliveryDate: string;
}) =>
  makeGetNetworkRequest({
    path: `/api/v1/kitchen/orders/ratings?recipeVariantsId=${recipeVariantsId}&deliveryDate=${deliveryDate}`
  });

export const getComments = ({
  recipeVariantsId,
  deliveryDate
}: {
  recipeVariantsId: string;
  deliveryDate: string;
}) =>
  makeGetNetworkRequest({
    path: `/api/v1/kitchen/orders/comments?recipeVariantsId=${recipeVariantsId}&deliveryDate=${deliveryDate}`
  });
