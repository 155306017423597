import React, { Dispatch, SetStateAction } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { CircularProgress, Theme } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import CustomTextField from './CustomTextField';
import DatePicker from './DatePicker';
import * as uiParams from './uiParams';
import DispatcherPrintContainer from '../dispatcher/DispatcherPrintContainer';
import LabelPrintContainer from '../dispatcher/LabelPrintContainer';
import DispatcherLegend from '../dispatcher/DispatcherLegend';
import { ClassNameMap, Styles } from '@material-ui/core/styles/withStyles';
import { DispatcherBranch } from '../dispatcher/domain';

interface PrintableInfo {
  showPrintAll: boolean;
  classes: ClassNameMap;
  branches: DispatcherBranch[];
  showLabelPrinter: boolean;
}

interface Props {
  classes: ClassNameMap;
  searchPlaceholder?: string | undefined;
  onSearch?: (query: string) => void | undefined;
  getting: boolean;
  accumulatingData: boolean;
  onPickDate: (date: Date) => void;
  currentSelectedDate: Date;
  title: string;
  printable?: PrintableInfo | undefined;
  legend?: Array<Dispatch<SetStateAction<boolean>> | boolean> | undefined;
}

function SearchBar(props: Props) {
  const setLegendOpen = (props.legend?.[0] ?? (() => {})) as Dispatch<
    SetStateAction<boolean>
  >;
  const isLegendOpen = (props.legend?.[1] ?? false) as boolean;

  return (
    <div className={props.classes.auxBar}>
      {props.printable && (
        <>
          <div
            role="button"
            className={props.classes.legendButton}
            onClick={() => setLegendOpen(true)}
            onKeyDown={() => {}}
            tabIndex={0}
          >
            <Settings color="action" fontSize="large" />
          </div>
          <DispatcherLegend
            open={isLegendOpen}
            onClose={() => setLegendOpen(false)}
          />
        </>
      )}
      {props.onSearch ? (
        <CustomTextField
          onTextChanged={props.onSearch}
          placeholderLabel={props.searchPlaceholder ?? 'Search dishes'}
          isSearch
          isPassword={false}
        />
      ) : null}
      {props.getting || props.accumulatingData ? (
        <CircularProgress
          className={props.classes.circleStyle}
          color="primary"
        />
      ) : (
        <h1>{props.title}</h1>
      )}
      <DatePicker
        dateChangeHandler={props.onPickDate}
        showDate={props.currentSelectedDate}
        showDateInPicker
      />
      {props.printable && props.printable.showPrintAll && (
        <div
          className={`${props.printable.classes.print} ${props.printable.classes.printAll}`}
        >
          <DispatcherPrintContainer
            type="multipleOrders"
            deliveryDate={props.currentSelectedDate}
            branches={props.printable.branches}
          />
        </div>
      )}
      {props.printable && props.printable.showLabelPrinter && (
        <LabelPrintContainer
          branches={props.printable.branches}
          deliveryDate={props.currentSelectedDate}
        />
      )}
    </div>
  );
}

const styles: Styles<Theme, {}> = {
  auxBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: uiParams.HORIZONTAL_MARGIN,
    paddingRight: uiParams.HORIZONTAL_MARGIN
  },
  legendButton: {
    width: '40px',
    height: '40px',
    marginLeft: '20px',

    '&:hover': {
      cursor: 'pointer',
      opacity: 0.5
    }
  },
  printLabels: {
    backgroundColor: uiParams.PRIMARY,
    color: uiParams.WHITE
  },
  disabled: {
    backgroundColor: uiParams.GREY2,
    color: uiParams.WHITE
  }
};

export default withStyles(styles)(SearchBar);
