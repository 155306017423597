/* eslint-disable import/no-cycle */
import { API_ENDPOINT, JWT_TOKEN } from '../config/constants';
import history from '../config/history';
import { clearLocalStorage } from '../utils/storageUtils';
import { authHeader } from './auth';

interface ApiResponse<T> {
  data: T;
}

export async function handleResponse<T>(
  response: Response
): Promise<ApiResponse<T>> {
  const text = await response.text();

  if (!response.ok) {
    if (response.status === 401 || response.status === 403) {
      clearLocalStorage(JWT_TOKEN);
      history.push('/auth');
    }

    throw new Error(text || response.statusText);
  }

  return JSON.parse(text) as ApiResponse<T>;
}

export async function editRequest(
  requestId: string,
  orderId: string,
  status: string
) {
  const body = JSON.stringify({ status });

  return fetch(
    `${API_ENDPOINT}/api/v1/kitchen/orders-processing/${orderId}/edit-requests/${requestId}`,
    { method: 'PATCH', ...authHeader(), body }
  )
    .then(handleResponse)
    .then((res) => res);
}
