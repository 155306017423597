import {
  makeGetNetworkRequest,
  makePostNetworkRequest,
  makePutNetworkRequest
} from '../../hooks/network/Network';

export function makeGetDispatchingQuery(
  deliveryDate: Date,
  timeRangeId: string | null
) {
  const deliveryDateString = deliveryDate.toISOString().substring(0, 10);
  const params = new URLSearchParams();

  params.append('deliveryDate', deliveryDateString);

  if (timeRangeId) {
    params.append('timeRangeId', timeRangeId);
  }

  const query = params.toString();

  return makeGetNetworkRequest({
    path: `/api/dispatcher?${query}`
  });
}

export const packDishesCommand = makePostNetworkRequest({
  path: '/api/dispatcher/dish/pack'
});

export const unpackDishesCommand = makePostNetworkRequest({
  path: '/api/dispatcher/dish/unpack'
});

export const packBowlCommand = makePostNetworkRequest({
  path: '/api/dispatcher/bowl/pack'
});

export const packBoxCommand = makePostNetworkRequest({
  path: '/api/dispatcher/box/pack'
});

export const unpackBoxCommand = makePostNetworkRequest({
  path: '/api/dispatcher/box/unpack'
});

export const updateBoxInfoCommand = makePutNetworkRequest({
  path: '/api/dispatcher/box'
});

export const dispatchBranchCommand = makePostNetworkRequest({
  path: '/api/dispatcher/branch/dispatch'
});

export const undispatchBranchCommand = makePostNetworkRequest({
  path: '/api/dispatcher/branch/undispatch'
});

export function makeLegacyBoxPatchQuery(selectedDate: Date) {
  const startTime: number = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    selectedDate.getDate()
  ).getTime();

  const endTime: number = startTime + 1000 * 60 * 60 * 24 - 1;
  const sqlDateString = selectedDate.toISOString().slice(0, 10);

  return makeGetNetworkRequest({
    path: `/api/v1/kitchen/orders/packaging/boxes/v2?page=1&limit=1000&deliveryFromDate=${startTime}&deliveryToDate=${endTime}&deliveryDate=${sqlDateString}`
  });
}
