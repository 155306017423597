export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const WS = process.env.REACT_APP_WS;
export const WS_MODE = process.env.REACT_APP_WS_MODE;
export const JWT_TOKEN = 'user';
export const ENABLE_SENTRY = process.env.REACT_APP_ENABLE_SENTRY;
export const MARKETMAN_PRODUCT_INFO_URL =
  process.env.REACT_APP_MARKETMAN_PRODUCT_INFO_URL;
export const STICKER_IMAGE = process.env.REACT_APP_STICKER_IMAGE;
export const TODAY = 'TODAY';
export const FUTURE = 'FUTURE';
export const ALL = 'ALL';
export const EDIT_REQUEST_ERROR_MSG =
  "Couldn't process the request, Please try again!";
export const QUANTITY_COULD_NOT_BE_NAGATIVE = 'Quantity can not be negative!';
export const QUANTITY_COULD_EXCEED_ALLOWED = 'Quantity can not exceed maximum!';
export const PROCESSING_LANE_ID = 'PROCESSING';
export const KITCHEN_LANE_ID = 'KITCHEN';
export const COMPLETED_LANE_ID = 'COMPLETED';
export const DRIVER_SAVE_SUCCESS = 'Driver updated successfully!';
export const DRIVER_SAVE_FAILED = 'Driver could not be updated!';
export const DELIVERY_FETCH_FAILED = 'Could not get delivery data!';
export const DRIVER_FETCH_FAILED = 'Could not get driver data!';
export const ORDER_STATUS_SUCCESS = 'Order status updated successfully!';
export const ORDER_STATUS_FAILED = 'Order status could not be updated!';

export const CARD_CLICK = 'CARD_CLICK';
export const MOVE_CLICK = 'MOVE_CLICK';
export const INTERNAL_SERVER_ERROR =
  'Something went wrong :/ please try again!';

export const USER_SELECTED_DATE = 'USER_SELECTED_DATE';
export const BTNTXT_FUTURE_ORDERS = 'View Future Orders';
export const BTNTXT_ALL_ORDERS = 'View All Orders';
export const WARNING_TEXT_ALL_ORDERS =
  'Currently showing ALL orders. ' +
  "Select a date to view that day's orders.\n" +
  "Click 'VIEW FUTURE ORDERS' button to view future orders from today";
export const TOOLTIP_SHOW_ALL_ORDERS = 'Click to show ALL orders';
export const TOOLTIP_SHOW_FUTURE_ORDERS =
  'Click to show Future orders from today';

export const MSG_INVALID_QUANTITY_ENTERED = 'Invalid quantity Entered';

export const ROLE_KITCHEN = 'kitchen';
export const ROLE_STAFF = 'operations';
export const ROLE_CHEF_SALAD = 'salad';
export const ROLE_CHEF_COLDBOWL = 'coldbowl';
export const ROLE_CHEF_CLASSIC = 'classic';
export const ROLE_CHEF_CATERING = 'catering';
export const ROLE_CHEF_HOTBOWL = 'hotbowl';

export const STATION_TAGS_OBJ = {
  [ROLE_KITCHEN]: 'kitchen',
  [ROLE_STAFF]: 'operations',
  [ROLE_CHEF_SALAD]: 'salad',
  [ROLE_CHEF_COLDBOWL]: 'coldbowl',
  [ROLE_CHEF_CLASSIC]: 'classic',
  [ROLE_CHEF_CATERING]: 'catering',
  [ROLE_CHEF_HOTBOWL]: 'hotbowl'
};
export const UNDO_ACTIONS_KEY = 'undo_actions';
export const REDO_ACTIONS_KEY = 'redo_actions';
export const KITCHEN_ACTIONS_KEY = 'kitchen_actions';
export const DISP_ACTIONS_KEY = 'dispatcher_actions';
export const WEEKLY_ACTIONS_KEY = 'weekly_actions';
export const MOVE_CARD_ACTION = 'move_card';
export const PACK_DISH_ACTION = 'pack_dish';
export const DELIVERY_DETAILS = 'change_delivery_details';
export const UNDO = 'undo';
export const REDO = 'redo';
export const UNDOMESSAGE = 'Undoing action for the date: ';
export const REDOMESSAGE = 'Redoing action for the date: ';

// UR - Undo/Redo
export const UR_ACTIONS_STRUCT = {
  [KITCHEN_ACTIONS_KEY]: {
    [MOVE_CARD_ACTION]: []
  },
  [DISP_ACTIONS_KEY]: {
    [PACK_DISH_ACTION]: []
  },
  [WEEKLY_ACTIONS_KEY]: {
    [DELIVERY_DETAILS]: []
  }
};

export type DayName = 'Sun' | 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri';
export const week: DayName[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri'];

export const weekData: Array<{ id: number; name: DayName }> = [
  { id: 0, name: 'Mon' },
  { id: 1, name: 'Tue' },
  { id: 2, name: 'Wed' },
  { id: 3, name: 'Thu' },
  { id: 4, name: 'Fri' }
];

export const NOTIFICATION_STATUS = 'NOTIFICATION_STATUS';
export const NOTIFICATION_STATES = {
  ON: 'on',
  OFF: 'off'
};
export const NEW_ORDER_RECEIVED = 'New order';
export const ORDER_CANCELLED = 'Order cancelled';
export const SOCKET_OPENED = 'Subscribed to Order notification';
export const ADDONS_CAT = 'addons';
export const ADDONS_PREPARE_STATION = 'prepared_addons';
export const ADDONS = 'AD';

export const S3_IMAGE_URL =
  'https://bellabona-e-commerce.s3.eu-central-1.amazonaws.com';
export const STICKER_THUMBNAIL_IMAGE_URL = `${S3_IMAGE_URL}/${STICKER_IMAGE}/new/200x200/{{NUM}}.jpg`;
export const STICKER_IMAGE_URL = `${S3_IMAGE_URL}/${STICKER_IMAGE}/new/800x800/{{NUM}}.jpg`;
export const DISH_TYPE_URL = `${S3_IMAGE_URL}/UtilityImages/`;
export const STICKER_URL = `${S3_IMAGE_URL}/${STICKER_IMAGE}/new/800x800/`;
export const GENERIC_STICKER = `${S3_IMAGE_URL}/${STICKER_IMAGE}/new/800x800/generic_sticker.jpg`;
export const GENERIC_STICKER_THUMBNAIL = `${S3_IMAGE_URL}/${STICKER_IMAGE}/new/200x200/generic_sticker.jpg`;

export const MUNICH_DRIVER_01 = 'Munich driver 01';

export const STICKER_COLOR_MAP = {
  1: 'rgb(113,63,134)',
  2: 'rgb(68,124,67)',
  3: 'rgb(146,180,81)',
  4: 'rgb(94,99,110)',
  5: 'rgb(77,132,195)',
  6: 'rgb(73,70,135)',
  7: 'rgb(190,188,217)',
  8: 'rgb(193,102,138)',
  9: 'rgb(222,183,169)',
  10: 'rgb(202,129,93)',
  11: 'rgb(238,207,73)',
  12: 'rgb(123,179,198)'
};
