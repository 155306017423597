import {
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,
  SEARCH_COMPANY
} from '../types';

const initialState = {
  orders: [],
  error: '',
  getting: false,
  total: 0,
  updates: [],
  updatesError: '',
  gettingUpdates: false
};

export const companyOrders = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS_REQUEST:
      return {
        ...state,
        error: '',
        getting: true
      };
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        getting: false,
        orders: [
          ...state.orders
          // ...action.orders.data.orders.rows[0].orders_processing
        ],
        total: action.orders.data.total,
        status: action.orders.status
      };
    case GET_ORDERS_FAILURE:
      return {
        ...state,
        error: action.error,
        getting: false
      };

    case SEARCH_COMPANY:
      const { value } = action;
      const filteredOrders = state.orders.filter((val) => val.includes(value));
      return {
        ...state,
        getting: false,
        companyOrders: filteredOrders,
        total: action.orders.data.total,
        status: action.orders.status
      };
    default:
      return state;
  }
};
