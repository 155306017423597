import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';

import withAuth from './components/hoc/withAuth';
import history from './config/history';
import MainLayout from './scenes/common/mainLayout';
import { routes, layoutRoutes } from './routesConfig';

const RouterView = () => {
  const getLayoutRoutes = (routeConfig, index) => {
    const {
      meta: { requireAuth, role },
      name,
      component
    } = routeConfig;
    if (!requireAuth) {
      // Route within the MainLayout which dose note require authentication.
      return <Route {...routeConfig} key={`${name}-${index}`} />;
    }
    // Route within the MainLayout which require authentication.
    return (
      <Route
        key={`${name}-${index}`}
        {...routeConfig}
        component={withAuth(component, role)}
      />
    );
  };

  return (
    <Router history={history}>
      <Switch>
        {/* Route without layout component */}
        {routes.map((item) => (
          <Route {...item} key={item.name} />
        ))}
        {/* Route with layout component */}
        <MainLayout key="layout">
          {layoutRoutes.map((item, index) => getLayoutRoutes(item, index))}
        </MainLayout>
      </Switch>
    </Router>
  );
};
export default RouterView;
