import { combineReducers } from 'redux';
import { auth } from './auth';
import { orders } from './orders';
import { companyOrders } from './company';

export default combineReducers({
  auth,
  orders,
  companyOrders
});
