import React, { PropsWithChildren } from 'react';
import './tooltip.css';

interface Props
  extends PropsWithChildren<{
    tooltipText: string;
    show: boolean;
  }> {}

export function Tooltip(props: Props) {
  return (
    <div className="tooltip_wrapper">
      {props.children}
      <span className={`${props.show ? 'tooltip_text' : 'hide'}`}>
        {props.tooltipText}
      </span>
    </div>
  );
}
