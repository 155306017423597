export class NetworkError extends Error {
  public code: number;
  public extras: any;

  constructor(code: number, message: string, extras?: any) {
    super(message);
    this.code = code;
    this.extras = extras;
  }
}
