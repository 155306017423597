/* eslint-disable import/no-cycle */
import { API_ENDPOINT } from '../config/constants';
import { authHeader } from './auth';
import { handleResponse } from './index';

export const getDeliveryByDate = async (deliveryFromDate, deliveryToDate) => {
  const options = {
    method: 'GET',
    ...(await authHeader())
  };

  return fetch(
    `${API_ENDPOINT}/api/v1/kitchen/orders/deliveryData?page=1&limit=1000&deliveryFromDate=${deliveryFromDate}&deliveryToDate=${deliveryToDate}`,
    options
  )
    .then(handleResponse)
    .then((res) => res.data);
};

export const updateDeliveryById = async (id, data) => {
  const bodyData = JSON.stringify(data);
  const options = {
    method: 'PATCH',
    ...(await authHeader()),
    body: bodyData
  };
  return fetch(
    `${API_ENDPOINT}/api/v1/kitchen/orders/deliveryData/${id}`,
    options
  )
    .then(handleResponse)
    .then((res) => res.data);
};
export const updateOrderByDeliveryId = async (id, data) => {
  const bodyData = JSON.stringify(data);
  const options = {
    method: 'PATCH',
    ...(await authHeader()),
    body: bodyData
  };
  return fetch(
    `${API_ENDPOINT}/api/v1/kitchen/orders/update/deliveryData/${id}`,
    options
  )
    .then(handleResponse)
    .then((res) => res.data);
};
