import {
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  LOGOUT_SUCCESS
} from '../types';

const initialState = {
  loggingIn: false,
  logoutSuccess: false,
  error: ''
};

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        error: ''
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        loggingIn: false
      };
    case SIGN_IN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        error: action.error
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        logoutSuccess: true
      };
    default:
      return state;
  }
};
