/* eslint-disable jsx-a11y/alt-text */
import React, { ChangeEvent, useMemo, useState } from 'react';
import { MenuItem, Select, Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import OrderMetricBoxes from '../../components/common/OrderMetricBoxes';
import { sortBranches } from './helpers';
import { getLocalStorage, setLocalStorage } from '../../utils/storageUtils';
import BoxDetailsModal from '../common/BoxDetailsModal';
import { commonStyles } from '../common/commonStyle';
import QuantityPopUp from '../common/QuantityPopUp';
import SearchBar from '../common/SearchBar';
import * as uiParams from '../common/uiParams';
import './css/dispatcher-tabs.css';
import { ClassNameMap, Styles } from '@material-ui/core/styles/withStyles';
import BranchCard from './BranchCard';
import {
  dispatchBranchCommand,
  makeGetDispatchingQuery,
  packBoxCommand,
  packDishesCommand,
  undispatchBranchCommand,
  unpackBoxCommand,
  unpackDishesCommand,
  updateBoxInfoCommand
} from './api';
import {
  mergeNetworkResponses,
  useCommand,
  useQuery,
  useQueryState
} from '../../hooks/network/Network';
import Query from '../../components/common/Query/Query';
import { forecastTimeRangesQuery } from '../api';
import { ForecastTimeRange } from '../OrderForecast/api';
import {
  DispatchOperation,
  DispatcherBox,
  DispatcherBranch,
  DispatcherDish,
  DispatcherUser,
  UpdateBoxPackingBody,
  UpdateBoxInfoBody,
  UpdateBranchDispatchingBody,
  UpdateDishPackingBody,
  UpdateBowlPackingBody
} from './domain';
import ScanningBranchPopup from './ScanningDispatcherBranchState/ScanningBranchPopup';

interface Props {
  classes: ClassNameMap;
}

interface ClosedBoxInfoModalState {
  isOpen: false;
  branchId: string | null;
  box: DispatcherBox | null;
}

interface OpenBoxInfoModalState {
  isOpen: true;
  branchId: string;
  box: DispatcherBox;
}

type BoxInfoModalState = ClosedBoxInfoModalState | OpenBoxInfoModalState;

interface ClosedPackDishQuantityModalState {
  isOpen: false;
  branchId: string | null;
  boxUniqueIdentifier: string | null;
  dish: DispatcherDish | null;
}

interface OpenPackDishQuantityModalState {
  isOpen: true;
  branchId: string;
  boxUniqueIdentifier: string;
  dish: DispatcherDish;
}

type PackDishQuantityModalState =
  | ClosedPackDishQuantityModalState
  | OpenPackDishQuantityModalState;

interface OnDispatchingUpdate {
  (dispatchData: DispatcherBranch[], shouldRefresh: boolean): void;
  (dispatchData: null): void;
}

export interface UpdatingItems {
  branchIds: string[];
  boxUniqueIdentifiers: string[];
  // This name is voluntarily unintuitive: it contains both the box unique identifier and the dish id,
  // concatenated with `-`, to distinguish the same dish in different boxes
  dishesInBoxes: string[];
}

function Dispatcher(props: Props) {
  const [deliveryDate, setDeliveryDate] = useState<Date>(() => {
    const storedQueryDate = getLocalStorage('USER_SELECTED_DATE');
    const date = storedQueryDate || new Date();

    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
  });

  const [currentTimeRange, setCurrentTimeRange] =
    useState<ForecastTimeRange | null>(null);

  const { response: timeRangesResponse } = useQuery<ForecastTimeRange[]>(
    forecastTimeRangesQuery
  );

  const {
    response: dispatchingResponse,
    retry: fetchDispatching,
    setResponse: setDispatching
  } = useQueryState<DispatcherBranch[]>(
    makeGetDispatchingQuery(deliveryDate, currentTimeRange?.id ?? null)
  );

  const onDeliveryDateChange = (deliveryDate: Date) => {
    setDeliveryDate(deliveryDate);
    setLocalStorage('USER_SELECTED_DATE', deliveryDate);
  };

  const onDispatchingUpdate: OnDispatchingUpdate = (
    dispatching: DispatcherBranch[] | null,
    shouldRefresh?: boolean
  ) => {
    if (dispatching) {
      setDispatching(dispatching);

      if (shouldRefresh) {
        fetchDispatching();
      }
    } else {
      fetchDispatching();
    }
  };

  return (
    <Query
      query={mergeNetworkResponses({
        timeRanges: timeRangesResponse,
        branches: dispatchingResponse
      })}
      shadowChanges
      render={({ timeRanges, branches }) => (
        <DispatcherContent
          classes={props.classes}
          deliveryDate={deliveryDate}
          onDeliveryDateChange={onDeliveryDateChange}
          timeRanges={timeRanges}
          currentTimeRange={currentTimeRange}
          onCurrentTimeRangeChange={setCurrentTimeRange}
          branches={branches}
          onUpdate={onDispatchingUpdate}
        />
      )}
    />
  );
}

interface DispatcherContentProps {
  classes: ClassNameMap;
  deliveryDate: Date;
  onDeliveryDateChange: (deliveryDate: Date) => void;
  timeRanges: ForecastTimeRange[];
  currentTimeRange: ForecastTimeRange | null;
  onCurrentTimeRangeChange: (
    currentTimeRange: ForecastTimeRange | null
  ) => void;
  branches: DispatcherBranch[];
  onUpdate: OnDispatchingUpdate;
}

function DispatcherContent(props: DispatcherContentProps) {
  const [searchQuery, setSearchQuery] = useState('');

  const [packDishQuantityModalState, setPackDishQuantityModalState] =
    useState<PackDishQuantityModalState>({
      isOpen: false,
      branchId: null,
      boxUniqueIdentifier: null,
      dish: null
    });

  const [boxInfoModalState, setBoxInfoModalState] = useState<BoxInfoModalState>(
    {
      isOpen: false,
      branchId: null,
      box: null
    }
  );

  const [isLegendOpen, setLegendOpen] = useState(false);

  const [updatingItems, setUpdatingItems] = useState<UpdatingItems>({
    branchIds: [],
    boxUniqueIdentifiers: [],
    dishesInBoxes: []
  });

  const [scanningBranch, setScanningBranch] = useState<DispatcherBranch | null>(
    null
  );

  const { sendRequest: sendUpdateBoxInfoRequest } = useCommand<
    UpdateBoxInfoBody,
    void
  >(updateBoxInfoCommand);

  const { sendRequest: sendPackDishesRequest } = useCommand<
    UpdateDishPackingBody,
    void
  >(packDishesCommand);

  const { sendRequest: sendUnpackDishesRequest } = useCommand<
    UpdateDishPackingBody,
    void
  >(unpackDishesCommand);

  const { sendRequest: sendPackBowlRequest } = useCommand<
    UpdateBowlPackingBody,
    void
  >(packDishesCommand);

  const { sendRequest: sendPackBoxRequest } = useCommand<
    UpdateBoxPackingBody,
    void
  >(packBoxCommand);

  const { sendRequest: sendUnpackBoxRequest } = useCommand<
    UpdateBoxPackingBody,
    void
  >(unpackBoxCommand);

  const { sendRequest: sendDispatchBranchRequest } = useCommand<
    UpdateBranchDispatchingBody,
    void
  >(dispatchBranchCommand);

  const { sendRequest: sendUndispatchBranchRequest } = useCommand<
    UpdateBranchDispatchingBody,
    void
  >(undispatchBranchCommand);

  const { branches } = props;

  const todaysNumbers = useMemo(() => {
    const dispatchedBranchesCount = branches.filter(
      (branch) => branch.isDispatched
    ).length;

    const allDishes = branches
      .flatMap((branch) => branch.boxes)
      .flatMap((box) => box.dishes);

    const mainDishesCount = allDishes
      .filter((dish) => dish.label !== 'addons')
      .reduce(
        (sum, dish) =>
          sum + dish.users.reduce((sum, user) => sum + user.orderedQuantity, 0),
        0
      );

    const bowlCodesCount = allDishes.reduce(
      (sum, dish) => sum + dish.bowlCodes.length,
      0
    );

    const addOnsCount = allDishes
      .filter((dish) => dish.label === 'addons')
      .reduce(
        (sum, dish) =>
          sum + dish.users.reduce((sum, user) => sum + user.orderedQuantity, 0),
        0
      );

    const dispatchedDishesCount = allDishes.reduce(
      (sum, dish) =>
        sum + dish.users.reduce((sum, user) => sum + user.packedQuantity, 0),
      0
    );

    return {
      'No. of Scans': `${bowlCodesCount} / ${mainDishesCount}`,
      'No. of Addons': addOnsCount,
      'Companies Dispatched': `${dispatchedBranchesCount} / ${branches.length}`,
      'Dishes Dispatched': `${dispatchedDishesCount} / ${
        mainDishesCount + addOnsCount
      }`
    };
  }, [branches]);

  const allBowlCodes = props.branches
    .flatMap((branch) => branch.boxes)
    .flatMap((box) => box.dishes)
    .flatMap((dish) => dish.bowlCodes);

  const sortedBranches = useMemo(
    () => [...branches].sort(sortBranches),
    [branches]
  );

  const filteredBranches = useMemo(
    () =>
      sortedBranches.filter(
        (branch) =>
          branch.name.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0
      ),
    [sortedBranches, searchQuery]
  );

  const onTimeRangeChange = (event: ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;

    if (value === 'all') {
      props.onCurrentTimeRangeChange(null);
    } else {
      const timeRange = props.timeRanges.find(
        (timeRange) => timeRange.name === value
      );
      if (timeRange) {
        props.onCurrentTimeRangeChange(timeRange);
      }
    }
  };

  const onEditBoxInfoButtonClick = (branchId: string, box: DispatcherBox) => {
    setBoxInfoModalState({
      isOpen: true,
      branchId,
      box
    });
  };

  const onBoxInfoChange = (
    branchId: string,
    box: DispatcherBox
  ): Promise<void> => {
    setBoxInfoModalState((state) => ({
      ...state,
      isOpen: false
    }));

    if (box.name) {
      return sendUpdateBoxInfoRequest({
        uniqueBoxIdentifier: box.uniqueIdentifier,
        boxName: box.name,
        plateId: box.plateId
      }).then(() => {
        const targetBox = box;

        props.onUpdate(
          props.branches.map((branch) => {
            if (branch.id === branchId) {
              return {
                ...branch,
                boxes: branch.boxes.map((box) => {
                  if (targetBox.uniqueIdentifier === box.uniqueIdentifier) {
                    return targetBox;
                  } else {
                    return box;
                  }
                })
              };
            } else {
              return branch;
            }
          }),
          false
        );
      });
    } else {
      return Promise.resolve();
    }
  };

  const packDishes = (
    branchId: string,
    boxUniqueIdentifier: string,
    dishId: string,
    quantity: number,
    operation: DispatchOperation
  ): Promise<void> => {
    setUpdatingItems((items) => ({
      ...items,
      dishesInBoxes: [
        ...items.dishesInBoxes,
        `${boxUniqueIdentifier}-${dishId}`
      ]
    }));

    const request = (() => {
      const body = {
        type: 'dish' as const,
        deliveryDate: props.deliveryDate.toISOString().slice(0, 10),
        branchId,
        uniqueBoxIdentifier: boxUniqueIdentifier,
        recipeVariantId: dishId,
        quantity
      };

      switch (operation) {
        case 'pack':
          return sendPackDishesRequest(body);
        case 'unpack':
          return sendUnpackDishesRequest(body);
      }
    })();

    return request
      .then(() => {
        props.onUpdate(
          updatePackedQuantity(
            props.branches,
            branchId,
            boxUniqueIdentifier,
            dishId,
            operation,
            quantity
          ),
          false
        );
      })
      .finally(() => {
        setUpdatingItems((items) => ({
          ...items,
          dishesInBoxes: items.dishesInBoxes.filter(
            (item) => item !== `${boxUniqueIdentifier}-${dishId}`
          )
        }));
      });
  };

  const packBowl = (
    branchId: string,
    uniqueBoxIdentifier: string,
    dishId: string,
    bowlCode: string
  ): Promise<void> =>
    sendPackBowlRequest({
      type: 'bowl',
      uniqueBoxIdentifier,
      bowlCode,
      branchId,
      deliveryDate: props.deliveryDate.toISOString().slice(0, 10),
      recipeVariantId: dishId
    }).then(() => {
      props.onUpdate(
        updatePackedQuantity(
          props.branches,
          branchId,
          uniqueBoxIdentifier,
          dishId,
          'pack',
          1,
          bowlCode
        ),
        false
      );
    });

  const onPackDishButtonClick = (
    branchId: string,
    boxUniqueIdentifier: string,
    dish: DispatcherDish,
    operation: DispatchOperation
  ) => {
    const totalQuantity = dish.users.reduce(
      (sum, user) => sum + user.orderedQuantity,
      0
    );

    switch (operation) {
      case 'pack': {
        if (totalQuantity > 1) {
          setPackDishQuantityModalState({
            isOpen: true,
            branchId,
            boxUniqueIdentifier,
            dish
          });
        } else {
          packDishes(branchId, boxUniqueIdentifier, dish.id, 1, 'pack');
        }

        break;
      }
      case 'unpack': {
        const totalQuantity = dish.users.reduce(
          (sum, user) => sum + user.packedQuantity,
          0
        );

        packDishes(
          branchId,
          boxUniqueIdentifier,
          dish.id,
          totalQuantity,
          'unpack'
        );

        break;
      }
    }
  };

  const onPackDishQuantityChange = (
    branchId: string,
    boxUniqueIdentifier: string,
    dish: DispatcherDish,
    quantity: number
  ) => {
    packDishes(branchId, boxUniqueIdentifier, dish.id, quantity, 'pack');
  };

  const onPackBoxButtonClick = (
    branchId: string,
    box: DispatcherBox,
    operation: DispatchOperation
  ) => {
    setUpdatingItems((items) => ({
      ...items,
      boxUniqueIdentifiers: [
        ...items.boxUniqueIdentifiers,
        box.uniqueIdentifier
      ]
    }));

    const request = (() => {
      const body = {
        type: 'box' as const,
        deliveryDate: props.deliveryDate.toISOString().slice(0, 10),
        branchId,
        uniqueBoxIdentifier: box.uniqueIdentifier
      };

      switch (operation) {
        case 'pack':
          return sendPackBoxRequest(body);
        case 'unpack':
          return sendUnpackBoxRequest(body);
      }
    })();

    request
      .then(() => {
        const targetBoxUniqueIdentifier = box.uniqueIdentifier;

        props.onUpdate(
          props.branches.map((branch) => {
            if (branch.id !== branchId) {
              return branch;
            } else {
              return {
                ...branch,
                boxes: branch.boxes.map((box) => {
                  if (box.uniqueIdentifier !== targetBoxUniqueIdentifier) {
                    return box;
                  } else {
                    return {
                      ...box,
                      dishes: box.dishes.map((dish) => ({
                        ...dish,
                        users: dish.users.map((user) => ({
                          ...user,
                          packedQuantity: (() => {
                            switch (operation) {
                              case 'pack':
                                return user.orderedQuantity;
                              case 'unpack':
                                return 0;
                            }
                          })()
                        }))
                      }))
                    };
                  }
                })
              };
            }
          }),
          true
        );
      })
      .finally(() => {
        setUpdatingItems((items) => ({
          ...items,
          boxUniqueIdentifiers: items.boxUniqueIdentifiers.filter(
            (uid) => uid !== box.uniqueIdentifier
          )
        }));
      });
  };

  const onDispatchBranchButtonClick = (
    branchId: string,
    operation: DispatchOperation
  ) => {
    setUpdatingItems((items) => ({
      ...items,
      branchIds: [...items.branchIds, branchId]
    }));

    const request = (() => {
      const body = {
        deliveryDate: props.deliveryDate.toISOString().slice(0, 10),
        branchId
      };

      switch (operation) {
        case 'pack':
          return sendDispatchBranchRequest(body);
        case 'unpack':
          return sendUndispatchBranchRequest(body);
      }
    })();

    request
      .then(() => {
        props.onUpdate(
          props.branches.map((branch) => {
            if (branch.id !== branchId) {
              return branch;
            } else {
              return {
                ...branch,
                isDispatched: operation === 'pack',
                boxes: branch.boxes.map((box) => ({
                  ...box,
                  dishes: box.dishes.map((dish) => ({
                    ...dish,
                    users: dish.users.map((user) => ({
                      ...user,
                      packedQuantity: (() => {
                        switch (operation) {
                          case 'pack':
                            return user.orderedQuantity;
                          case 'unpack':
                            return 0;
                        }
                      })()
                    }))
                  }))
                }))
              };
            }
          }),
          true
        );
      })
      .finally(() => {
        setUpdatingItems((items) => ({
          ...items,
          branchIds: items.branchIds.filter((item) => item !== branchId)
        }));
      });
  };

  return (
    <div
      className={[
        props.classes.wrapper,
        props.classes.dispatcherContainer
      ].join(' ')}
    >
      <SearchBar
        searchPlaceholder="Search branches"
        onSearch={setSearchQuery}
        getting={false}
        currentSelectedDate={props.deliveryDate}
        onPickDate={props.onDeliveryDateChange}
        title="Dispatcher"
        printable={{
          classes: props.classes,
          branches: sortedBranches,
          showLabelPrinter: true,
          showPrintAll: sortedBranches.length > 0
        }}
        legend={[setLegendOpen, isLegendOpen]}
        accumulatingData={false}
      />
      <OrderMetricBoxes numbersData={todaysNumbers} />
      <div className={props.classes.timeRangeFilter}>
        <Select
          value={props.currentTimeRange?.name ?? 'all'}
          onChange={onTimeRangeChange}
        >
          <MenuItem value="all">Whole day</MenuItem>
          {props.timeRanges.map((timeRange) => (
            <MenuItem key={timeRange.id} value={timeRange.name}>
              {timeRange.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={props.classes.container}>
        {filteredBranches.map((branch, index) => (
          <BranchCard
            key={branch.id}
            classes={props.classes}
            index={index}
            branch={branch}
            deliveryDate={props.deliveryDate}
            updatingItems={updatingItems}
            onDispatchButtonClick={(operation: DispatchOperation) => {
              onDispatchBranchButtonClick(branch.id, operation);
            }}
            onEditBoxDetailsButtonClick={(box) => {
              onEditBoxInfoButtonClick(branch.id, box);
            }}
            onPackBoxButtonClick={(box, operation) => {
              onPackBoxButtonClick(branch.id, box, operation);
            }}
            onPackDishButtonClick={(boxUniqueIdentitfier, dish, operation) => {
              onPackDishButtonClick(
                branch.id,
                boxUniqueIdentitfier,
                dish,
                operation
              );
            }}
            onStartScanningButtonClick={() => setScanningBranch(branch)}
          />
        ))}
      </div>
      {packDishQuantityModalState.isOpen ? (
        <QuantityPopUp
          open
          onClose={(quantityToPack) => {
            onPackDishQuantityChange(
              packDishQuantityModalState.branchId,
              packDishQuantityModalState.boxUniqueIdentifier,
              packDishQuantityModalState.dish,
              quantityToPack
            );

            setPackDishQuantityModalState((state) => ({
              ...state,
              isOpen: false
            }));
          }}
          onEscape={() =>
            setPackDishQuantityModalState((state) => ({
              ...state,
              isOpen: false
            }))
          }
          defaultQuantity={(() => {
            const totalQuantity = packDishQuantityModalState.dish.users.reduce(
              (sum, user) => sum + user.orderedQuantity,
              0
            );

            const packedQuantity = packDishQuantityModalState.dish.users.reduce(
              (sum, user) => sum + user.packedQuantity,
              0
            );

            return totalQuantity - packedQuantity;
          })()}
          maxQuantity={(() => {
            const totalQuantity = packDishQuantityModalState.dish.users.reduce(
              (sum, user) => sum + user.orderedQuantity,
              0
            );

            return totalQuantity;
          })()}
          dialogTitle={`How many of ${packDishQuantityModalState.dish.name} did you pack?`}
        />
      ) : null}
      {boxInfoModalState.isOpen ? (
        <BoxDetailsModal
          open
          box={boxInfoModalState.box}
          onClose={() =>
            setBoxInfoModalState((state) => ({
              ...state,
              isOpen: false
            }))
          }
          onEscape={() =>
            setBoxInfoModalState((state) => ({
              ...state,
              isOpen: false
            }))
          }
          onSubmit={(box) => onBoxInfoChange(boxInfoModalState.branchId, box)}
        />
      ) : null}
      {scanningBranch !== null ? (
        <ScanningBranchPopup
          branch={scanningBranch}
          allBowlCodes={allBowlCodes}
          onBoxInfoChange={(box) => onBoxInfoChange(scanningBranch.id, box)}
          onBowlPack={(boxUniqueIdentifier, dishId, bowlCode) =>
            packBowl(scanningBranch.id, boxUniqueIdentifier, dishId, bowlCode)
          }
          onClose={() => setScanningBranch(null)}
          deliveryDate={props.deliveryDate}
        />
      ) : null}
    </div>
  );
}

function updatePackedQuantity(
  branches: DispatcherBranch[],
  targetBranchId: string,
  targetBoxId: string,
  targetDishId: string,
  operation: DispatchOperation,
  quantity: number,
  bowlCode?: string
): DispatcherBranch[] {
  return branches.map((branch) => {
    if (branch.id !== targetBranchId) {
      return branch;
    } else {
      return {
        ...branch,
        boxes: branch.boxes.map((box) => {
          if (box.uniqueIdentifier !== targetBoxId) {
            return box;
          } else {
            return {
              ...box,
              dishes: box.dishes.map((dish) => {
                if (quantity === 0 || dish.id !== targetDishId) {
                  return dish;
                } else {
                  /*
                  This is an optimistic update which DOES NOT match reality (i.e.: what's
                  written in the database). The BE updates first the orders that are closer
                  to completion (the ones that have the most dishes already packed). Since we
                  don't show information about which specific orders are packed, but we just show
                  how many orders are packed per recipe, we are updating orders ordered by
                  username, which is the order we get data in.
                  */
                  const { users } = dish.users.reduce<{
                    users: DispatcherUser[];
                    quantity: number;
                  }>(
                    // eslint-disable-next-line array-callback-return
                    ({ users, quantity }, user) => {
                      switch (operation) {
                        case 'pack': {
                          const unpackedQty =
                            user.orderedQuantity - user.packedQuantity;

                          const qtyToPack = Math.min(quantity, unpackedQty);

                          return {
                            users: [
                              ...users,
                              {
                                ...user,
                                packedQuantity: user.packedQuantity + qtyToPack
                              }
                            ],
                            quantity: quantity - qtyToPack
                          };
                        }
                        case 'unpack': {
                          const qtyToUnpack = Math.min(
                            quantity,
                            user.packedQuantity
                          );

                          return {
                            users: [
                              ...users,
                              {
                                ...user,
                                packedQuantity:
                                  user.packedQuantity - qtyToUnpack
                              }
                            ],
                            quantity: quantity - qtyToUnpack
                          };
                        }
                      }
                    },
                    { users: [], quantity }
                  );

                  return {
                    ...dish,
                    users,
                    bowlCodes: bowlCode
                      ? [...dish.bowlCodes, bowlCode]
                      : dish.bowlCodes
                  };
                }
              })
            };
          }
        })
      };
    }
  });
}

const styles: Styles<Theme, {}> = {
  ...commonStyles,
  dispatcherContainer: {
    position: 'relative'
  },
  background: {
    backgroundColor: uiParams.WHITE
  },
  companyCard: {
    position: 'relative',
    backgroundColor: uiParams.WHITE,
    width: '320px',
    height: 'fit-content',
    borderRadius: '4px',
    boxShadow: '0px 2px 4px 0 rgba(0, 0, 0, 0.4)',
    margin: '30px'
  },
  loader: {
    zIndex: 11
  },
  greyOverlay: {
    background: uiParams.GREY1,
    height: '100%',
    width: '100%',
    opacity: '0.3',
    top: 0,
    left: 0,
    position: 'absolute',
    padding: 0,
    zIndex: 10
  },
  companyHeader: {
    display: 'flex',
    fontSize: '24px',
    textAlign: 'left',
    color: uiParams.BLACK,
    margin: '24px 12px -10px 12px'
  },
  companyName: {
    display: 'inline-block',
    width: '90%'
  },
  otherCompanyDetails: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  totalQuantity: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '12px',
    marginRight: '12px',
    '& > p:first-child': {
      borderBottom: `1px solid ${uiParams.GREY3}`,
      paddingBottom: '0.5em',
      marginBottom: '0.5em'
    },
    '& > p': {
      marginTop: '1em',
      marginBottom: '0.25em'
    },
    '& > p + p': {
      marginTop: '0'
    }
  },
  print: {
    display: 'inline-block',
    width: '44px',
    height: '44px',
    zIndex: 100,
    '&:hover': {
      cursor: 'pointer',
      width: '45px',
      height: '45px',
      transition: 'opacity 0.8s ease-in-out'
    }
  },
  detailSection: {
    display: 'flex'
  },
  box: {
    position: 'relative',
    margin: '2px',
    marginBottom: '10px',
    borderRadius: '5px',
    backgroundColor: uiParams.WHITE,
    boxShadow: '0px 2px 4px 0 rgba(0, 0, 0, 0.3)'
  },
  boxSection: {
    display: 'flex',
    color: uiParams.BLACK,
    fontWeight: 'bold',
    fontSize: '16px',
    position: 'relative'
  },
  listStyleUlBox: {
    padding: '0px',
    margin: '12px'
  },
  listStyleBox: {
    padding: '0px',
    color: uiParams.BLACK,
    fontWeight: 'bold',
    fontSize: '16px',
    position: 'relative',
    listStyle: 'none'
  },
  packedBoxOverlay: {
    background: uiParams.GREY1,
    position: 'absolute',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    width: '96%',
    opacity: 0.4,
    zIndex: 8
  },
  boxHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: uiParams.LIGHT_BLUE
  },
  hotBox: {
    backgroundColor: uiParams.LIGHT_BLUE
  },
  coldBox: {
    backgroundColor: uiParams.LIGHT_RED
  },
  boxAction: {
    position: 'relative',
    display: 'flex',
    backgroundColor: uiParams.PRIMARY,
    padding: '2px',
    borderRadius: '3px',
    width: '30px',
    height: '30px',
    zIndex: 9,
    color: uiParams.WHITE,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-end',
    cursor: 'pointer'
  },
  boxPack: {
    width: '20px',
    height: '20px'
  },
  editBoxName: {
    backgroundColor: uiParams.PRIMARY,
    padding: '2px',
    marginRight: '10px',
    marginLeft: '-7px',
    borderRadius: '5px',
    color: uiParams.WHITE,
    width: '20px',
    height: '20px',
    cursor: 'pointer'
  },
  disabled: {
    backgroundColor: uiParams.GREY2
  },
  platePresent: {
    backgroundColor: '#f47b50'
  },
  iconWrapper: {
    width: '30px',
    height: '30px',
    padding: '2px',
    borderRadius: '3px',
    cursor: 'pointer'
  },
  plateIcon: {
    width: '25px',
    height: '25px',
    color: uiParams.WHITE
  },
  headerSectionCold: {
    display: 'flex',
    color: uiParams.BLACK,
    fontWeight: 'bold',
    padding: '4px 8px',
    position: 'relative',
    cursor: 'pointer'
  },
  headerSectionHot: {
    display: 'flex',
    color: uiParams.BLACK,
    fontWeight: 'bold',
    position: 'relative',
    padding: '4px 8px',
    marginTop: '10px'
  },
  timesSection: {
    display: 'flex',
    padding: '0 12px',
    height: '60px',
    width: '80%'
  },
  timeLabel: {
    minWidth: '80px',
    height: '8px',
    fontSize: '11px',
    fontWeight: 600,
    letterSpacing: '0.55px',
    textAlign: 'left',
    color: uiParams.GREY2
  },
  time: {
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: '0.9px',
    textAlign: 'left',
    color: uiParams.BLACK
  },
  dispatchAllSection: {
    position: 'relative',
    display: 'flex',
    width: '44px',
    height: '44px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: uiParams.PRIMARY,
    color: uiParams.WHITE,
    borderRadius: '4px',
    zIndex: 100
  },
  circleStyle: {
    width: 50,
    height: 50
  },
  bulkMark: {
    position: 'absolute',
    top: '8px',
    zIndex: 100
  },
  listSection: {
    paddingBottom: '0px',
    margin: '10px',
    marginTop: 0
  },
  tabPanel: {
    padding: '5px'
  },
  rowCommon: {
    position: 'relative',
    height: '48px',
    padding: '4px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  dishName: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '85%'
  },
  lidImage: {
    width: '30px',
    maxWidth: '30px',
    marginRight: '10px'
  },
  stickerBadge: {
    marginRight: '10px',
    height: '30px',
    width: '30px',
    background: '#5f6470'
  },
  recipeSticker: {
    width: '100%',
    height: '100%'
  },
  darkRow: {
    backgroundColor: uiParams.BG_THEME
  },
  lightRow: {
    backgroundColor: uiParams.WHITE
  },
  dishQuantity: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: uiParams.HORIZONTAL_MARGIN,
    height: uiParams.HORIZONTAL_MARGIN,
    borderRadius: '4px',
    backgroundColor: uiParams.DARK_RED,
    color: uiParams.WHITE
  },
  dishQuantityPacked: {
    backgroundColor: uiParams.PRIMARY,
    marginLeft: '4px'
  },
  hoverEffect: {
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
      transition: 'opacity 0.4s ease-in-out'
    }
  },
  loading: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  dispatcherCircleStyle: {
    width: '100px!important',
    height: '100px!important'
  },
  printAll: {
    float: 'right'
  },
  dishStatusIcon: {
    width: '40px',
    maxWidth: '40px',
    borderRadius: '30px',
    marginTop: '5px',
    alignItems: 'center',
    justify: 'center'
  },
  greenRow: {
    backgroundColor: uiParams.GREEN
  },
  labelsDialog: {
    minWidth: '70vh',
    minHeight: '90vh'
  },
  overlayLoaderContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: '#00000030',
    zIndex: 999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  centerLoaderContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  timeRangeFilter: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingLeft: '32px',
    paddingRight: '32px'
  },
  scanButton: {
    backgroundColor: uiParams.PRIMARY,
    color: uiParams.WHITE,
    margin: '12px',
    '&.Mui-disabled': {
      color: uiParams.WHITE,
      opacity: 0.5
    }
  }
};

export default withStyles(styles)(Dispatcher);
