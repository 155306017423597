import React from 'react';
import QRCode from 'react-qr-code';
import BBLogo from '../../assets/homelogo.svg';
import './css/labelPrint.css';
import { DeliveryBranchInstruction, DispatcherBranch } from './domain';

interface Props {
  branch: DispatcherBranch;
  deliveryDate: Date;
}

function LabelsPrinter(props: Props) {
  if (props.branch.deliveryBoxIds === null) {
    return null;
  } else {
    const boxesCount = props.branch.deliveryBoxIds.length;

    const instructions = (props.branch.deliveryInstructions?.filter(
      (instruction) => instruction.type === 'custom'
    ) ?? []) as DeliveryBranchInstruction[];

    return props.branch.deliveryBoxIds.map((deliveryBoxId, index) => (
      <div className="label" key={index}>
        <div className="border_right" />
        <img src={BBLogo} alt="BellaBona Logo" className="logo" />
        <div className="divider" />
        <div className="section">
          <div className="column">
            <p className="title" style={{ marginBottom: 0 }}>
              Recipient
            </p>
            <p className="bold">{props.branch.deliveryName}</p>
            <span>
              {props.branch.deliveryAddress}, {props.branch.deliveryPostalCode}{' '}
              {props.branch.deliveryCity}
            </span>
          </div>
          <div className="column">
            <div className="driver_name">
              <span className="title" style={{ color: 'white' }}>
                {props.branch.deliveryDriverName}
              </span>
            </div>
          </div>
        </div>
        <div className="divider" />
        <div className="section">
          <div className="column">
            <span className="title" style={{ textTransform: 'capitalize' }}>
              Delivery Time Slot
            </span>
            <span>
              {props.branch.deliverySlotStart} - {props.branch.deliverySlotEnd}
            </span>
          </div>
          <div
            className="column"
            style={{
              alignSelf: 'flex-end'
            }}
          >
            <p
              className="title"
              style={{
                textTransform: 'capitalize',
                textAlign: 'end'
              }}
            >
              Delivery Date
            </p>
            <p style={{ textAlign: 'end', margin: '2px', marginRight: 0 }}>
              {props.deliveryDate.toISOString().slice(0, 10)}
            </p>
          </div>
        </div>
        <div className="divider" />
        <div className="section">
          <div className="column">
            <span
              className="title"
              style={{
                fontSize: '12px',
                fontWeight: 'bolder'
              }}
            >
              Package Number
            </span>
            <span>{deliveryBoxId}</span>
            <QRCode value={deliveryBoxId} size={90} />
          </div>
          <div
            className="column"
            style={{
              alignSelf: 'end',
              justifyContent: 'end'
            }}
          >
            <p className="title" style={{ textAlign: 'end' }}>
              Package
            </p>
            <p className="title" style={{ fontSize: '25px', textAlign: 'end' }}>
              {`${index + 1}/${boxesCount}`}
            </p>
          </div>
        </div>

        {instructions.map((instruction, index) => (
          <div key={index}>
            <div className="divider" />
            <div className="requirement_section">
              <p className="title" style={{ marginBottom: 0, marginTop: 0 }}>
                INSTRUCTIONS
              </p>
              <span>{instruction.value}</span>
            </div>
          </div>
        ))}
      </div>
    ));
  }
}

export default LabelsPrinter;
