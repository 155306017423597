import moment from 'moment';
import {
  convertMinsToHrsMins,
  convertTimestampToTime,
  convertUnixTimeToMinutes
} from '../../../utils/commonUtils';

const formatDateForWeekly = (date) => moment(date).format('ddd-DD/MM/YY');

const createNewDeliveryObject = (deliveryData) => ({
  id: deliveryData.id,
  type: deliveryData.type,
  company: deliveryData.branchName
    ? deliveryData.branchName
    : deliveryData.companyName,
  readyTime: convertMinsToHrsMins(
    deliveryData.branchReadyTime
      ? convertUnixTimeToMinutes(deliveryData.branchReadyTime)
      : deliveryData.readyTime
  ),
  deliveryTime: convertTimestampToTime(
    deliveryData.tzDeliveryTime,
    deliveryData.branchDeliveryTime
      ? convertUnixTimeToMinutes(deliveryData.branchDeliveryTime)
      : deliveryData.deliveryTime
  ),
  deliveryDate: formatDateForWeekly(deliveryData.deliveryDate),
  companyAddress: deliveryData.branchAddress
    ? deliveryData.branchAddress
    : deliveryData.companyAddress,
  driverId: deliveryData.driverId,
  driverName: deliveryData.driverName,
  status: deliveryData.status
});

export const transform = (deliveries) => {
  const result = new Map();
  for (let i = 0; i < deliveries.length; i += 1) {
    const day = formatDateForWeekly(deliveries[i].deliveryDate);
    let existingDeliveriesPerDay = result.get(day);
    if (!existingDeliveriesPerDay) {
      existingDeliveriesPerDay = [];
    }
    existingDeliveriesPerDay.push(createNewDeliveryObject(deliveries[i]));
    result.set(day, existingDeliveriesPerDay);
  }
  return result;
};
