import React from 'react';
import { KitchenOrders } from '../../api/kitchen';
import Query from '../../components/common/Query/Query';
import { useQuery } from '../../hooks/network/Network';
import { DishesQueryInput, dishesQuery } from './api';
import { OrderForecastData } from './OrderForecast';
import OrderMetricBoxes from '../../components/common/OrderMetricBoxes';

interface Props {
  data: OrderForecastData;
  selectedDate: Date;
}

interface NumbersData {
  'Total Forecasted Order': number;
  'Total Orders Placed': number;
  'Remaining Forecasted Order': number;
}

export default function NumbersOfToday(props: Props) {
  const selectedDayStart = new Date(
    props.selectedDate.getUTCFullYear(),
    props.selectedDate.getUTCMonth(),
    props.selectedDate.getUTCDate()
  );

  const selectedDayStartMs = selectedDayStart.getTime();
  const selectedDayEndMs = selectedDayStart.getTime() + 1000 * 60 * 60 * 24 - 1;

  const { response: ordersResponse } = useQuery<
    DishesQueryInput,
    KitchenOrders
  >(dishesQuery, {
    value: {
      deliveryFromDate: selectedDayStartMs,
      deliveryToDate: selectedDayEndMs,
      limit: 500,
      page: 1
    },
    eq: (a, b) => a.deliveryFromDate === b.deliveryFromDate
  });

  const selectedDateSQLString = props.selectedDate.toISOString().slice(0, 10);

  const { remainingForecastQuantity, totalForecastQuantity } =
    props.data.forecastData
      .filter(
        (forecastEntry) => forecastEntry.deliveryDate === selectedDateSQLString
      )
      .reduce(
        (result, forecastEntry) => {
          result.remainingForecastQuantity += forecastEntry.quantity;
          result.totalForecastQuantity += forecastEntry.forecastedQuantity;

          return result;
        },
        { remainingForecastQuantity: 0, totalForecastQuantity: 0 }
      );

  return (
    <Query
      query={ordersResponse}
      shadowChanges={false}
      render={(orders) => {
        const totalOrders = orders.dishes.reduce(
          (sum, dish) =>
            sum + dish.inProcessing + dish.inKitchen + dish.completed,
          0
        );

        const data: NumbersData = {
          'Total Forecasted Order': totalForecastQuantity,
          'Total Orders Placed': totalOrders,
          'Remaining Forecasted Order': remainingForecastQuantity
        };

        return <OrderMetricBoxes numbersData={data} />;
      }}
    />
  );
}
