export const firebaseConfig = {
  // apiKey: process.env.FIREBASE_API_KEY,
  // authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  // databaseURL: process.env.FIREBASE_DATABASE_URL,
  // projectId: process.env.FIREBASE_PROJECT_ID,
  // storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  // messagingSenderId: process.env.FIREBASE_SENDER_ID,
  // appId: process.env.FIREBASE_APP_ID,
  // measurementId: process.env.FIREBASE_MEASUREMENT_ID
  apiKey: 'AIzaSyCItrbbx_DIlABhwt6Jb6JDD3EuQXRiKK8',
  authDomain: 'bellabona-79781.firebaseapp.com',
  databaseURL: 'https://bellabona-79781.firebaseio.com',
  projectId: 'bellabona-79781',
  storageBucket: 'bellabona-79781.appspot.com',
  messagingSenderId: '268815466931',
  appId: '1:268815466931:web:8ee477437db3fae0a2cbc6',
  measurementId: 'G-7FMY3ZZPZ6'
};
