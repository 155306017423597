import {
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,
  ADD_ORDER,
  ADD_UPDATE,
  GET_UPDATES_REQUEST,
  GET_UPDATES_SUCCESS,
  GET_UPDATES_FAILURE,
  UPDATE_ORDER,
  REMOVE_UPDATE_REQUEST,
  GET_CANCELLED_ORDERS_REQUEST,
  GET_CANCELLED_ORDERS_SUCCESS,
  GET_CANCELLED_ORDERS_FAILURE
} from '../types';

const initialState = {
  orders: [],
  cancelledOrders: [],
  error: '',
  getting: false,
  count: 0,
  updates: [],
  updatesError: '',
  gettingUpdates: false
};

export const orders = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS_REQUEST:
      return {
        ...state,
        error: '',
        getting: true
      };
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        getting: false,
        orders: [...action.orders.data.orders],
        count: action.orders.data.orders.count
      };
    case GET_CANCELLED_ORDERS_SUCCESS:
      return {
        ...state,
        getting: false,
        cancelledOrders: [...action.cancelledOrders.data.orders],
        count: action.cancelledOrders.data.count
      };
    case GET_CANCELLED_ORDERS_REQUEST:
      return {
        ...state,
        error: '',
        getting: true
      };
    case GET_CANCELLED_ORDERS_FAILURE:
      return {
        ...state,
        error: action.error,
        getting: false
      };
    case GET_ORDERS_FAILURE:
      return {
        ...state,
        error: action.error,
        getting: false
      };
    case ADD_ORDER:
      return {
        ...state,
        orders: [action.order, ...state.orders],
        count: state.count + 1
      };
    case UPDATE_ORDER:
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (
            order.orderID === action.order.id || // Changed from order.orderID to order.orderID
            order.id === action.order.id
          ) {
            return action.order;
          }
          return order;
        })
      };
    case ADD_UPDATE:
      return {
        ...state,
        updates: [...state.updates, action.update]
      };
    case GET_UPDATES_REQUEST:
      return {
        ...state,
        gettingUpdates: true,
        updatesError: ''
      };
    case GET_UPDATES_SUCCESS:
      return {
        ...state,
        updates: action.updates.data.editRequests,
        gettingUpdates: false
      };
    case GET_UPDATES_FAILURE:
      return {
        ...state,
        updatesError: action.error,
        gettingUpdates: false
      };
    case REMOVE_UPDATE_REQUEST:
      return {
        ...state,
        updates: state.updates.filter(
          (update) => update.id !== action.requestId
        )
      };
    default:
      return state;
  }
};
