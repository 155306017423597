import { makeStyles } from '@material-ui/core/styles';
import * as uiParams from '../../common/uiParams';

export const deliveryGroupStyles = makeStyles({
  emptyDriver: {
    height: '30px'
  },
  formDriver: {
    width: '85%'
  },
  weeklyCardItemDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    margin: '0 22px'
  },
  weeklyCardItemData: {
    display: 'flex',
    color: uiParams.THEME_GREEN_TEXT,
    margin: '8px 0',
    backgroundColor: uiParams.WHITE,
    height: '40px',
    borderRadius: '10px',
    border: `solid 1.5px ${uiParams.GREY2}`,
    lineHeight: '38px'
  },
  weeklyCardItem: {
    display: 'flex',
    marginLeft: '40px',
    marginTop: '40px',
    width: '100%'
  },
  weeklyCardItemDay: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '200px',
    minHeight: '180px',
    borderRadius: '10px',
    backgroundImage: `linear-gradient(to top, ${uiParams.GRADIENT_SEC}, ${uiParams.PRIMARY})`
  },
  cardItemDayName: {
    width: '104px',
    height: '62px',
    fontWeight: 'bold',
    lineHeight: '1.3',
    textAlign: 'center',
    color: uiParams.WHITE,
    fontSize: '37px'
  },
  cardItemDate: {
    color: uiParams.WHITE,
    fontSize: '25px'
  }
});
