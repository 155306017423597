import React from 'react';
import {
  Badge,
  Card,
  CircularProgress,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import InfoIcon from '@material-ui/icons/Info';
import {
  ADDONS_CAT,
  GENERIC_STICKER,
  STICKER_COLOR_MAP
} from '../../../config/constants';
import { getStickerUrl, removeZeroPadding } from '../../../utils/commonUtils';
import * as uiParams from '../../common/uiParams';
import { ClassNameMap, Styles } from '@material-ui/core/styles/withStyles';
import { LaneData } from '../../../api/kitchen';
import { OrderWithLanesData } from '../../../utils/kitchen/sortHelper';

interface Props {
  classes: ClassNameMap;
  orders: OrderWithLanesData[]; // Cannot be empty!
  currentLane: keyof LaneData;
  newTag: boolean;
  onShowRecipeButtonClick: () => void;
  isLoading: boolean;
  onCardArrowClick: (targetLane: keyof LaneData) => void;
  onCardClick: () => void;
}

function DishItem(props: Props) {
  const isProcessingLane = props.currentLane === 'inProcessing';
  const isKitchenLane = props.currentLane === 'inKitchen';
  const isCompletedLane = props.currentLane === 'inCompleted';

  const normalPackagingCount = props.orders.reduce(
    (sum, order) => sum + order.lanesData.normalPackaging[props.currentLane],
    0
  );

  const reusablePackagingCount = props.orders.reduce(
    (sum, order) => sum + order.lanesData.reusablePackaging[props.currentLane],
    0
  );

  const totalCount = normalPackagingCount + reusablePackagingCount;

  const dishName =
    props.orders[0].name.length > 26
      ? props.orders[0].name.slice(0, 26) + '...'
      : props.orders[0].name;

  return (
    <Card className={props.classes.cardStyle}>
      <div className={props.classes.wrapper}>
        {(isProcessingLane || isKitchenLane) && (
          <div className={props.classes.infoIconContainer}>
            <InfoIcon
              className={props.classes.infoIcon}
              onClick={props.onShowRecipeButtonClick}
            />
          </div>
        )}
        <div className={props.classes.beginStyle}>
          {isKitchenLane || isCompletedLane ? (
            <Tooltip
              title={isKitchenLane ? 'Move to Processing' : 'Move to Kitchen'}
            >
              <IconButton
                disabled={props.isLoading}
                className={props.classes.buttonBackStyle}
                onClick={() =>
                  props.onCardArrowClick(
                    isCompletedLane ? 'inKitchen' : 'inProcessing'
                  )
                }
                id={props.orders[0].id}
              >
                <ArrowBackIosIcon className={props.classes.arrowIcon} />
              </IconButton>
            </Tooltip>
          ) : null}
          {props.orders[0].category !== ADDONS_CAT && props.orders[0].num ? (
            <>
              <div
                className={props.classes.stickerBadge}
                style={{
                  backgroundColor:
                    STICKER_COLOR_MAP[
                      removeZeroPadding(
                        props.orders[0].num
                      ) as unknown as keyof typeof STICKER_COLOR_MAP
                    ]
                }}
              >
                <img
                  src={getStickerUrl(props.orders[0].cat, props.orders[0].num)}
                  className={props.classes.recipeSticker}
                  alt="Bella&Bona"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = GENERIC_STICKER;
                  }}
                />
              </div>
            </>
          ) : null}
          <div className={props.classes.badgeContainer}>
            {props.newTag ? (
              <Badge
                classes={{ badge: props.classes.customBadge }}
                badgeContent="NEW"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              />
            ) : null}
            <Typography
              noWrap
              className={props.classes.titleStyle}
              onClick={props.onCardClick}
            >
              {dishName}
            </Typography>
          </div>
        </div>

        <div className={props.classes.endStyle}>
          {props.isLoading && totalCount === 0 ? (
            <CircularProgress
              className={props.classes.circleStyle}
              color="primary"
            />
          ) : (
            <div
              data-testid={`${props.currentLane}-${dishName}-count`}
              role="presentation"
              tabIndex={0}
              className={props.classes.qtyContainerStyle}
              onClick={props.onCardClick}
            >
              <div className={props.classes.pkgOptStyle} role="presentation">
                {reusablePackagingCount}
              </div>

              <div className={props.classes.normalPkgStyle} role="presentation">
                {normalPackagingCount}
              </div>
              <div className={props.classes.qtyStyle} role="presentation">
                {totalCount}
              </div>
            </div>
          )}
          {isKitchenLane || isProcessingLane ? (
            <Tooltip
              title={isKitchenLane ? 'Move to Completed' : 'Move to Kitchen'}
            >
              <IconButton
                disabled={props.isLoading}
                className={props.classes.buttonFwdStyle}
                aria-label="open drawer"
                onClick={() =>
                  props.onCardArrowClick(
                    isProcessingLane ? 'inKitchen' : 'inCompleted'
                  )
                }
              >
                <ArrowForwardIosIcon className={props.classes.arrowIcon} />
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
      </div>
    </Card>
  );
}

const styles: Styles<Theme, {}> = {
  cardStyle: {
    width: '100%',
    marginTop: '8px',
    marginBottom: '8px',
    padding: '5px'
  },
  stickerBadge: {
    marginRight: '10px',
    height: '30px',
    width: '30px',
    background: '#5f6470'
  },
  recipeSticker: {
    width: '100%',
    height: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    minHeight: '60px'
  },
  beginStyle: {
    display: 'flex',
    flex: 0.7,
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  endStyle: {
    display: 'flex',
    flex: 0.3,
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  titleStyle: {
    display: 'flex',
    height: '100%',
    width: '100%',
    fontSize: '14px',
    fontWeight: 'bold',
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    cursor: 'pointer',
    alignItems: 'center'
  },
  qtyContainerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  qtyStyle: {
    width: '30px',
    height: '30px',
    backgroundColor: uiParams.PRIMARY,
    color: uiParams.WHITE,
    borderRadius: '10%',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '2px',
    paddingTop: '4px',
    textAlign: 'center',
    cursor: 'pointer'
  },
  pkgOptStyle: {
    width: '30px',
    height: '30px',
    backgroundColor: uiParams.RELEVO,
    color: uiParams.WHITE,
    borderRadius: '10%',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '2px',
    paddingTop: '4px',
    textAlign: 'center',
    cursor: 'pointer'
  },
  normalPkgStyle: {
    width: '30px',
    height: '30px',
    backgroundColor: uiParams.NORMAL_PKG,
    color: uiParams.WHITE,
    borderRadius: '10%',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '2px',
    paddingTop: '4px',
    textAlign: 'center',
    cursor: 'pointer'
  },
  buttonBackStyle: {
    color: uiParams.GREY2
  },
  buttonFwdStyle: {
    color: uiParams.GREY2
  },
  arrowIcon: {
    fontSize: '14px'
  },
  badgeContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  customBadge: {
    right: -25,
    left: -25,
    backgroundColor: uiParams.DARK_RED,
    color: uiParams.WHITE
  },
  infoIconContainer: {
    color: uiParams.GREY1,
    display: 'flex',
    alignItems: 'center'
  },
  infoIcon: {
    width: '32px',
    height: '32px',
    marginRight: '10px',
    '&:hover': {
      cursor: 'pointer',
      transition: 'opacity 0.4s ease-in-out'
    }
  },
  stickerWrapper: {
    width: '30px',
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px'
  },
  stickerImg: {
    width: '30px'
  }
};

export default withStyles(styles)(DishItem);
