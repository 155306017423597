import * as uiParams from '../../common/uiParams';

export const styles = {
  container: {
    height: '95%',
    pageBreakBefore: 'always' as const,
    position: 'relative' as const
  },
  deliveryNoteTitle: {
    textAlign: 'right' as const,
    marginRight: '-50px'
  },
  imageStyle: {
    display: 'block',
    width: '150px',
    height: '75px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  clientInfo: {
    color: uiParams.BLACK,
    fontWeight: 'bold' as const,
    marginLeft: '20px',
    marginBottom: '20px'
  },
  returnBowl: {
    display: 'flex' as const,
    alignItems: 'center' as const,
    justifyContent: 'center' as const,
    color: uiParams.BLACK,
    fontWeight: 'bold' as const,
    fontSize: '16px'
  },
  companyName: {
    marginRight: '20px'
  },
  companyAdd: {
    marginRight: '20px'
  },
  table: {
    boxSizing: 'border-box' as const,
    padding: '10px',
    borderStyle: 'solid' as const,
    display: 'flex' as const,
    alignItems: 'center' as const,
    justifyContent: 'center' as const,
    flexDirection: 'column' as const
  },
  deliveryNoteClientInfo: {
    color: uiParams.BLACK,
    margin: '50px',
    display: 'flex' as const,
    alignItems: 'center' as const,
    justifyContent: 'space-between' as const
  },
  deliveryNoteFooter: {
    borderTop: '1px solid black',
    position: 'absolute' as const,
    bottom: 0,
    color: uiParams.BLACK,
    display: 'flex' as const,
    alignItems: 'center' as const,
    justifyContent: 'center' as const,
    flexDirection: 'column' as const,
    fontSize: '8px',
    width: '100%'
  },
  deliveryNoteFooterP: {
    marginBottom: '10px'
  },
  deliveryNotePageNo: {
    textAlign: 'right' as const,
    marginRight: '10px'
  },
  deliveryNoteTable: {
    margin: '30px',
    padding: '10px',
    borderStyle: 'solid' as const,
    borderWidth: '1px'
  },
  deliveryNoteTableCell: { fontSize: '10px' },
  deliveryNote: {
    display: 'flex' as const,
    alignItems: 'center' as const,
    justifyContent: 'right' as const
  },
  deliveryNoteH2: {
    paddingRight: '10px',
    marginBottom: 0
  },
  dishCategory: {
    fontWeight: 'bold' as const,
    borderBottom: '1px solid black',
    display: 'flex' as const,
    justifyContent: 'space-between' as const
  },
  lidImage: {
    width: '100%',
    maxWidth: '35px',
    display: 'block' as const
  },
  tableCell: {
    padding: '12px'
  }
};
