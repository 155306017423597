import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Theme } from '@material-ui/core';
import ComponentToPrint, { PrintComponentVisibility } from './ComponentToPrint';
import { Tooltip } from '../../components/common/Tooltip/Tooltip';
import * as uiParams from '../common/uiParams';
import { ClassNameMap, Styles } from '@material-ui/core/styles/withStyles';
import { DispatcherBranch } from './domain';

interface Props {
  classes: ClassNameMap;
  deliveryDate: Date;
  branches: DispatcherBranch[];
}

function LabelPrintContainer(props: Props) {
  const [printComponentsVisibility, setPrintComponentsVisibility] =
    useState<PrintComponentVisibility>('hidden');

  const isDisabled = props.branches.length === 0;

  return (
    <div>
      <div className={props.classes.hide}>
        {(() => {
          switch (printComponentsVisibility) {
            case 'hidden':
              return null;
            case 'visible':
              return (
                <ComponentToPrint
                  type="labelsData"
                  branches={props.branches}
                  deliveryDate={props.deliveryDate}
                  printStyle={props.classes.pri}
                  onClose={() => setPrintComponentsVisibility('hidden')}
                />
              );
          }
        })()}
      </div>
      <Tooltip show tooltipText="Print all company Labels">
        <Button
          onClick={() => setPrintComponentsVisibility('visible')}
          className={[
            props.classes.printLabels,
            isDisabled ? props.classes.disabled : ''
          ].join(' ')}
          disabled={isDisabled}
        >
          Labels
        </Button>
      </Tooltip>
    </div>
  );
}

const styles: Styles<Theme, {}> = {
  printIcon: {
    width: '100%',
    height: '100%'
  },
  hide: {
    display: 'none',
    color: 'red'
  },
  printLabels: {
    backgroundColor: uiParams.PRIMARY,
    color: uiParams.WHITE
  },
  disabled: {
    backgroundColor: uiParams.GREY2,
    color: uiParams.WHITE
  }
};

export default withStyles(styles)(LabelPrintContainer);
