import React, { useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  TextField,
  Theme,
  withStyles
} from '@material-ui/core';
import * as uiParams from './uiParams';
import { BLACK } from './uiParams';
import { ClassNameMap, Styles } from '@material-ui/core/styles/withStyles';

interface Props {
  classes: ClassNameMap;
  onClose: (value: number) => void;
  onEscape: () => void;
  defaultQuantity: number;
  maxQuantity: number;
  dialogTitle: string;
  open: boolean;
}

function QuantityPopUp(props: Props) {
  const inputRef = useRef<HTMLDivElement>(null);
  const [valid, setValid] = useState(true);

  const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter': {
        const target = e.target as HTMLInputElement;
        const value = parseInt(target.value, 10);

        if (isNaN(value) || value <= 0) {
          setValid(false);
        } else {
          props.onClose(value);
        }

        break;
      }
      case 'Escape':
        props.onEscape();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.setTimeout(() => {
      if (inputRef.current) {
        const input = inputRef.current.getElementsByTagName('input').item(0);
        input && input.focus();
      }
    });
  }, []);

  return (
    <Dialog
      className={props.classes.dialogStyle}
      onClose={props.onEscape}
      open={props.open}
    >
      <DialogTitle id="dispatcherDialog">{props.dialogTitle}</DialogTitle>
      <div className={props.classes.dishQuantitySection}>
        <span className={props.classes.dishQuantityLabel}>Max:</span>
        <div className={props.classes.dishMaxQuantity}>{props.maxQuantity}</div>
      </div>
      <TextField
        ref={inputRef}
        type="number"
        label="Quantity"
        error={!valid}
        defaultValue={props.defaultQuantity}
        onKeyUp={handleEnterKey}
        className={props.classes.textField}
        margin="normal"
        variant="outlined"
        InputProps={{
          inputProps: {
            min: 0,
            max: props.maxQuantity
          },
          classes: {
            notchedOutline: props.classes.notchedOutline
          }
        }}
      />
    </Dialog>
  );
}

const styles: Styles<Theme, {}> = {
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    width: '150px',
    borderRadius: uiParams.RAD_GLOBAL,
    backgroundColor: uiParams.BG_TEXT_FLD,
    margin: uiParams.HORIZONTAL_MARGIN,
    alignSelf: 'center'
  },
  cssLabel: {
    color: '#aaa'
  },
  cssOutlinedInput: {
    $notchedOutline: {
      borderColor: uiParams.WHITE
    }
  },
  notchedOutline: {
    width: '100%',
    borderRadius: uiParams.RAD_GLOBAL
  },
  dialogStyle: {
    alignItems: 'center'
  },
  dishQuantitySection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dishQuantityLabel: {
    color: BLACK,
    fontSize: '18px',
    fontWeight: 600,
    letterSpacing: '1px',
    marginRight: '8px'
  },
  dishMaxQuantity: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: uiParams.HORIZONTAL_MARGIN,
    height: uiParams.HORIZONTAL_MARGIN,
    borderRadius: '4px',
    backgroundColor: uiParams.PRIMARY,
    color: uiParams.WHITE
  }
};

export default withStyles(styles)(QuantityPopUp);
