import { API_ENDPOINT } from '../config/constants';
import { handleResponse } from './index';
import { setLocalStorage } from '../utils/storageUtils';
import { requestFirebaseNotificationPermission } from '../utils/firebaseInit';
import '@firebase/messaging';

export interface AuthTokens {
  token: string;
}

export interface AuthCredentials {
  appVersion: string;
  platform: string;
  firebaseToken: string | 'notApplicable';
}

export async function refreshToken(refreshToken: string): Promise<AuthTokens> {
  const response = await window.fetch(`${API_ENDPOINT}/api/v1/auth/refresh`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ refreshToken }),
    credentials: 'include'
  });

  const authTokens = await handleResponse<AuthTokens>(response);

  setLocalStorage('user', authTokens.data);
  return authTokens.data;
}

export function authHeader(): RequestInit {
  const requestObject: RequestInit = {
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  };

  return requestObject;
}

export async function signIn(
  credentials: AuthCredentials
): Promise<AuthTokens> {
  const firebaseTokenData: AuthCredentials['firebaseToken'] =
    await (async () => {
      try {
        return await requestFirebaseNotificationPermission();
      } catch (e) {
        return 'notApplicable';
      }
    })();

  const updatedCredentials: AuthCredentials = {
    ...credentials,
    appVersion: '1.0.0',
    platform: 'oms_web',
    firebaseToken: firebaseTokenData
  };

  const options = {
    method: 'POST',
    ...authHeader(),
    body: JSON.stringify(updatedCredentials)
  };

  const response = await fetch(`${API_ENDPOINT}/api/v1/auth/signin/`, options);
  const authTokens = await handleResponse<AuthTokens>(response);

  setLocalStorage('user', authTokens.data);
  return authTokens.data;
}
