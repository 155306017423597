import history from '../../config/history';
import { signIn } from '../../api';
import { clearLocalStorage } from '../../utils/storageUtils';
import sessionStorageHelper from '../../utils/common/sessionStorageHelper';
import { AuthCredentials, AuthTokens } from '../../api/auth';
import { Dispatch } from 'redux';

interface SignInRequest {
  type: 'SIGN_IN_REQUEST';
}
function signInRequest(): SignInRequest {
  return {
    type: 'SIGN_IN_REQUEST'
  };
}

interface SignInSuccess {
  type: 'SIGN_IN_SUCCESS';
  authTokens: AuthTokens;
}
function signInSuccess(authTokens: AuthTokens): SignInSuccess {
  return {
    type: 'SIGN_IN_SUCCESS',
    authTokens
  };
}

interface SignInFailure {
  type: 'SIGN_IN_FAILURE';
  error: Error;
}
function signInFailure(error: Error): SignInFailure {
  return {
    type: 'SIGN_IN_FAILURE',
    error
  };
}

interface Logout {
  type: 'LOGOUT_SUCCESS';
}
function logout(): Logout {
  return {
    type: 'LOGOUT_SUCCESS'
  };
}

type SignInAction = SignInRequest | SignInSuccess | SignInFailure | Logout;

export function signInAction(
  credentials: AuthCredentials
): (dispatch: Dispatch<SignInAction>) => Promise<void> {
  return async (dispatch) => {
    dispatch(signInRequest());

    try {
      const authTokens = await signIn(credentials);
      dispatch(signInSuccess(authTokens));
    } catch (error) {
      dispatch(signInFailure(error as Error));
    }
  };
}

export function logoutAction(): (dispatch: Dispatch<SignInAction>) => void {
  return (dispatch) => {
    clearLocalStorage('user');
    sessionStorageHelper.clear();
    history.push('/auth');
    dispatch(logout());
  };
}
