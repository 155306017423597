import { ForecastEntry } from '../../api/kitchen';
import {
  makeGetNetworkRequest,
  makePostNetworkRequest,
  makePutNetworkRequest
} from '../../hooks/network/Network';

export function makeForecastDataQuery(deliveryDate: Date) {
  const dateString = deliveryDate.toISOString().slice(0, 10);

  return makeGetNetworkRequest({
    path: `/api/v1/kitchen/orders/forecasting/${dateString}`
  });
}

export function makeMenuQuery(deliveryDate: Date) {
  const dateString = deliveryDate.toISOString().slice(0, 10);

  return makeGetNetworkRequest({
    path: `/api/v1/kitchen/orders/menu/${dateString}`
  });
}

export interface DishesQueryInput {
  deliveryFromDate: number;
  deliveryToDate: number;
  limit: 500;
  page: 1;
}

export const dishesQuery = makeGetNetworkRequest({
  path: `/api/v1/kitchen/orders/dishes`
});

export interface ForecastTimeRange {
  id: string;
  name: string;
  startTime: string;
  endTime: string;
}

export type UpdateForecastDataCommandInput = ForecastEntry[];

export const updateForecastDataCommand = makePostNetworkRequest({
  path: '/api/v1/kitchen/orders/forecasting'
});

export type UpdateStockCommandInput = Array<{ id: string; stock: number }>;

export const updateStockCommand = makePutNetworkRequest({
  path: '/api/v1/kitchen/orders/dish/stock'
});
