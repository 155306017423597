import React from 'react';
import { CircularProgress } from '@material-ui/core';
import {
  NetworkResponse,
  matchNetworkResponse
} from '../../../hooks/network/Network';
import './Query.css';

interface Props<T> {
  query: NetworkResponse<T>;
  shadowChanges: boolean;
  render: (data: T) => JSX.Element | JSX.Element[] | null;
}

export default function Query<T>(props: Props<T>) {
  return (
    <div className="Query">
      {matchNetworkResponse(props.query, {
        whenIdle: () => null,
        whenLoading: (previousData) => {
          if (props.shadowChanges && previousData) {
            return props.render(previousData);
          } else {
            return <CircularProgress />;
          }
        },
        // TODO: actually handle error
        whenFailed: (error) => <p className="error">{error.message}</p>,
        whenSuccessful: props.render
      })}
    </div>
  );
}
