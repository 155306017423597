import MaterialTable from 'material-table';
import moment from 'moment';
import React, { useContext } from 'react';
import { USER_SELECTED_DATE } from '../../config/constants';
import { setLocalStorage } from '../../utils/storageUtils';
import SearchBar from '../common/SearchBar';
import VytalCountContext from './Context';
import './vytalCount.css';

export const VytalCount = () => {
  const { dateState, tableDataState } = useContext(VytalCountContext);
  const [selectedDate, setSelectedDate] = dateState;
  const [tableData] = tableDataState;

  const onDateChanged = (date) => {
    setSelectedDate(date);
    setLocalStorage(USER_SELECTED_DATE, moment(date));
  };

  return (
    <div className="wrapper">
      <SearchBar
        onPickDate={onDateChanged}
        currentSelectedDate={selectedDate}
        onSearch={() => {}}
        title="Vytal Qr"
      />

      <div className="screen_content">
        <MaterialTable
          title="Total QR"
          columns={[
            {
              title: 'Branch Name',
              field: 'name',
              width: '10%'
            },
            { title: 'qrCode', field: 'qrCode', width: '10%' },
            {
              title: 'Sent To Vytal ?',
              field: 'isSentToVytal',
              render: (rowData) => (
                <div>
                  {rowData.isSentToVytal ? <span>Yes</span> : <span>No</span>}
                </div>
              )
            },
            {
              title: 'count',
              field: 'count',
              width: '10%'
            },
            {
              title: 'qr',
              field: 'qr',
              render: (rowData) => (
                <div
                  style={{
                    maxHeight: '100px',
                    overflow: 'scroll'
                  }}
                >
                  {rowData.qr.map((ele) => (
                    <p>{ele}</p>
                  ))}
                </div>
              )
            }
          ]}
          data={tableData}
          options={{
            paging: false
          }}
          onSelectionChange={() => {}}
        />
      </div>
    </div>
  );
};

export default VytalCount;
