import moment from 'moment';
import React, { useEffect, useState } from 'react';
import OrderForecastContext from '..';
import {
  getDishesOrdersForWeek,
  getForecastData,
  getMenu
} from '../../../../api/kitchen';
import { weekData } from '../../../../config/constants';
import { getInitialSelectedDate } from '../../../../utils/commonUtils';

const KitchenCountContextProvider = ({ children }) => {
  const [forecastData, setForecastData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [menu, setMenu] = useState([]);
  const [selectedDate, setSelectedDate] = useState(getInitialSelectedDate());
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getMenu(moment(selectedDate).format('YYYY-MM-DD')).then((res) => {
      setMenu(res);
    });
    getForecastData(moment(selectedDate).format('YYYY-MM-DD')).then((res) => {
      setForecastData(res);
    });
    getDishesOrdersForWeek(moment(selectedDate).format('YYYY-MM-DD')).then(
      (res) => {
        setOrderData(res.dishes);
      }
    );
  }, [selectedDate]);

  useEffect(() => {
    if (menu && menu.length > 0) {
      const days = {};
      const day = moment(selectedDate).startOf('isoWeek');

      for (let i = 1; i <= 6; i += 1) {
        days[`${day.format('ddd')}`] = day.format('YYYY-MM-DD');
        day.add(1, 'day');
      }

      const data = menu.map((dish) => {
        const totalForecastQuantity = [];
        const totalActualOrderQuantity = [];
        const remainingForecastQuantity = [];
        const forecastedWeekData = {};

        // complete weekData
        weekData
          .map((d) => d.name)
          .forEach((key) => {
            totalForecastQuantity[key] = forecastData.filter(
              (fd) =>
                fd.itemNo.indexOf(dish.itemNo) > -1 &&
                fd.deliveryDate === days[key]
            )?.[0]?.forecastedQuantity;

            remainingForecastQuantity[key] = forecastData.filter(
              (fd) =>
                fd.itemNo.indexOf(dish.itemNo) > -1 &&
                fd.deliveryDate === days[key]
            )?.[0]?.quantity;

            totalActualOrderQuantity[key] = orderData
              .filter(
                (d) =>
                  d.itemNo.indexOf(dish.itemNo) > -1 &&
                  d.deliveryDate === days[key]
              )
              .reduce((acc, cv) => acc + cv.quantity, 0);

            forecastedWeekData[key] = {
              deliveryDate: days[key],
              quantity: remainingForecastQuantity[key] || 0,
              totalQuantity: totalForecastQuantity[key] || 0,
              actualOrderQuantity: totalActualOrderQuantity[key] || 0,
              isToday: moment(new Date()).format('YYYY-MM-DD') === days[key]
            };
          });

        return {
          id: dish.id,
          name: dish.name,
          itemNo: dish.itemNo,
          stock: dish.stock,
          category: dish.categoryName,
          ...forecastedWeekData
        };
      });

      const mainDishes = data
        .filter((recipe) => recipe.category !== 'Addons')
        .sort((a, b) => a.name.localeCompare(b.name));

      const addOns = data
        .filter((recipe) => recipe.category === 'Addons')
        .sort((a, b) => a.name.localeCompare(b.name));

      setTableData([...mainDishes, ...addOns]);
    } else {
      setTableData([]);
    }
  }, [menu, forecastData, orderData, selectedDate]);

  return (
    <OrderForecastContext.Provider
      value={{
        forecastDataState: [forecastData, setForecastData],
        menuDataState: [setMenu],
        menu,
        tableDataState: [tableData, setTableData],
        dateState: [selectedDate, setSelectedDate]
      }}
    >
      {children}
    </OrderForecastContext.Provider>
  );
};

export default KitchenCountContextProvider;
