import React, { FormEventHandler, useEffect, useRef, useState } from 'react';
import { DishScanningBranchState } from './ScanningBranchState';
import { bowlPattern, labelPattern, PASSTHROUGH_BOWL_CODE } from './qrPatterns';
import {
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  Grid,
  Input,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@material-ui/core';
import { getBoxName } from './getBoxName';
import { getStickerUrl } from '../../../utils/commonUtils';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { v4 } from 'uuid';

interface DishFormProps {
  state: DishScanningBranchState;
  onBoxSkip: () => void;
  onBowlScan: (bowlCode: string) => void;
  onLabelScan: (label: string) => void;
  onResetCurrentDish: () => void;
  deliveryDate: Date;
}

export function DishForm(props: DishFormProps) {
  const [input, setInput] = useState('');
  const [shouldShowNext, setShouldShowNext] = useState(true);
  const [shouldShowPrevious, setShouldShowPrevious] = useState(false);
  const inputContainerRef = useRef<HTMLDivElement>(null);

  const isLoading = props.state.type === 'ScannedDish';

  const inputTitle = (() => {
    switch (props.state.type) {
      case 'EmptyDish':
        return 'Bowl or label scan';
      case 'DishBowlCode':
        return 'Bowl scan';
      case 'DishLabel':
        return 'Label scan';
      case 'ScannedDish':
        return null;
      case 'DuplicateBowlCodeError':
      case 'WrongLabel':
        return 'Error';
    }
  })();

  const state = (() => {
    switch (props.state.type) {
      case 'EmptyDish':
      case 'DishBowlCode':
      case 'DishLabel':
      case 'ScannedDish':
        return props.state;
      case 'DuplicateBowlCodeError':
      case 'WrongLabel':
        return props.state.previousState;
    }
  })();

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const subject = input.toUpperCase();

    if (labelPattern.test(subject)) {
      const [, label] = labelPattern.exec(subject) as RegExpExecArray;
      props.onLabelScan(label);
    } else if (subject === PASSTHROUGH_BOWL_CODE || bowlPattern.test(subject)) {
      props.onBowlScan(
        subject === PASSTHROUGH_BOWL_CODE ? `bamboo://${v4()}` : subject
      );
    }

    setInput('');
  };

  useEffect(() => {
    if (!isLoading && inputContainerRef.current) {
      const input = inputContainerRef.current.querySelector(
        '#scannedValue'
      ) as HTMLInputElement | null;

      input?.focus();

      if ('virtualKeyboard' in navigator) {
        // @ts-ignore
        navigator.virtualKeyboard.hide();
      }
    }
  }, [isLoading]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '1.5em',
          marginBottom: '1.5em'
        }}
      >
        <Typography
          variant="button"
          style={{
            fontWeight: 'bold',
            color: (() => {
              switch (state.box.type) {
                case 'cold':
                  return '#3f51b5';
                case 'hot':
                  return '#f44336';
              }
            })()
          }}
        >
          {state.box.type.toUpperCase()}, {state.dishes.toArray().length} dishes
        </Typography>
        <Button
          variant="contained"
          onClick={props.onBoxSkip}
          disabled={isLoading}
        >
          Skip
        </Button>
      </div>
      <Typography variant="h5">{getBoxName(state.box)}</Typography>
      <Typography variant="h6">{inputTitle}</Typography>
      <Grid container spacing={2}>
        <Grid item>
          <form style={{ marginTop: '1em' }} onSubmit={onSubmit}>
            <FormControl variant="outlined" focused>
              <Input
                id="scannedValue"
                name="scannedValue"
                value={input}
                onChange={(e) => {
                  const currentTarget = e.currentTarget;

                  if (currentTarget !== null) {
                    setInput(currentTarget.value);
                  }
                }}
                autoFocus
                ref={inputContainerRef}
                disabled={isLoading}
              />
            </FormControl>
          </form>
        </Grid>
        <Grid item hidden={!isLoading}>
          <CircularProgress />
        </Grid>
      </Grid>
      {props.state.type === 'DishLabel' ||
      props.state.type === 'DishBowlCode' ? (
        <Button
          onClick={props.onResetCurrentDish}
          style={{ marginTop: '0.5em' }}
        >
          Reset current scan
        </Button>
      ) : null}
      <List>
        {(() => {
          switch (props.state.type) {
            case 'EmptyDish':
              return null;
            case 'DishLabel':
              return (
                <ListItem>
                  <ListItemText primary="Scanned bowl, waiting for label" />
                </ListItem>
              );
            case 'DishBowlCode': {
              return (
                <ListItem>
                  <ListItemAvatar>
                    <img
                      src={getStickerUrl(
                        props.state.dish.label === 'addons' ? 'AD' : 'DL',
                        props.state.dish.label
                      )}
                      alt={props.state.dish.label}
                      style={{ width: '32px' }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <p
                        style={{
                          whiteSpace: 'nowrap',
                          width: '23em',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          margin: 0
                        }}
                      >
                        {props.state.dish.name}
                      </p>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Button
                      disabled
                      style={{
                        backgroundColor: '#ce2b37',
                        color: '#fff',
                        minWidth: '2.5em'
                      }}
                    >
                      {props.state.dish.totalQty - props.state.dish.packedQty}
                    </Button>
                    <Button
                      disabled
                      style={{
                        backgroundColor: '#0e6f37',
                        color: '#fff',
                        minWidth: '2.5em',
                        marginLeft: '0.5em'
                      }}
                    >
                      {props.state.dish.packedQty}
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            }
            case 'DuplicateBowlCodeError':
            case 'WrongLabel':
              return (
                <div>
                  <Typography variant="h5" color="error">
                    Error
                  </Typography>
                  <Typography
                    variant="h6"
                    color="error"
                    style={{ marginTop: '1em' }}
                  >
                    {(() => {
                      switch (props.state.type) {
                        case 'DuplicateBowlCodeError':
                          return 'This bowl has already been scanned';
                        case 'WrongLabel':
                          return 'This recipe is not in the todo list for this box';
                      }
                    })()}
                  </Typography>
                  <Button
                    style={{ marginTop: '1em', marginBottom: '1em' }}
                    color="primary"
                    variant="contained"
                    onClick={props.onResetCurrentDish}
                  >
                    Dismiss
                  </Button>
                </div>
              );
            case 'ScannedDish':
              return null;
          }
        })()}
        {(() => {
          switch (props.state.type) {
            case 'DuplicateBowlCodeError':
            case 'WrongLabel':
              return null;
            default:
              return (
                <>
                  <ListItem button>
                    <ListItemText
                      primary={`Next (${props.state.dishes.getTodo().length})`}
                      onClick={() => setShouldShowNext((_) => !_)}
                    />
                    {shouldShowNext ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={shouldShowNext}>
                    {props.state.dishes.getTodo().map((dish) => (
                      <ListItem key={dish.id}>
                        <ListItemAvatar>
                          <img
                            src={getStickerUrl(
                              dish.label === 'addons' ? 'AD' : 'DL',
                              dish.label
                            )}
                            alt={dish.label}
                            style={{ width: '32px' }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <p
                              style={{
                                whiteSpace: 'nowrap',
                                width: '23em',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                margin: 0
                              }}
                            >
                              {dish.name}
                            </p>
                          }
                        />
                        <ListItemSecondaryAction>
                          <Button
                            disabled
                            style={{
                              backgroundColor: '#ce2b37',
                              color: '#fff',
                              minWidth: '2.5em'
                            }}
                          >
                            {dish.totalQty - dish.packedQty}
                          </Button>
                          <Button
                            disabled
                            style={{
                              backgroundColor: '#0e6f37',
                              color: '#fff',
                              minWidth: '2.5em',
                              marginLeft: '0.5em'
                            }}
                          >
                            {dish.packedQty}
                          </Button>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </Collapse>
                  <ListItem button>
                    <ListItemText
                      primary={`Previous (${
                        props.state.dishes.getDone().length
                      })`}
                      onClick={() => setShouldShowPrevious((_) => !_)}
                    />
                    {shouldShowPrevious ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={shouldShowPrevious}>
                    {props.state.dishes.getDone().map((dish) => (
                      <ListItem key={dish.id}>
                        <ListItemAvatar>
                          <img
                            src={getStickerUrl(
                              dish.label === 'addons' ? 'AD' : 'DL',
                              dish.label
                            )}
                            alt={dish.label}
                            style={{ width: '32px' }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <p
                              style={{
                                whiteSpace: 'nowrap',
                                width: '23em',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                margin: 0
                              }}
                            >
                              {dish.name}
                            </p>
                          }
                        />
                        <ListItemSecondaryAction>
                          <Button
                            disabled
                            style={{
                              backgroundColor: '#ce2b37',
                              color: '#fff',
                              minWidth: '2.5em'
                            }}
                          >
                            {dish.totalQty - dish.packedQty}
                          </Button>
                          <Button
                            disabled
                            style={{
                              backgroundColor: '#0e6f37',
                              color: '#fff',
                              minWidth: '2.5em',
                              marginLeft: '0.5em'
                            }}
                          >
                            {dish.packedQty}
                          </Button>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </Collapse>
                </>
              );
          }
        })()}
      </List>
    </div>
  );
}
