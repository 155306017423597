import React, { ChangeEventHandler, useEffect, useState } from 'react';
import MaterialTable, { Column } from 'material-table';
import { OrderForecastData } from './OrderForecast';
import * as uiParams from '../common/uiParams';
import { ForecastEntry } from '../../api/kitchen';
import { InputAdornment, TextField } from '@material-ui/core';
import {
  ForecastTimeRange,
  UpdateForecastDataCommandInput,
  UpdateStockCommandInput,
  updateForecastDataCommand,
  updateStockCommand
} from './api';
import { mergeNetworkResponses, useCommand } from '../../hooks/network/Network';

interface Props {
  selectedDate: Date;
  data: OrderForecastData;
  onUpdate: () => void;
}

type TableRow = {
  id: string;
  name: string;
  itemNo: string;
  stock: number;
  dailyForecast: number;
  category: string;
  timeRanges: ForecastTimeRange[];
} & Record<string, ForecastEntry>;

export default function ForecastTableDaily(props: Props) {
  const {
    response: forecastDataUpdateResponse,
    sendRequest: updateForecastData
  } = useCommand<UpdateForecastDataCommandInput, unknown>(
    updateForecastDataCommand
  );

  const { response: stockUpdateResponse, sendRequest: updateStock } =
    useCommand<UpdateStockCommandInput, unknown>(updateStockCommand);

  const updateResponse = mergeNetworkResponses({
    forecast: forecastDataUpdateResponse,
    stock: stockUpdateResponse
  });

  const selectedDateSQLString = props.selectedDate.toISOString().slice(0, 10);

  const columns: Column<TableRow>[] = [
    { title: 'Dish Name', field: 'name', editable: 'never' },
    { title: 'Stock', field: 'stock', type: 'numeric' },
    { title: 'Daily Forecast', field: 'dailyForecast', editable: 'never' },
    { title: 'Item Number', field: 'itemNo', editable: 'never' },
    ...props.data.forecastTimeRanges.map<Column<TableRow>>((range) => ({
      title: range.name,
      field: range.name,
      type: 'numeric',
      editable: (_, row) => row.category !== 'Addons',
      render: (rowData) => (
        <div
          style={{
            margin: 'auto',
            padding: '3px 10px',
            width: 'fit-content',
            justifyContent: 'center',
            borderRadius: '5px',
            backgroundColor: (() => {
              const entry = rowData[range.name];

              if (entry) {
                const itemNo = entry.itemNo.slice(5);

                const stock =
                  props.data.menu.find((recipe) => recipe.itemNo === itemNo)
                    ?.stock ?? 0;

                if (entry.forecastedQuantity === 0 && stock !== 0) {
                  return uiParams.LIGHT_RED;
                } else if (entry.quantity > 0) {
                  return uiParams.GREEN;
                } else {
                  return 'transparent';
                }
              } else {
                return 'transparent';
              }
            })()
          }}
        >
          <span>
            {typeof rowData[range.name]?.quantity === 'undefined'
              ? '-'
              : rowData[range.name].quantity}
          </span>
        </div>
      )
    }))
  ];

  const forecastDataOfToday = props.data.forecastData.filter(
    (forecastEntry) => forecastEntry.deliveryDate === selectedDateSQLString
  );

  const mainDishRows = props.data.menu
    .filter((dish) => dish.categoryName !== 'Addons')
    .map((dish) => {
      const dailyForecastEntries = forecastDataOfToday.filter((forecastEntry) =>
        forecastEntry.itemNo.includes(dish.itemNo)
      );

      const dailyForecast = dailyForecastEntries.reduce(
        (sum, entry) => sum + entry.quantity,
        0
      );

      const forecastDataByTimeRange = props.data.forecastTimeRanges.reduce<
        Record<
          string,
          Omit<ForecastEntry, 'id' | 'kitchenId'> & {
            id: string | null;
            kitchenId: string | null;
          }
        >
      >((result, range) => {
        const forecastEntry = dailyForecastEntries.find(
          (forecastEntry) => forecastEntry.timeRangeId === range.id
        );

        result[range.name] = forecastEntry || {
          createdAt: new Date().toISOString(),
          deliveryDate: props.selectedDate.toISOString().slice(0, 10),
          forecastedQuantity: 0,
          id: null,
          itemNo: dish.itemNo,
          kitchenId: props.data.forecastData[0]?.kitchenId ?? null,
          quantity: 0,
          updatedAt: new Date().toISOString(),
          timeRangeId: range.id
        };

        return result;
      }, {});

      return {
        id: dish.id,
        name: dish.name,
        stock: dish.stock,
        itemNo: dish.itemNo,
        dailyForecast,
        category: dish.categoryName,
        timeRanges: props.data.forecastTimeRanges,
        ...forecastDataByTimeRange
      } as TableRow;
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const addonRows = props.data.menu
    .filter((dish) => dish.categoryName === 'Addons')
    .map(
      (dish) =>
        ({
          id: dish.id,
          name: dish.name,
          stock: dish.stock,
          itemNo: dish.itemNo,
          dailyForecast: 0,
          category: dish.categoryName,
          timeRanges: props.data.forecastTimeRanges
        } as TableRow)
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  const tableData = [...mainDishRows, ...addonRows];

  const onRowUpdate = async (
    newData: TableRow,
    oldData?: TableRow
  ): Promise<void> => {
    if (oldData) {
      return onBulkUpdate({
        0: { oldData, newData }
      });
    }
  };

  const onBulkUpdate = async (
    data: Record<number, { oldData: TableRow; newData: TableRow }>
  ): Promise<void> => {
    const changes = Object.values(data);

    const stockChanges = changes
      .filter((change) => change.oldData.stock !== change.newData.stock)
      .map((change) => ({
        id: change.newData.id,
        stock: change.newData.stock
      }));

    const forecastChanges = changes.flatMap((change) => {
      const result: ForecastEntry[] = [];

      props.data.forecastTimeRanges.forEach((range) => {
        if (range.name in change.oldData && range.name in change.newData) {
          const oldValue = change.oldData[range.name];
          const newValue = change.newData[range.name];

          if (oldValue.id === null || oldValue.quantity !== newValue.quantity) {
            result.push(newValue);
          }
        }
      });

      return result;
    });

    const promises = [
      ...(stockChanges.length ? [updateStock(stockChanges)] : []),
      ...(forecastChanges.length ? [updateForecastData(forecastChanges)] : [])
    ];

    return Promise.all(promises).then(() => {
      props.onUpdate();
    });
  };

  return (
    <MaterialTable
      title="Stocks & Forecasted Orders"
      columns={columns}
      data={tableData}
      options={{ paging: false }}
      editable={{ onRowUpdate, onBulkUpdate }}
      components={{ EditField }}
      isLoading={updateResponse.type === 'loading'}
    />
  );
}

interface CommonEditFieldProps {
  rowData: TableRow;
}

interface StockEditFieldProps extends CommonEditFieldProps {
  columnDef: Column<TableRow>;
  value: number;
  onChange: (value: number) => void;
}

interface DayEditFieldProps extends CommonEditFieldProps {
  columnDef: Column<TableRow>;
  value: ForecastEntry;
  onChange: (value: ForecastEntry) => void;
}

type EditFieldProps = StockEditFieldProps | DayEditFieldProps;

function isStockEditField(props: EditFieldProps): props is StockEditFieldProps {
  return props.columnDef.field === 'stock';
}

function EditField(props: EditFieldProps) {
  if (isStockEditField(props)) {
    return <StockEditField {...props} />;
  } else {
    return <DayEditField {...props} />;
  }
}

function StockEditField(props: StockEditFieldProps) {
  const placedStock = props.rowData.timeRanges.reduce((sum, range) => {
    if (range.name in props.rowData) {
      return sum + props.rowData[range.name].quantity;
    } else {
      return sum;
    }
  }, 0);

  const [value, setValue] = useState((props.value + placedStock).toString());

  const freeStock: number | null = (() => {
    const valueNumber = parseInt(value, 10);

    if (!Number.isNaN(valueNumber)) {
      return valueNumber - placedStock;
    } else {
      return null;
    }
  })();

  const id = `${props.rowData.itemNo}-stock`;

  const error: string | null = (() => {
    const valueNumber = parseInt(value, 10);

    if (Number.isNaN(valueNumber)) {
      return 'This is not a valid number';
    } else if (valueNumber < 0) {
      return 'This cannot be negative';
    } else {
      if (freeStock !== null) {
        if (freeStock < 0) {
          return 'Stock is not enough';
        } else {
          return null;
        }
      } else {
        // Delegate error showing to single field
        return null;
      }
    }
  })();

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const inputValue = e.target.value;
    setValue(inputValue);
  };

  useEffect(() => {
    if (freeStock !== null) {
      props.onChange(freeStock);
    }
    // onChange is handled by MaterialTable and it changes on every render,
    // so we can't include it here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freeStock]);

  return (
    <TextField
      id={id}
      name={id}
      title="Stock"
      value={value}
      onChange={onChange}
      error={!!error}
      helperText={error}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            ({freeStock === null ? '-' : freeStock})
          </InputAdornment>
        )
      }}
    />
  );
}

function DayEditField(props: DayEditFieldProps) {
  const today = new Date(new Date().toISOString().slice(0, 10));
  const [value, setValue] = useState(props.value.quantity.toString());
  const id = `${props.rowData.itemNo}-${props.value.timeRangeId}`;

  const title = props.rowData.timeRanges.find(
    (range) => range.id === props.value.timeRangeId
  )?.name;

  const isDisabled =
    new Date(props.value.deliveryDate).getTime() < today.getTime();

  const error: string | null = (() => {
    const valueNumber = parseInt(value, 10);

    if (Number.isNaN(valueNumber)) {
      return 'This is not a valid number';
    } else if (valueNumber < 0) {
      return 'This cannot be negative';
    } else {
      return null;
    }
  })();

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const inputValue = e.target.value;
    const valueNumber = parseInt(inputValue, 10);

    setValue(inputValue);

    if (!Number.isNaN(valueNumber) && valueNumber >= 0) {
      props.onChange({
        ...props.value,
        quantity: valueNumber
      });
    }
  };

  return (
    <TextField
      id={id}
      name={id}
      value={value}
      title={title}
      onChange={onChange}
      error={!!error}
      helperText={error}
      disabled={isDisabled}
    />
  );
}
