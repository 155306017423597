export interface PackDishState {
  bulkOrderUpdate: {
    orderIds: string[];
    bulkPack: boolean;
  };
  dishes: Array<{
    id: string;
    packed: number;
  }>;
}

export interface Action<T> {
  undo: T | null;
  redo: T | null;
  actionDate: string;
}

export type PackDishAction = Action<PackDishState>;

export type MoveCardAction = Action<{
  id: string;
  inProcessing: number;
  inKitchen: number;
  completed: number;
  orderId: string;
}>;

export interface UndoRedoActions {
  kitchen_actions: {
    move_card: MoveCardAction[];
  };
  dispatcher_actions: {
    pack_dish: PackDishAction[];
  };
  weekly_actions: {
    change_delivery_details: Action<unknown>[];
  };
}

interface SessionStorageMap {
  undo_actions: UndoRedoActions;
  redo_actions: UndoRedoActions;
}

export default {
  set<K extends keyof SessionStorageMap>(
    key: K,
    value: SessionStorageMap[K]
  ): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  },

  get<K extends keyof SessionStorageMap>(key: K): SessionStorageMap[K] | null {
    const stringValue = sessionStorage.getItem(key);

    if (stringValue) {
      try {
        return JSON.parse(stringValue);
      } catch (e) {
        return null;
      }
    } else {
      return null;
    }
  },

  clear() {
    sessionStorage.clear();
  }
};
