import { DispatcherBox } from '../domain';

export function getBoxName(box: DispatcherBox): string {
  return (
    box.size.charAt(0).toUpperCase() +
    box.size.slice(1) +
    ' ' +
    box.type.charAt(0).toUpperCase() +
    box.type.slice(1) +
    ' box'
  );
}
