import React, {
  useState,
  KeyboardEventHandler,
  useRef,
  useEffect
} from 'react';
import {
  TextField,
  withStyles,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Theme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { convertMinsToHrsMins } from '../../../utils/commonUtils';
import * as uiParams from '../../common/uiParams';
import { ClassNameMap, Styles } from '@material-ui/core/styles/withStyles';
import { LaneData } from '../../../api/kitchen';
import { OrderWithLanesData } from '../../../utils/kitchen/sortHelper';

interface InputDishModalDataWithSubmit {
  mode: 'input';
  orders: OrderWithLanesData[];
  maxQuantity: number;
  currentLane: keyof LaneData;
  targetLane: keyof LaneData;
  onSubmit: (quantity: number) => void;
}

export type InputDishModalData = Omit<InputDishModalDataWithSubmit, 'onSubmit'>;

interface InfoDishModalData {
  mode: 'info';
  orders: OrderWithLanesData[];
  currentLane: keyof LaneData;
}

type DishModalDataWithSubmit = InputDishModalDataWithSubmit | InfoDishModalData;
type DishModalData = InputDishModalData | InfoDishModalData;

interface CommonDishModalProps {
  classes: ClassNameMap;
  onCancel: () => void;
}

interface ClosedDishModalProps {
  open: false;
  data: DishModalDataWithSubmit | null;
}

interface ClosedDishModalPropsNoSubmit {
  open: false;
  data: DishModalData | null;
}

interface OpenDishModalProps {
  open: true;
  data: DishModalDataWithSubmit;
}

interface OpenDishModalPropsNoSubmit {
  open: true;
  data: DishModalData;
}

export type DishModalProps =
  | ClosedDishModalPropsNoSubmit
  | OpenDishModalPropsNoSubmit;

type DishModalPropsWithSubmit = ClosedDishModalProps | OpenDishModalProps;
type Props = CommonDishModalProps & DishModalPropsWithSubmit;

function DishModal(props: Props) {
  const dialogTitle = (() => {
    if (props.data) {
      switch (props.data.mode) {
        case 'input':
          return 'Confirm Quantity';
        case 'info': {
          const laneName = (() => {
            switch (props.data.currentLane) {
              case 'inProcessing':
                return 'Processing';
              case 'inKitchen':
                return 'Cooking';
              case 'inCompleted':
                return 'Completed';
            }
          })();

          return `${props.data.orders[0].name}\n${laneName}`;
        }
      }
    } else {
      return '';
    }
  })();

  const dialogContent: JSX.Element | JSX.Element[] | null = (() => {
    if (props.data) {
      switch (props.data.mode) {
        case 'input':
          return (
            <div className={props.classes.contentStyle}>
              <div>{`How many of ${props.data.orders[0].name} do you want to move?`}</div>
              <div className={props.classes.maxStyle}>
                <div>Maximum: </div>
                <div className={props.classes.qtyStyle}>
                  {props.data.maxQuantity}
                </div>
              </div>
            </div>
          );
        case 'info': {
          const data = props.data;

          return data.orders.map((order) => (
            <div key={order.id} className={props.classes.modalWrapper}>
              <div className={props.classes.readyByStyle}>
                {`Ready by: ${convertMinsToHrsMins(order.readyBy)}`}
              </div>
              <div className={props.classes.qtyStyle}>
                {order.lanesData.normalPackaging[data.currentLane] +
                  order.lanesData.reusablePackaging[data.currentLane]}
              </div>
            </div>
          ));
        }
      }
    } else {
      return null;
    }
  })();

  return (
    <Dialog
      className={props.classes.dialogStyle}
      aria-label="simple-dialog"
      open={props.open}
      onClose={() => props.onCancel()}
    >
      <DialogTitle
        id="simple-dialog-title"
        className={props.classes.dialogTitleStyle}
      >
        <div id="title" className={props.classes.titleTextStyle}>
          {dialogTitle}
        </div>
      </DialogTitle>
      <IconButton
        aria-label="close"
        className={props.classes.closeButton}
        onClick={() => props.onCancel()}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers className={props.classes.questionStyle}>
        {dialogContent}
      </DialogContent>
      {props.data && props.data.mode === 'input' ? (
        <DishModalForm
          classes={props.classes}
          maxQuantity={props.data.maxQuantity}
          onSubmit={props.data.onSubmit}
          onCancel={props.onCancel}
        />
      ) : null}
    </Dialog>
  );
}

interface DishModalFormProps {
  classes: ClassNameMap;
  maxQuantity: number;
  onSubmit: (quantity: number) => void;
  onCancel: () => void;
}

function DishModalForm(props: DishModalFormProps) {
  const inputRef = useRef<HTMLDivElement>(null);

  const [quantityInput, setQuantityInput] = useState(
    props.maxQuantity.toString()
  );

  const onKeyUp: KeyboardEventHandler = (event) => {
    switch (event.key) {
      case 'Enter': {
        const quantity = parseInt(quantityInput, 10);

        if (!Number.isNaN(quantity)) {
          props.onSubmit(quantity);
        }

        break;
      }
      case 'Escape':
        props.onCancel();
        break;
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      const input = inputRef.current.getElementsByTagName('input').item(0);
      input && input.focus();
    }
  }, []);

  return (
    <TextField
      type="number"
      label="Quantity"
      onChange={(e) => setQuantityInput(e.target.value)}
      onKeyUp={onKeyUp}
      className={props.classes.textField}
      margin="normal"
      variant="outlined"
      ref={inputRef}
      value={quantityInput}
      InputProps={{
        inputProps: {
          min: 1,
          max: props.maxQuantity
        },
        classes: {
          notchedOutline: props.classes.notchedOutline
        }
      }}
    />
  );
}

const styles: Styles<Theme, {}> = {
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    width: '150px',
    borderRadius: uiParams.RAD_GLOBAL,
    backgroundColor: uiParams.BG_TEXT_FLD,
    margin: uiParams.HORIZONTAL_MARGIN,
    alignSelf: 'center'
  },
  cssLabel: {
    color: '#aaa'
  },
  cssOutlinedInput: {
    $notchedOutline: {
      borderColor: uiParams.WHITE
    }
  },
  notchedOutline: {
    width: '100%',
    borderRadius: uiParams.RAD_GLOBAL
  },
  dialogStyle: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  dialogTitleStyle: {
    backgroundColor: uiParams.PRIMARY,
    color: uiParams.WHITE
  },
  titleTextStyle: {
    whiteSpace: 'pre-wrap',
    marginRight: uiParams.HORIZONTAL_MARGIN
  },
  questionStyle: {
    fontSize: 12,
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    paddingBottom: '60px'
  },
  modalWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: uiParams.HORIZONTAL_MARGIN
  },
  readyByStyle: {
    color: uiParams.PRIMARY,
    marginLeft: '8px',
    marginRight: '8px'
  },
  closeButton: {
    position: 'absolute',
    right: '8px',
    top: '8px',
    color: uiParams.WHITE
  },
  contentStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  maxStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '10px'
  },
  qtyStyle: {
    display: 'flex',
    flexDirection: 'row',
    width: '30px',
    height: '30px',
    backgroundColor: uiParams.PRIMARY,
    color: uiParams.WHITE,
    borderRadius: '10%',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '2px',
    paddingTop: '4px',
    textAlign: 'center',
    justifyContent: 'center'
  }
};

export default withStyles(styles)(DishModal);
