import React, { useEffect, useReducer, useRef } from 'react';
import { Dialog, DialogContent, Typography } from '@material-ui/core';
import { DispatcherBox, DispatcherBranch } from '../domain';
import {
  initialState,
  resetDishScan,
  scanningBranchPopupReducer,
  skipBox,
  updateBox,
  updateBoxName,
  updateBoxPlateId,
  updateDish,
  updateDishBowlCode,
  updateDishLabel
} from './ScanningBranchState';
import { BoxForm } from './BoxForm';
import { DishForm } from './DishForm';

const errorSound = require('../../../assets/error.mp3');

interface Props {
  branch: DispatcherBranch;
  allBowlCodes: string[];
  onBoxInfoChange: (box: DispatcherBox) => Promise<void>;
  onBowlPack: (
    boxUniqueIdentifier: string,
    dishId: string,
    bowlCode: string
  ) => Promise<void>;
  onClose: () => void;
  deliveryDate: Date;
}

export default function ScanningBranchPopup(props: Props) {
  const errorSoundAudio = useRef(new Audio(errorSound));

  const [state, dispatch] = useReducer(
    scanningBranchPopupReducer,
    initialState(props.branch)
  );

  const onBoxScan = (boxName: string) => {
    dispatch(updateBoxName(boxName));
  };

  const onPlateScan = (plateId: string) => {
    dispatch(updateBoxPlateId(plateId));
  };

  const onBoxSkip = () => {
    dispatch(skipBox());
  };

  const onBowlScan = (bowlCode: string) => {
    dispatch(updateDishBowlCode(bowlCode, props.allBowlCodes));
  };

  const onLabelScan = (label: string) => {
    dispatch(updateDishLabel(label));
  };

  const onResetCurrentDish = () => {
    dispatch(resetDishScan());
  };

  useEffect(() => {
    if (state.type === 'ScannedHotBox' || state.type === 'ScannedColdBox') {
      props.onBoxInfoChange(state.box).then(() => {
        dispatch(updateBox());
      });
    } else if (state.type === 'ScannedDish') {
      props
        .onBowlPack(
          state.box.uniqueIdentifier,
          state.dish.id,
          state.dishBowlCode
        )
        .then(() => {
          dispatch(updateDish());
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (
      state.type === 'DuplicateBowlCodeError' ||
      state.type === 'WrongLabel'
    ) {
      errorSoundAudio.current.play();
    }
  }, [state]);

  return (
    <Dialog open onClose={props.onClose}>
      <DialogContent style={{ width: '600px', maxWidth: '90vw' }}>
        <Typography variant="h5">{props.branch.name}</Typography>
        {(() => {
          switch (state.type) {
            case 'Idle':
              return <Typography>All done here!</Typography>;
            case 'BoxName':
            case 'BoxPlateId':
            case 'ScannedColdBox':
            case 'ScannedHotBox':
              return (
                <BoxForm
                  state={state}
                  onBoxScan={onBoxScan}
                  onPlateScan={onPlateScan}
                  onBoxSkip={onBoxSkip}
                />
              );
            case 'EmptyDish':
            case 'DishBowlCode':
            case 'DishLabel':
            case 'ScannedDish':
            case 'DuplicateBowlCodeError':
            case 'WrongLabel':
              return (
                <DishForm
                  state={state}
                  onBoxSkip={onBoxSkip}
                  onBowlScan={onBowlScan}
                  onLabelScan={onLabelScan}
                  onResetCurrentDish={onResetCurrentDish}
                  deliveryDate={props.deliveryDate}
                />
              );
          }
        })()}
      </DialogContent>
    </Dialog>
  );
}
