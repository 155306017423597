import React from 'react';
import { Redirect } from 'react-router-dom';
import { getUserRole } from '../../utils/jwtUtil';

const PermissionDenied = () => <p> Access Denied </p>;
const withAuth = (Component, routeRole) => (props) => {
  const {
    location: { pathname }
  } = props;
  if (!localStorage.length) {
    return <Redirect to={{ pathname: '/auth', from: pathname }} />;
  }
  const userRole = getUserRole();

  if (routeRole && routeRole.indexOf(userRole) !== -1) {
    return <Component {...props} />;
  }

  return <PermissionDenied />;
};

export default withAuth;
