import { PackagingV2BranchData } from '../api/packaging';

export function search(
  word: string,
  content: PackagingV2BranchData[]
): PackagingV2BranchData[] {
  return content.filter((companyData) =>
    Object.values(companyData.dishes).some((dishes) =>
      dishes.some(
        (dish) => dish.name.toLowerCase().indexOf(word.toLowerCase()) >= 0
      )
    )
  );
}
