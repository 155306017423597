import React from 'react';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { CircularProgress } from '@material-ui/core';
import { Check, Close, WarningOutlined } from '@material-ui/icons';
import { GENERIC_STICKER_THUMBNAIL } from '../../config/constants';
import { getStickerUrl } from '../../utils/commonUtils';
import { DispatchOperation, DispatcherDish } from './domain';

interface Props {
  classes: ClassNameMap;
  dish: DispatcherDish;
  index: number;
  isUpdating: boolean;
  onPackButtonClick: (operation: DispatchOperation) => void;
}

export function DishListItem(props: Props) {
  const isAddon = props.dish.label === 'addons';

  const totalQuantity = props.dish.users.reduce(
    (sum, user) => sum + user.orderedQuantity,
    0
  );

  const packedQuantity = props.dish.users.reduce(
    (sum, user) => sum + user.packedQuantity,
    0
  );

  const isPacked = totalQuantity === packedQuantity;

  const scanningStatusIcon = (() => {
    if (isAddon || props.dish.bowlCodes.length === totalQuantity) {
      return <Check htmlColor="#4caf50" />;
    } else if (props.dish.bowlCodes.length === 0) {
      return <Close htmlColor="#f44336" />;
    } else {
      return <WarningOutlined htmlColor="#ffc107" />;
    }
  })();

  return (
    <div
      className={[
        props.classes.rowCommon,
        isPacked ? props.classes.greenRow : props.classes.redRow
      ].join(' ')}
      role="button"
      tabIndex={props.index}
    >
      {props.isUpdating ? (
        <div className={props.classes.overlayLoaderContainer}>
          <CircularProgress />
        </div>
      ) : null}
      <div className={props.classes.dishStatusIcon}>{scanningStatusIcon}</div>
      <div className={props.classes.dishName}>
        {isAddon ? (
          <img
            className={props.classes.lidImage}
            src={GENERIC_STICKER_THUMBNAIL}
            alt={props.dish.label}
          />
        ) : (
          <div className={props.classes.stickerBadge}>
            <img
              src={getStickerUrl(isAddon ? 'AD' : 'DL', props.dish.label)}
              className={props.classes.recipeSticker}
              alt="Bella&Bona"
              onError={({ currentTarget }) => {
                // prevents looping
                currentTarget.onerror = null;
                currentTarget.src = GENERIC_STICKER_THUMBNAIL;
              }}
            />
          </div>
        )}
        {props.dish.name}
      </div>
      <div
        className={[props.classes.dishQuantity, props.classes.hoverEffect].join(
          ' '
        )}
        onClick={() => props.onPackButtonClick('pack')}
      >
        {totalQuantity - packedQuantity}
      </div>
      <div
        className={[
          props.classes.dishQuantity,
          props.classes.dishQuantityPacked,
          props.classes.hoverEffect
        ].join(' ')}
        onClick={() => props.onPackButtonClick('unpack')}
      >
        {packedQuantity}
      </div>
    </div>
  );
}
