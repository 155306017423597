import React, { useEffect, useRef } from 'react';
import LabelsPrinter from './LabelsPrintComponent';
import SinglePrintComponent from './SinglePrintComponent';
import './css/labelPrint.css';
import { DispatcherBranch } from './domain';
import { useReactToPrint } from 'react-to-print';

const pageStyle = `
  @page {
	  size: 700px 920px;
	  margin: 0;
	  padding: 0;
  }
`;

export type PrintComponentVisibility = 'hidden' | 'visible';

interface ComponentToPrintCommonProps {
  printStyle: string;
  deliveryDate: Date;
  onClose: () => void;
}

interface ComponentToPrintMultipleOrdersProps
  extends ComponentToPrintCommonProps {
  type: 'multipleOrders';
  branches: DispatcherBranch[];
}

interface ComponentToPrintSingleOrderProps extends ComponentToPrintCommonProps {
  type: 'singleOrder';
  branch: DispatcherBranch;
}

interface ComponentToPrintLabelsDataProps extends ComponentToPrintCommonProps {
  type: 'labelsData';
  branches: DispatcherBranch[];
}

type Props =
  | ComponentToPrintMultipleOrdersProps
  | ComponentToPrintSingleOrderProps
  | ComponentToPrintLabelsDataProps;

export default function ComponentToPrint(props: Props) {
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle,
    onAfterPrint: props.onClose
  });

  useEffect(() => {
    handlePrint();
  }, [handlePrint]);

  switch (props.type) {
    // Multiple user lists
    case 'multipleOrders': {
      return (
        <div ref={componentRef}>
          {props.branches.map((branch) => (
            <SinglePrintComponent
              key={branch.id}
              branch={branch}
              deliveryDate={props.deliveryDate}
            />
          ))}
        </div>
      );
    }
    // Single user list
    case 'singleOrder': {
      return (
        <SinglePrintComponent
          ref={componentRef}
          branch={props.branch}
          deliveryDate={props.deliveryDate}
        />
      );
    }
    // Label Print - Labels
    case 'labelsData': {
      return (
        <div className="label_print_grid" ref={componentRef}>
          {props.branches.map((branch) => (
            <LabelsPrinter
              key={branch.id}
              deliveryDate={props.deliveryDate}
              branch={branch}
            />
          ))}
        </div>
      );
    }
  }
}
