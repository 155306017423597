import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useEffect, useState } from 'react';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';

import {
  getAllDrivers,
  updateDeliveryById,
  updateOrderByDeliveryId
} from '../../../api';
import {
  DRIVER_FETCH_FAILED,
  DRIVER_SAVE_FAILED,
  DRIVER_SAVE_SUCCESS,
  ORDER_STATUS_SUCCESS,
  ORDER_STATUS_FAILED,
  WEEKLY_ACTIONS_KEY,
  DELIVERY_DETAILS,
  UNDO_ACTIONS_KEY,
  REDO_ACTIONS_KEY
} from '../../../config/constants';
import CustomSnackbar from '../../common/CustomSnackbar';
import { deliveryGroupStyles } from './DeliveryGroupStyle';
import {
  setActionData,
  clearActions
} from '../../../utils/common/undoActionHelper';
import ls from '../../../utils/common/sessionStorageHelper';

export const DeliveryGroup = ({
  deliveries,
  commonClasses,
  driverUpdated,
  enableUndo,
  enableRedo
}) => {
  const compStyles = deliveryGroupStyles();
  const [showSnack, setShowSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [drivers, setDrivers] = useState([]);

  useEffect(() => {
    setShowSnack(false);
    getAllDrivers()
      .then((response) => {
        setDrivers(response.data.driverData);
      })
      .catch(() => {
        setSnackMessage(DRIVER_FETCH_FAILED);
        setShowSnack(true);
      });
  }, []);

  const handleChange = async (event, deliveryItemId, initialValue) => {
    setShowSnack(false);
    const previousState = {
      itemId: deliveryItemId,
      payload: { driver: initialValue }
    };
    const changeValue = event.target.value === '' ? null : event.target.value;
    const updatedState = {
      itemId: deliveryItemId,
      payload: { driver: changeValue }
    };
    const actionType = 'delivery-driver-update';
    const reqBody = {
      deliveryData: {
        previousState,
        updatedState: updatedState.payload
      },
      actionType
    };
    await updateDeliveryById(deliveryItemId, reqBody)
      .then(async () => {
        setActionData(
          previousState,
          updatedState,
          ls,
          UNDO_ACTIONS_KEY,
          WEEKLY_ACTIONS_KEY,
          DELIVERY_DETAILS
        );
        clearActions(
          REDO_ACTIONS_KEY,
          WEEKLY_ACTIONS_KEY,
          DELIVERY_DETAILS,
          ls
        );
        enableRedo(false);
        enableUndo(true);
        driverUpdated(true);
        setSnackMessage(DRIVER_SAVE_SUCCESS);
      })
      .catch(() => {
        setSnackMessage(DRIVER_SAVE_FAILED);
      })
      .finally(() => {
        setShowSnack(true);
      });
  };

  const onDoneAll = (deliveryItemId, initialStatus, status) => {
    setShowSnack(false);
    const updatedState = { itemId: deliveryItemId, payload: { status } };
    const body = {
      updatedState: updatedState.payload,
      previousState: {
        itemId: deliveryItemId,
        payload: { status: initialStatus }
      },
      actionType: 'delivery-status-change'
    };
    updateOrderByDeliveryId(deliveryItemId, body)
      .then(() => {
        setActionData(
          body.previousState,
          updatedState,
          ls,
          UNDO_ACTIONS_KEY,
          WEEKLY_ACTIONS_KEY,
          DELIVERY_DETAILS
        );
        clearActions(
          REDO_ACTIONS_KEY,
          WEEKLY_ACTIONS_KEY,
          DELIVERY_DETAILS,
          ls
        );
        enableRedo(false);
        enableUndo(true);
        driverUpdated(true);
        setSnackMessage(ORDER_STATUS_SUCCESS);
      })
      .catch(() => {
        setSnackMessage(ORDER_STATUS_FAILED);
      })
      .finally(() => {
        setShowSnack(true);
      });
  };

  const handleCloseSnack = () => {
    setShowSnack(false);
  };

  return (
    <>
      <CustomSnackbar
        visible={showSnack}
        message={snackMessage}
        handleClose={handleCloseSnack}
      />
      {deliveries &&
        drivers &&
        Array.from(deliveries.keys()).map((key) => (
          <div key={key} className={compStyles.weeklyCardItem}>
            <div className={compStyles.weeklyCardItemDay}>
              <div className={compStyles.cardItemDayName}>
                {key.split('-')[0]}
              </div>
              <div className={compStyles.cardItemDate}>
                {key.split('-').length > 0 ? key.split('-')[1] : 'invalid date'}
              </div>
            </div>
            <div className={compStyles.weeklyCardItemDataContainer}>
              {deliveries.get(key).map((deliveryItem) => (
                <div
                  key={deliveryItem.id}
                  className={compStyles.weeklyCardItemData}
                >
                  <div className={commonClasses.dataOrderType}>
                    {deliveryItem.type}
                  </div>
                  <div className={commonClasses.dataCompanyName}>
                    {deliveryItem.company}
                  </div>
                  <div className={commonClasses.dataReadyFor}>
                    {deliveryItem.readyTime}
                  </div>
                  <div className={commonClasses.dataDeliveryTime}>
                    {deliveryItem.deliveryTime}
                  </div>
                  <div className={commonClasses.dataDriver}>
                    <FormControl className={compStyles.formDriver}>
                      <Select
                        id={`${key}_${deliveryItem.driverId}`}
                        displayEmpty
                        value={
                          !deliveryItem.driverId ? '' : deliveryItem.driverId
                        }
                        onChange={(e) =>
                          handleChange(
                            e,
                            deliveryItem.id,
                            deliveryItem.driverId
                          )
                        }
                        inputProps={{
                          'aria-label': 'Without label'
                        }}
                      >
                        <MenuItem
                          key=""
                          value=""
                          className={compStyles.emptyDriver}
                        />
                        {drivers.map((drv) => (
                          <MenuItem key={drv.id} value={drv.id}>
                            {drv.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className={commonClasses.dataAddress}>
                    {deliveryItem.companyAddress}
                  </div>
                  <div className={commonClasses.orderStatus}>
                    {deliveryItem.status}
                  </div>
                  {deliveryItem.status !== 'delivered' ? (
                    <div className={commonClasses.doneAllIcon}>
                      <DoneAllIcon
                        onClick={() =>
                          onDoneAll(
                            deliveryItem.id,
                            deliveryItem.status,
                            'delivered'
                          )
                        }
                      />
                    </div>
                  ) : (
                    <div className={commonClasses.doneAllIcon}>
                      <SettingsBackupRestoreIcon
                        onClick={() =>
                          onDoneAll(
                            deliveryItem.id,
                            deliveryItem.status,
                            'out for delivery'
                          )
                        }
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
    </>
  );
};
