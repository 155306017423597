import { Equalizer } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import React, { useState } from 'react';

import './orderMetricBoxes.css';

export const OrderMetricBoxes = ({ numbersData }) => {
  const [showNumbers, setShowNumbers] = useState(false);
  return (
    <div className="numbers">
      <div
        className="viewNumbers"
        onClick={() => setShowNumbers(!showNumbers)}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
      >
        <Equalizer color="primary" className="graphIcon" />
        <span className="boxTitle">View today&apos;s numbers</span>
        {showNumbers ? (
          <KeyboardArrowUpIcon color="primary" />
        ) : (
          <KeyboardArrowDownIcon color="primary" />
        )}
      </div>
      {showNumbers && (
        <div
          className={`numberBoxes numberBoxes${
            Object.keys(numbersData)?.length
          }`}
        >
          {Object.keys(numbersData).map((dataKey) => (
            <div key={dataKey} className="numberBox">
              <p className="numberTitle">{dataKey}</p>
              <p className="numberValue">{numbersData[dataKey]}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default OrderMetricBoxes;
