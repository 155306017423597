/* eslint-disable import/no-cycle */
import { authHeader } from './auth';
import { API_ENDPOINT } from '../config/constants';
import { handleResponse } from './index';

export const getAllDrivers = async () => {
  const options = {
    method: 'GET',
    ...(await authHeader())
  };

  return fetch(`${API_ENDPOINT}/api/v1/kitchen/orders/drivers`, options).then(
    handleResponse
  );
};
