import { UNDO_ACTIONS_KEY, UR_ACTIONS_STRUCT } from '../../config/constants';
import sessionStorageHelper, {
  Action,
  UndoRedoActions
} from './sessionStorageHelper';

export function setActionData<
  T,
  K extends keyof UndoRedoActions,
  A extends keyof UndoRedoActions[K]
>(
  previousState: T,
  currentState: T | null,
  ls: typeof sessionStorageHelper,
  actionType: 'undo_actions' | 'redo_actions',
  actionPage: K,
  actionCategory: keyof UndoRedoActions[K],
  actionDate: string
): void {
  const actions = (() => {
    const storedActions = ls.get(actionType);

    if (storedActions) {
      return storedActions;
    } else {
      ls.set(actionType, UR_ACTIONS_STRUCT);
      return UR_ACTIONS_STRUCT;
    }
  })();

  const history: UndoRedoActions[K][A][] = actions[actionPage][actionCategory];

  if (history.length > 30) {
    history.shift();
  }

  if (actionType === UNDO_ACTIONS_KEY) {
    history.push({
      undo: previousState,
      redo: currentState,
      actionDate
    } as UndoRedoActions[K][A]);
  } else {
    history.push({
      undo: null,
      redo: previousState,
      actionDate
    } as UndoRedoActions[K][A]);
  }

  ls.set(actionType, actions);
}

export function getActionData<
  K extends keyof UndoRedoActions,
  A extends keyof UndoRedoActions[K]
>(
  actionType: 'undo_actions' | 'redo_actions',
  actionPage: K,
  actionCategory: A,
  ls: typeof sessionStorageHelper
): {
  data: UndoRedoActions[K][A];
  actionsLength: number;
  actionDate: string;
} | null {
  const actions = ls.get(actionType);

  if (!actions) {
    return null;
  }

  const history: UndoRedoActions[K][A][] = actions[actionPage][actionCategory];
  const todoAction: Action<UndoRedoActions[K][A]> | undefined = history.pop();

  if (actionType === 'undo_actions' && todoAction) {
    setActionData(
      todoAction.redo,
      null,
      ls,
      'redo_actions',
      actionPage,
      actionCategory,
      todoAction.actionDate
    );
  }

  const actionsLength = history.length;

  ls.set(actionType, actions);

  if (todoAction) {
    switch (actionType) {
      case 'undo_actions':
        if (todoAction.undo) {
          return {
            data: todoAction.undo,
            actionsLength,
            actionDate: todoAction.actionDate
          };
        } else {
          return null;
        }
      case 'redo_actions':
        if (todoAction.redo) {
          return {
            data: todoAction.redo,
            actionsLength,
            actionDate: todoAction.actionDate
          };
        } else {
          return null;
        }
    }
  } else {
    return null;
  }
}

export function clearActions<
  K extends keyof UndoRedoActions,
  A extends keyof UndoRedoActions[K]
>(
  actionType: 'undo_actions' | 'redo_actions',
  actionPage: K,
  actionCategory: A,
  ls: typeof sessionStorageHelper
): void {
  const actions = ls.get(actionType);

  if (actions) {
    const history: UndoRedoActions[K][A][] =
      actions[actionPage][actionCategory];

    history.splice(0, history.length);
    ls.set(actionType, actions);
  }
}
