import { getUserRole } from './jwtUtil';
import {
  GENERIC_STICKER,
  GENERIC_STICKER_THUMBNAIL,
  STICKER_IMAGE_URL,
  STICKER_THUMBNAIL_IMAGE_URL
} from '../config/constants';
import { getLocalStorage, setLocalStorage } from './storageUtils';
import { layoutRoutes } from '../routesConfig';
import { PackagingV2BranchData } from '../api/packaging';

export function convertMinsToHrsMins(minutes: number | string): string {
  const numMinutes: number = (() => {
    if (typeof minutes === 'string') {
      const n = parseInt(minutes, 10);

      if (!Number.isNaN(n) && n <= 1439 && n >= 0) {
        return n;
      } else {
        return 0;
      }
    } else {
      return minutes;
    }
  })();

  const hour = Math.floor(numMinutes / 60);
  const minute = Math.floor(numMinutes % 60);
  const hourString = hour < 10 ? `0${hour}` : hour.toString();
  const minuteString = minute < 10 ? `0${minute}` : minute;

  return `${hourString}:${minuteString}`;
}

export function getRedirectPath(routes: typeof layoutRoutes): string {
  const userRole = getUserRole();

  const routesWithRole = routes.filter(({ meta }) => {
    if (meta && meta.role) {
      return meta.role.indexOf(userRole) !== -1;
    }

    return false;
  });

  return routesWithRole && routesWithRole.length !== 0
    ? routesWithRole[0].path
    : '/kitchen';
}

export function countBoxes(dishes: PackagingV2BranchData['dishes']): string[] {
  const typeToLabelMap: Record<string, { label: string; qty: number }> = {
    hb: { label: 'BigBox (Hot)', qty: 0 },
    hm: { label: 'MediumBox (Hot)', qty: 0 },
    hs: { label: 'SmallBox (Hot)', qty: 0 },
    cb: { label: 'BigBox (Cold)', qty: 0 },
    cm: { label: 'MediumBox (Cold)', qty: 0 },
    cs: { label: 'SmallBox (Cold)', qty: 0 },
    ab: { label: 'Big Addon Box', qty: 0 },
    am: { label: 'Medium Addon Box', qty: 0 },
    as: { label: 'Small Addon Box', qty: 0 }
  };

  const boxIds = Object.keys(dishes);

  for (const boxId of boxIds) {
    const boxType = boxId.trim().slice(0, 2);

    if (boxType in typeToLabelMap) {
      typeToLabelMap[boxType].qty++;
    }
  }

  return Object.values(typeToLabelMap)
    .filter((_) => _.qty > 0)
    .map((entry) => `${entry.label}: ${entry.qty}`);
}

// TODO: in case of add-on, `categoryNumber` (which is actually "label" or "displayNum") is set to
// 'addons', so `categoryCode` is useless here
export function getStickerUrl(
  categoryCode: string,
  categoryNumber: string,
  isThumbnail = true
): string {
  if (categoryCode === 'AD') {
    if (isThumbnail) {
      return GENERIC_STICKER_THUMBNAIL;
    }

    return GENERIC_STICKER;
  }

  const stickerUrl = isThumbnail
    ? STICKER_THUMBNAIL_IMAGE_URL
    : STICKER_IMAGE_URL;

  return stickerUrl.replace('{{NUM}}', categoryNumber);
}

export function capitalizeWord(word: string): string {
  if (word.length === 0) {
    return '';
  } else {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
}

export function convertTimestampToTime(
  time: string,
  defaultValue: number | string | null
): string {
  if (!time) {
    return defaultValue ? convertMinsToHrsMins(defaultValue) : '--';
  }

  // 2020-11-26T10:37:21+01:00
  // 10:37
  try {
    const temp = time.split('T');
    return temp[1].substring(0, 5);
  } catch (e) {
    return defaultValue ? convertMinsToHrsMins(defaultValue) : '--';
  }
}

// eslint-disable-next-line max-len
export function convertUnixTimeToMinutes(milliseconds: number) {
  return (
    new Date(+milliseconds + 3600000 * -5.5).getHours() * 60 +
    new Date(+milliseconds + 3600000 * -5.5).getMinutes()
  );
}

export function getInitialSelectedDate(): Date {
  let queryDate = getLocalStorage('USER_SELECTED_DATE');

  if (!queryDate) {
    queryDate = new Date();
    setLocalStorage('USER_SELECTED_DATE', queryDate);
  }

  return queryDate;
}

// Function to remove 0 padding from the left of the string
export function removeZeroPadding(str: string): string {
  return str.replace(/^0+/, '');
}
