import React, { FormEventHandler, useEffect, useRef, useState } from 'react';
import { BoxScanningBranchState } from './ScanningBranchState';
import {
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  Grid,
  Input,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import {
  CheckBox,
  CheckBoxOutlineBlank,
  ExpandLess,
  ExpandMore,
  IndeterminateCheckBox
} from '@material-ui/icons';
import { getBoxName } from './getBoxName';

interface BoxFormProps {
  state: BoxScanningBranchState;
  onBoxScan: (boxName: string) => void;
  onPlateScan: (plateId: string) => void;
  onBoxSkip: () => void;
}

export function BoxForm(props: BoxFormProps) {
  const [currentScannedValue, setCurrentScannedValue] = useState('');
  const [shouldShowPrevious, setShouldShowPrevious] = useState(false);
  const [shouldShowNext, setShouldShowNext] = useState(false);
  const inputContainerRef = useRef<HTMLDivElement>(null);

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    switch (props.state.type) {
      case 'BoxName':
        props.onBoxScan(currentScannedValue);
        break;
      case 'BoxPlateId':
        props.onPlateScan(currentScannedValue);
        break;
      case 'ScannedColdBox':
      case 'ScannedHotBox':
        break;
    }

    setCurrentScannedValue('');
  };

  const inputTitle = (() => {
    switch (props.state.type) {
      case 'BoxName':
        return 'Box scan';
      case 'BoxPlateId':
        return 'Plate scan';
      case 'ScannedColdBox':
      case 'ScannedHotBox':
        return null;
    }
  })();

  /*
  When state is "ScannedColdBox" or "ScannedHotBox", we finished scanning box info and we are waiting
  for back-end sync
  */
  const isLoading =
    props.state.type === 'ScannedColdBox' ||
    props.state.type === 'ScannedHotBox';

  useEffect(() => {
    if (!isLoading && inputContainerRef.current) {
      const input = inputContainerRef.current.querySelector(
        '#scannedValue'
      ) as HTMLInputElement | null;

      input?.focus();
    }
  }, [isLoading]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '1.5em',
          marginBottom: '1.5em'
        }}
      >
        <Typography
          variant="button"
          style={{
            fontWeight: 'bold',
            color: (() => {
              switch (props.state.box.type) {
                case 'cold':
                  return '#3f51b5';
                case 'hot':
                  return '#f44336';
              }
            })()
          }}
        >
          {props.state.box.type.toUpperCase()},{' '}
          {props.state.box.dishes
            .flatMap((dish) => dish.users)
            .reduce((sum, user) => sum + user.orderedQuantity, 0)}{' '}
          dishes
        </Typography>
        <Button variant="contained" onClick={props.onBoxSkip}>
          Skip
        </Button>
      </div>
      <Typography variant="h5">{getBoxName(props.state.box)}</Typography>
      <Typography variant="h6" color="primary">
        {inputTitle}
      </Typography>
      <Grid container spacing={2}>
        <Grid item>
          <form style={{ marginTop: '1em' }} onSubmit={onSubmit}>
            <FormControl variant="outlined" focused>
              <Input
                id="scannedValue"
                name="scannedValue"
                value={currentScannedValue}
                onChange={(e) => setCurrentScannedValue(e.currentTarget.value)}
                autoFocus
                ref={inputContainerRef}
                disabled={isLoading}
              />
            </FormControl>
          </form>
        </Grid>
        <Grid item hidden={!isLoading}>
          <CircularProgress />
        </Grid>
      </Grid>
      <List>
        <ListItem>
          <ListItemIcon>
            <IndeterminateCheckBox />
          </ListItemIcon>
          <ListItemText primary={getBoxName(props.state.box)} />
        </ListItem>
        <ListItem button>
          <ListItemText
            primary={`Next (${props.state.boxes.getNext().length})`}
            onClick={() => setShouldShowNext((_) => !_)}
          />
          {shouldShowNext ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={shouldShowNext}>
          {props.state.boxes.getNext().map((box) => (
            <ListItem key={box.uniqueIdentifier}>
              <ListItemIcon>
                <CheckBoxOutlineBlank />
              </ListItemIcon>
              <ListItemText primary={getBoxName(box)} />
            </ListItem>
          ))}
        </Collapse>
        <ListItem button>
          <ListItemText
            primary={`Previous (${props.state.boxes.getPrevious().length})`}
            onClick={() => setShouldShowPrevious((_) => !_)}
          />
          {shouldShowPrevious ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={shouldShowPrevious}>
          {props.state.boxes.getPrevious().map((box) => (
            <ListItem key={box.uniqueIdentifier}>
              <ListItemIcon>
                <CheckBox />
              </ListItemIcon>
              <ListItemText primary={getBoxName(box)} />
            </ListItem>
          ))}
        </Collapse>
      </List>
    </div>
  );
}
