import React from 'react';
import {
  withStyles,
  IconButton,
  Dialog,
  DialogContent,
  Theme,
  CircularProgress
} from '@material-ui/core';
import * as uiParams from '../../common/uiParams';
import { GENERIC_STICKER, DISH_TYPE_URL } from '../../../config/constants';
import { getStickerUrl } from '../../../utils/commonUtils';
import { ClassNameMap, Styles } from '@material-ui/core/styles/withStyles';
import { OrderWithLanesData } from '../../../utils/kitchen/sortHelper';
import { NetworkResponse, useQuery } from '../../../hooks/network/Network';
import {
  GetCommentsResponse,
  GetRatingsResponse,
  getComments,
  getRatings
} from '../api';

interface Props {
  classes: ClassNameMap;
  onClose: () => void;
  open: boolean;
  order: OrderWithLanesData;
}

export const localizeNumberToGerman = (
  number: number,
  minimumFractionDigits = 2
): string =>
  number.toLocaleString('de-DE', {
    minimumFractionDigits,
    maximumFractionDigits: 2
  });

function RecipeModal(props: Props) {
  const { response: ratingsResponse } = useQuery<GetRatingsResponse[]>(
    getRatings({
      recipeVariantsId: props.order.productId,
      deliveryDate: props.order.deliveryDate
    })
  );

  const { response: commentsResponse } = useQuery<GetCommentsResponse[]>(
    getComments({
      recipeVariantsId: props.order.productId,
      deliveryDate: props.order.deliveryDate
    })
  );

  const renderRatingsTable = (
    responseData: Array<{
      average_rating: number;
      average_taste: number;
      average_satisfaction: number;
    }>
  ) => {
    const data = responseData[0];
    if (
      data.average_rating === null &&
      data.average_taste === null &&
      data.average_satisfaction === null
    )
      return null;
    return (
      <table className={props.classes.ratingsTable}>
        <thead>
          <tr>
            <th>Average Rating</th>
            <th>Average Taste</th>
            <th>Average Satisfaction</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {data?.average_rating
                ? localizeNumberToGerman(data?.average_rating)
                : 'NA'}
            </td>
            <td>
              {data?.average_taste
                ? localizeNumberToGerman(data?.average_taste)
                : 'NA'}
            </td>
            <td>
              {data?.average_satisfaction
                ? localizeNumberToGerman(data?.average_satisfaction)
                : 'NA'}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderRating = (
    ratingsResponse: NetworkResponse<GetRatingsResponse[]>
  ) => {
    switch (ratingsResponse.type) {
      case 'successful':
        return renderRatingsTable(ratingsResponse.data);
      case 'loading':
        return (
          <div className={props.classes.progressBar}>
            <CircularProgress />
          </div>
        );
      default:
        return (
          <p className={props.classes.failureMessage}>
            Failed to fetch ratings
          </p>
        );
    }
  };

  const renderComments = (
    commentsResponse: NetworkResponse<GetCommentsResponse[]>
  ) => {
    switch (commentsResponse.type) {
      case 'successful':
        if (commentsResponse.data.length === 0) {
          return <p>No comments available</p>;
        }
        return (
          <>
            <h3 className={props.classes.commentsHeader}>Comments</h3>
            <div className={props.classes.commentsScrollable}>
              {commentsResponse.data.map((comment) => (
                <div
                  key={comment.reviewId}
                  className={props.classes.commentItem}
                >
                  <p>{comment.comment}</p>
                </div>
              ))}
            </div>
          </>
        );
      case 'loading':
        return (
          <div className={props.classes.progressBar}>
            <CircularProgress />
          </div>
        );
      default:
        return (
          <p className={props.classes.failureMessage}>
            Failed to fetch comments
          </p>
        );
    }
  };
  return (
    <Dialog
      className={props.classes.dialogStyle}
      aria-label="simple-dialog"
      open={props.open}
      onClose={() => props.onClose()}
      maxWidth="md"
    >
      <DialogContent className={props.classes.dialogContent}>
        <div className={props.classes.contentWrapper}>
          <div className={props.classes.mainContent}>
            <div className={props.classes.leftSideContent}>
              <img
                id="recipeDishImage"
                src={props.order.webUrl}
                className={props.classes.recipeDishImage}
                alt={props.order.webUrl}
              />
              <span className={props.classes.leftSideBottomContent}>
                {props.order.num ? (
                  <img
                    src={getStickerUrl(props.order.cat, props.order.num, false)}
                    className={props.classes.recipeSticker}
                    alt={props.order.num}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = GENERIC_STICKER;
                    }}
                  />
                ) : null}
                <img
                  src={`${DISH_TYPE_URL + props.order.type}.jpg`}
                  className={props.classes.recipeTempType}
                  alt={props.order.type}
                />
              </span>
            </div>
            <div className={props.classes.rightSideContent}>
              <div className={props.classes.recipeHeader}>
                <p className={props.classes.recipeText} id="title">
                  Recipe
                </p>
                <p className={props.classes.recipeSubHeader}>
                  {props.order.name}
                </p>
              </div>
              {props.order.recipe ? (
                <div className={props.classes.recipeComposition}>
                  <ul className={props.classes.recipe}>
                    {props.order.recipe.map((step, index) => (
                      <li key={index}>{step}</li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>

          <div>{renderRating(ratingsResponse)}</div>
          <div>{renderComments(commentsResponse)}</div>
        </div>
        <div className={props.classes.closeIcon}>
          <IconButton
            aria-label="close"
            className={props.classes.closeButton}
            onClick={() => props.onClose()}
          >
            <svg
              className={props.classes.recipeClose}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.421068 21.425L9.86057 12L0.421068 2.57498C0.147831 2.29251 -0.00336093 1.91419 5.67037e-05 1.52149C0.00347433 1.1288 0.161228 0.75316 0.43934 0.475474C0.717452 0.197789 1.09367 0.0402767 1.48697 0.0368643C1.88026 0.0334519 2.25916 0.184412 2.54207 0.45723L11.9816 9.88225L21.4211 0.45723C21.5594 0.314185 21.725 0.200086 21.908 0.121594C22.091 0.0431006 22.2878 0.00178462 22.487 5.65487e-05C22.6861 -0.00167152 22.8837 0.0362225 23.068 0.111528C23.2523 0.186834 23.4198 0.298043 23.5607 0.438666C23.7015 0.579289 23.8129 0.74651 23.8883 0.930572C23.9637 1.11463 24.0017 1.31185 23.9999 1.51071C23.9982 1.70958 23.9568 1.90611 23.8782 2.08883C23.7996 2.27156 23.6853 2.43682 23.5421 2.57498L14.1026 12L23.5421 21.425C23.6853 21.5632 23.7996 21.7284 23.8782 21.9112C23.9568 22.0939 23.9982 22.2904 23.9999 22.4893C24.0017 22.6882 23.9637 22.8854 23.8883 23.0694C23.8129 23.2535 23.7015 23.4207 23.5607 23.5613C23.4198 23.702 23.2523 23.8132 23.068 23.8885C22.8837 23.9638 22.6861 24.0017 22.487 23.9999C22.2878 23.9982 22.091 23.9569 21.908 23.8784C21.725 23.7999 21.5594 23.6858 21.4211 23.5428L11.9816 14.1177L2.54207 23.5428C2.25916 23.8156 1.88026 23.9665 1.48697 23.9631C1.09367 23.9597 0.717452 23.8022 0.43934 23.5245C0.161228 23.2468 0.00347433 22.8712 5.67037e-05 22.4785C-0.00336093 22.0858 0.147831 21.7075 0.421068 21.425Z"
                fill="#7C7F89"
              />
            </svg>
          </IconButton>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const styles: Styles<Theme, {}> = {
  dialogStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex !important',
    position: 'fixed',
    top: '50%',
    left: '0',
    overflowY: 'auto',
    borderRadius: '28px',
    '& .MuiDialog-paper': {
      maxHeight: '90vh',
      width: '850px',
      maxWidth: '70vw'
    }
  },
  progressBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100px',
    zIndex: 10
  },
  dialogTitleStyle: {
    backgroundColor: uiParams.PRIMARY,
    color: uiParams.WHITE
  },
  titleTextStyle: {
    whiteSpace: 'pre-wrap',
    marginRight: uiParams.HORIZONTAL_MARGIN
  },
  closeButton: {
    right: '20px',
    top: '16px',
    color: uiParams.WHITE
  },
  imgStyle: {
    width: '600px',
    height: '400px',
    objectFit: 'cover'
  },
  failureMessage: {
    color: uiParams.DARK_RED
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    height: '100%',
    width: '100%',
    overflowY: 'auto'
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  },
  mainContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '24px',
    alignItems: 'flex-start'
  },
  recipeHeader: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    background: '#35671C',
    borderRadius: '30px',
    paddingLeft: '20px'
  },
  recipeSubHeader: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    color: '#FFFFFF',
    marginTop: '0px'
  },
  recipeDishImage: {
    width: '344px',
    height: '344px',
    background: 'url(Hatcho Miso Tofu Bowl.jpg), #8BA5B7',
    borderRadius: '0px'
  },
  recipeClose: {
    position: 'absolute'
  },
  recipeText: {
    width: '83px',
    height: '26px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    color: '#EDF0F6'
  },
  recipeDescription: {
    width: '431px',
    height: '46px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '145.5%',
    color: '#191D2C'
  },
  recipeCompositionHeader: {
    width: '195px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '145.5%',
    color: '#79CF4F'
  },
  recipeToppingHeader: {
    width: '195px',
    height: '32px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '145.5%',
    color: '#79CF4F'
  },
  recipePackageHeader: {
    width: '195px',
    height: '32px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '145.5%',
    color: '#79CF4F'
  },
  recipeSticker: {
    width: '140px',
    height: '140px',
    borderRadius: '0px'
  },
  recipeTempType: {
    width: '140px',
    height: '140px',
    borderRadius: '0px'
  },
  recipeComposition: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '30px',
    color: '#191D2C'
  },
  recipe: {
    whiteSpace: 'pre-wrap'
  },
  recipeTopping: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '30px',
    color: '#191D2C'
  },
  recipePackaging: {
    width: '345px',
    height: '90px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '30px',
    color: '#191D2C'
  },
  leftSideContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  leftSideBottomContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0'
  },
  rightSideContent: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '20px'
  },
  closeIcon: {
    position: 'absolute',
    right: '0px',
    top: '0px'
  },
  ratingsTable: {
    borderCollapse: 'separate',
    borderSpacing: '0',
    width: '100%',
    maxWidth: '800px',
    fontSize: '18px',
    '& th, & td': {
      padding: '15px',
      textAlign: 'center',
      border: '1px solid #ddd'
    },
    '& th': {
      backgroundColor: '#35671C',
      color: 'white',
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    '& td': {
      backgroundColor: '#f9f9f9',
      fontWeight: 'bold'
    }
  },
  commentsContainer: {
    width: '100%',
    marginTop: '24px'
  },
  commentsHeader: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '12px'
  },
  commentsScrollable: {
    border: '1px solid #ddd',
    borderRadius: '4px',
    padding: '8px'
  },
  commentItem: {
    marginBottom: '8px',
    padding: '8px',
    backgroundColor: '#f9f9f9',
    borderRadius: '4px',
    '&:last-child': {
      marginBottom: 0
    }
  }
};

export default withStyles(styles)(RecipeModal);
