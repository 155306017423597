import React from 'react';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    },
    height: 'auto'
  }
}));

const defaultPosition: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'center'
};

const topRightPosition: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'right'
};

interface Props {
  visible: boolean;
  message: string;
  handleClose: (event: React.SyntheticEvent<any, Event>) => void;
  position: boolean;
}

export default function CustomSnackbar(props: Props) {
  const classes: ClassNameMap = useStyles();

  return (
    <div className={classes.root}>
      {props.position ? (
        <Snackbar
          anchorOrigin={topRightPosition}
          open={props.visible}
          autoHideDuration={1000}
          onClose={props.handleClose}
        >
          <Alert onClose={props.handleClose} severity="info">
            {props.message}
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          anchorOrigin={defaultPosition}
          open={props.visible}
          message={props.message}
          autoHideDuration={1000}
          onClose={props.handleClose}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={props.handleClose}
            >
              <CloseIcon />
            </IconButton>
          }
        />
      )}
    </div>
  );
}
