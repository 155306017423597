import React, { useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {
  Button,
  DialogContent,
  Switch,
  TextField,
  Theme,
  withStyles
} from '@material-ui/core';
import * as uiParams from './uiParams';
import { ClassNameMap, Styles } from '@material-ui/core/styles/withStyles';
import { DispatcherBox } from '../dispatcher/domain';

interface Props {
  classes: ClassNameMap;
  onClose: (value: number) => void;
  onEscape: () => void;
  box: DispatcherBox;
  open: boolean;
  onSubmit: (box: DispatcherBox) => void;
}

function BoxDetailsModal(props: Props) {
  const [boxName, setBoxName] = useState(props.box.name || '');

  const [showPlateField, setShowPlateField] = useState(
    props.box.plateId !== null
  );

  const [plateId, setPlateId] = useState<string>(props.box.plateId || '');

  const onClose = () => {
    props.onEscape();
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit();

      const target = e.target as HTMLInputElement;
      props.onClose(parseInt(target.value, 10));

      return;
    } else if (e.key === 'Escape') {
      props.onEscape();
    }
  };

  const onSubmit = () => {
    if (boxName !== '') {
      props.onSubmit({
        ...props.box,
        name: boxName,
        plateId: showPlateField ? plateId || null : null
      });
    }
  };

  return (
    <Dialog
      className={props.classes.dialogStyle}
      onClose={onClose}
      open={props.open}
    >
      <DialogTitle id="dispatcherDialog">Box Details</DialogTitle>
      <DialogContent>
        <div className={props.classes.section}>
          <div className={props.classes.subSection}>
            <div className={props.classes.header}>Box Id:</div>
            <div>{props.box.uniqueIdentifier}</div>
          </div>
          <div>
            <div className={props.classes.header}>Box Type:</div>
            <div>{props.box.size.toUpperCase()}</div>
          </div>
        </div>
        <div className={props.classes.section}>
          <div className={props.classes.subSection}>
            <div className={props.classes.header}>Type of Dishes:</div>
            <div>{props.box.type.toUpperCase()}</div>
          </div>
        </div>
        <div className={props.classes.header}>Box Name:</div>
        <TextField
          autoFocus
          fullWidth
          value={boxName}
          onKeyUp={onKeyUp}
          className={props.classes.textField}
          onChange={(e) => setBoxName(e.target.value)}
          placeholder="Enter box name"
          margin="normal"
          variant="outlined"
        />
        {props.box.type === 'hot' ? (
          <>
            <div className={props.classes.header}>
              Add Plate:
              <Switch
                checked={showPlateField}
                onClick={() => setShowPlateField(!showPlateField)}
                color="primary"
              />
            </div>
            {showPlateField && (
              <TextField
                autoFocus
                fullWidth
                value={plateId}
                onKeyUp={onKeyUp}
                className={props.classes.textField}
                onChange={(e) => setPlateId(e.target.value)}
                placeholder="Enter Plate name"
                margin="normal"
                variant="standard"
              />
            )}
          </>
        ) : null}
        <Button
          className={props.classes.submit}
          fullWidth
          onClick={() => onSubmit()}
        >
          Submit
        </Button>
      </DialogContent>
    </Dialog>
  );
}

const styles: Styles<Theme, {}> = {
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '10px'
  },
  subSection: {
    flexDirection: 'column',
    marginRight: '20px'
  },
  header: {
    fontWeight: 'bold',
    fontSize: '15px'
  },
  cssLabel: {
    color: '#aaa'
  },
  cssOutlinedInput: {
    $notchedOutline: {
      borderColor: uiParams.WHITE
    }
  },
  textField: {
    height: '50px'
  },
  submit: {
    backgroundColor: uiParams.PRIMARY,
    alignSelf: 'center',
    color: uiParams.WHITE,
    marginTop: '10px',
    '&:hover': {
      backgroundColor: uiParams.SECONDARY
    }
  },
  notchedOutline: {
    width: '100%',
    borderRadius: uiParams.RAD_GLOBAL
  },
  dialogStyle: {
    alignItems: 'center',
    padding: '10px'
  },
  boxDetailsSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

export default withStyles(styles)(BoxDetailsModal);
