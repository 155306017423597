import { Styles } from '@material-ui/core/styles/withStyles';
import * as uiParams from './uiParams';
import { Theme } from '@material-ui/core';

export const commonStyles: Styles<Theme, {}> = {
  wrapper: {
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
    flex: 1,
    backgroundColor: uiParams.BG_THEME,
    flexDirection: 'column',
    borderRadius: uiParams.RAD_GLOBAL,
    paddingTop: '10px'
  },
  auxBar: {
    backgroundColor: uiParams.BG_THEME,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '32px',
    paddingRight: '32px'
  },
  error: {
    textAlign: 'center',
    color: 'red',
    fontSize: '13px'
  },
  container: {
    backgroundColor: uiParams.BG_THEME,
    margin: '20px 0',
    display: 'flex',
    height: '100%',
    flexWrap: 'wrap',
    overflow: 'scroll'
  }
};
