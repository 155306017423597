import React, { useState } from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import {
  OrderWithLanesData,
  PlacedOrderWithLaneData,
  addLanesDataToOrders,
  forecastsInLanesDataToOrders
} from '../../utils/kitchen/sortHelper';
import DishModal, {
  DishModalProps,
  InputDishModalData
} from './components/DishModal';
import DishLane from './components/DishLane';
import SearchBar from '../common/SearchBar';
import CustomSnackBar from '../common/CustomSnackbar';
import { setLocalStorage, getLocalStorage } from '../../utils/storageUtils';
import * as uiParams from '../common/uiParams';
import RecipeModal from './components/RecipeModal';
import {
  KitchenOrders,
  MenuEntry,
  LaneData,
  LanesEntry,
  UpdateDishKitchenData,
  UpdateKitchenDishesRequest,
  UpdatedForecastEntry
} from '../../api/kitchen';
import { ClassNameMap, Styles } from '@material-ui/core/styles/withStyles';
import {
  mapNetworkResponse,
  mergeNetworkResponses,
  useCommand,
  useQuery
} from '../../hooks/network/Network';
import {
  makeOrdersQuery,
  makeMenuQuery,
  makeLanesDataQuery,
  updateDishCommand
} from './api';
import Query from '../../components/common/Query/Query';
import { MenuItem, Select } from '@material-ui/core';
import { ForecastTimeRange } from '../OrderForecast/api';
import { forecastTimeRangesQuery } from '../api';
import OrderMetricBoxes from '../../components/common/OrderMetricBoxes';

interface Props {
  classes: ClassNameMap;
  isCatering: boolean;
}

function Kitchen(props: Props) {
  const [currentDate, setCurrentDate] = useState(() => {
    const savedDate = getLocalStorage('USER_SELECTED_DATE');
    const date = savedDate || new Date();

    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
  });

  const { response: ordersResponse, retry: fetchOrders } =
    useQuery<KitchenOrders>(
      makeOrdersQuery(
        currentDate,
        props.isCatering ? 'cateringAddons' : undefined
      )
    );

  const { response: menuResponse, retry: fetchMenu } = useQuery<MenuEntry[]>(
    makeMenuQuery(currentDate)
  );

  const { response: lanesDataResponse, retry: fetchLanesData } = useQuery<
    LanesEntry[]
  >(makeLanesDataQuery(currentDate));

  const { response: forecastTimeRangesResponse } = useQuery<
    ForecastTimeRange[]
  >(forecastTimeRangesQuery);

  const ordersFromForecastsInLanesData = mapNetworkResponse(
    mergeNetworkResponses({
      lanesData: lanesDataResponse,
      menu: menuResponse,
      timeRanges: forecastTimeRangesResponse
    }),
    ({ lanesData, menu, timeRanges }) =>
      forecastsInLanesDataToOrders(lanesData, menu, timeRanges)
  );

  const ordersFromOrdersResponse = mapNetworkResponse(
    mergeNetworkResponses({
      lanesData: lanesDataResponse,
      orders: ordersResponse,
      timeRanges: forecastTimeRangesResponse
    }),
    ({ lanesData, orders, timeRanges }) =>
      addLanesDataToOrders(orders.dishes, lanesData, timeRanges)
  );

  const orders = mapNetworkResponse(
    mergeNetworkResponses({
      ordersFromLanesData: ordersFromForecastsInLanesData,
      ordersFromOrdersResponse
    }),
    ({ ordersFromLanesData, ordersFromOrdersResponse }) => [
      ...ordersFromLanesData,
      ...ordersFromOrdersResponse
    ]
  );

  const kitchenContentData = mergeNetworkResponses({
    orders,
    timeRanges: forecastTimeRangesResponse
  });

  const fetchKitchenData = () => {
    fetchOrders();
    fetchMenu();
    fetchLanesData();
  };

  const onDateChange = (date: Date) => {
    setCurrentDate(date);
    setLocalStorage('USER_SELECTED_DATE', date);
  };

  return (
    <Query
      query={kitchenContentData}
      shadowChanges
      render={({ orders, timeRanges }) => (
        <KitchenContent
          {...props}
          orders={orders}
          timeRanges={timeRanges}
          currentDate={currentDate}
          onCurrentDateChange={onDateChange}
          onDishMoved={fetchKitchenData}
        />
      )}
    />
  );
}

interface ClosedSnackState {
  open: false;
  message: string | null;
}

interface OpenSnackState {
  open: true;
  message: string;
}

type SnackState = ClosedSnackState | OpenSnackState;

interface KitchenContentProps extends Props {
  currentDate: Date;
  onCurrentDateChange: (currentDate: Date) => void;
  orders: OrderWithLanesData[];
  timeRanges: ForecastTimeRange[];
  onDishMoved: () => void;
}

function KitchenContent(props: KitchenContentProps) {
  const [snackState, setSnackState] = useState<SnackState>({
    open: false,
    message: null
  });

  const [searchQuery, setSearchQuery] = useState('');

  const [selectedTimeRange, setSelectedTimeRange] =
    useState<ForecastTimeRange | null>(() => {
      const startTimeToRangeMap = props.timeRanges.reduce<
        Record<number, ForecastTimeRange>
      >((result, timeRange) => {
        const [startTimeHours, startTimeMinutes] = timeRange.startTime
          .split(':')
          .map((n) => parseInt(n));

        result[startTimeHours * 60 + startTimeMinutes] = timeRange;
        return result;
      }, {});

      const minStartTime = Math.min(
        ...(Object.keys(startTimeToRangeMap) as unknown as number[])
      );

      return startTimeToRangeMap[minStartTime] || null;
    });

  const [selectedRecipeData, setSelectedRecipeData] =
    useState<OrderWithLanesData | null>(null);

  const [dishModalState, setDishModalState] = useState<DishModalProps>({
    open: false,
    data: null
  });

  const { response: updateDishResponse, sendRequest: updateDish } = useCommand<
    UpdateKitchenDishesRequest,
    unknown
  >(updateDishCommand);

  const filteredDishes: OrderWithLanesData[] = (() => {
    const filteredByQuery = (() => {
      if (searchQuery) {
        const query = searchQuery.trim().toLowerCase();

        return props.orders.filter((order) =>
          order.name.toLowerCase().includes(query)
        );
      } else {
        return props.orders;
      }
    })();

    const filteredByTimeRange = (() => {
      if (selectedTimeRange === null) {
        return filteredByQuery;
      } else {
        return filteredByQuery.filter(
          (order) => order.timeRange.id === selectedTimeRange.id
        );
      }
    })();

    return filteredByTimeRange;
  })();

  const dishLanes = props.isCatering
    ? []
    : [
        {
          laneId: 'inProcessing' as const,
          title: 'Processing',
          orders: filteredDishes
            .filter((order) => order.category !== 'addons')
            .filter(
              (order) =>
                order.lanesData.normalPackaging.inProcessing +
                  order.lanesData.reusablePackaging.inProcessing >
                0
            )
        },
        {
          laneId: 'inKitchen' as const,
          title: 'Cooking',
          orders: filteredDishes
            .filter((order) => order.category !== 'addons')
            .filter(
              (order) =>
                order.lanesData.normalPackaging.inKitchen +
                  order.lanesData.reusablePackaging.inKitchen >
                0
            )
        },
        {
          laneId: 'inCompleted' as const,
          title: 'Completed',
          orders: filteredDishes
            .filter((order) => order.category !== 'addons')
            .filter(
              (order) =>
                order.lanesData.normalPackaging.inCompleted +
                  order.lanesData.reusablePackaging.inCompleted >
                0
            )
        }
      ];

  const addonLanes = [
    {
      laneId: 'inProcessing' as const,
      title: 'Processing',
      orders: filteredDishes
        .filter((order) => order.category === 'addons')
        .filter(
          (order) =>
            order.lanesData.normalPackaging.inProcessing +
              order.lanesData.reusablePackaging.inProcessing >
            0
        )
    },
    {
      laneId: 'inKitchen' as const,
      title: 'Cooking',
      orders: filteredDishes
        .filter((order) => order.category === 'addons')
        .filter(
          (order) =>
            order.lanesData.normalPackaging.inKitchen +
              order.lanesData.reusablePackaging.inKitchen >
            0
        )
    },
    {
      laneId: 'inCompleted' as const,
      title: 'Completed',
      orders: filteredDishes
        .filter((order) => order.category === 'addons')
        .filter(
          (order) =>
            order.lanesData.normalPackaging.inCompleted +
              order.lanesData.reusablePackaging.inCompleted >
            0
        )
    }
  ];

  const bowlTypeTotals = props.orders.reduce(
    (ref, order) => {
      /** `order.quantity` is not reliable due to mismatching kitchen numbers
      so we're using lanes quantities to get the right totals. */
      const orderQuantity =
        order.lanesData.normalPackaging.inCompleted +
        order.lanesData.normalPackaging.inKitchen +
        order.lanesData.normalPackaging.inProcessing +
        order.lanesData.reusablePackaging.inCompleted +
        order.lanesData.reusablePackaging.inKitchen +
        order.lanesData.reusablePackaging.inProcessing;

      switch (order.bowlType) {
        case 'round':
          ref.round += orderQuantity;

          return ref;
        case 'compartment':
          ref.compartment += orderQuantity;

          return ref;
        case null:
          if (order.cat === 'AD') {
            ref.addons += orderQuantity;
          } else {
            ref.unknown += orderQuantity;
          }

          return ref;
        default:
          throw new Error(`Invalid bowlType: ${order.bowlType}`);
      }
    },
    {
      round: 0,
      compartment: 0,
      addons: 0,
      unknown: 0
    }
  );

  const moveOrders = (
    orders: OrderWithLanesData[],
    fromLaneId: keyof LaneData,
    toLaneId: keyof LaneData,
    movingQuantity: number
  ): {
    orders: UpdateDishKitchenData[];
    forecastEntries: UpdatedForecastEntry[];
  } => {
    const ordersByTime = orders
      .filter(
        (order) =>
          order.lanesData.normalPackaging[fromLaneId] +
            order.lanesData.reusablePackaging[fromLaneId] >
          0
      )
      // Sort by readyTime, then prioritize orders over forecasts
      .sort((a, b) => {
        if (a.readyBy !== b.readyBy) {
          return a.readyBy - b.readyBy;
        } else {
          const isAForecast = a.orderType === 'forecasted';
          const isBForecast = b.orderType === 'forecasted';

          if (isAForecast !== isBForecast) {
            if (isAForecast) {
              return 1;
            } else {
              return -1;
            }
          } else {
            return 0;
          }
        }
      });

    const updatedOrders = ordersByTime.reduce<{
      qty: number;
      result: OrderWithLanesData[];
    }>(
      ({ qty, result }, order) => {
        if (qty === 0) {
          return { qty, result };
        } else {
          const movableQty = Math.min(
            order.lanesData.normalPackaging[fromLaneId] +
              order.lanesData.reusablePackaging[fromLaneId],
            qty
          );

          // Move normalPackaging first
          const normalPackagingMovableQty = Math.min(
            order.lanesData.normalPackaging[fromLaneId],
            movableQty
          );

          const afterNormalPackagingMove: OrderWithLanesData = {
            ...order,
            lanesData: {
              ...order.lanesData,
              normalPackaging: {
                ...order.lanesData.normalPackaging,
                [fromLaneId]:
                  order.lanesData.normalPackaging[fromLaneId] -
                  normalPackagingMovableQty,
                [toLaneId]:
                  order.lanesData.normalPackaging[toLaneId] +
                  normalPackagingMovableQty
              }
            }
          };

          const afterReusablePackagingMove: OrderWithLanesData = (() => {
            if (normalPackagingMovableQty >= movableQty) {
              return afterNormalPackagingMove;
            } else {
              const reusablePackagingMovableQty = Math.min(
                order.lanesData.reusablePackaging[fromLaneId] -
                  normalPackagingMovableQty,
                movableQty - normalPackagingMovableQty
              );

              return {
                ...afterNormalPackagingMove,
                lanesData: {
                  ...afterNormalPackagingMove.lanesData,
                  reusablePackaging: {
                    ...afterNormalPackagingMove.lanesData.reusablePackaging,
                    [fromLaneId]:
                      afterNormalPackagingMove.lanesData.reusablePackaging[
                        fromLaneId
                      ] - reusablePackagingMovableQty,
                    [toLaneId]:
                      afterNormalPackagingMove.lanesData.reusablePackaging[
                        toLaneId
                      ] + reusablePackagingMovableQty
                  }
                }
              };
            }
          })();

          result.push(afterReusablePackagingMove);

          return { qty: qty - movableQty, result };
        }
      },
      { qty: movingQuantity, result: [] }
    );

    return {
      orders: updatedOrders.result
        .filter((order) => order.orderType === 'placed')
        .map((order) => order as PlacedOrderWithLaneData)
        .map((order) => ({
          id: order.id,
          itemNo: order.itemNo,
          inProcessing:
            order.lanesData.normalPackaging.inProcessing +
            order.lanesData.reusablePackaging.inProcessing,
          inKitchen:
            order.lanesData.normalPackaging.inKitchen +
            order.lanesData.reusablePackaging.inKitchen,
          completed:
            order.lanesData.normalPackaging.inCompleted +
            order.lanesData.reusablePackaging.inCompleted,
          orderId: order.orderId,
          timeRangeId: order.timeRange.id
        })),
      forecastEntries: updatedOrders.result
        .filter((order) => order.orderType === 'forecasted')
        .map((order) => ({
          itemNo: order.itemNo,
          deliveryDate: order.deliveryDate,
          normalPackaging: order.lanesData.normalPackaging,
          reusablePackaging: order.lanesData.reusablePackaging,
          timeRangeId: order.timeRange.id
        }))
    };
  };

  const onDishModalInputSubmit = async (
    dishModalData: InputDishModalData,
    nonValidatedQuantity: number
  ) => {
    setDishModalState((state) => ({ ...state, open: false }));

    const quantity = Math.min(
      Math.max(nonValidatedQuantity, 0),
      dishModalData.maxQuantity
    );

    const updateResult = moveOrders(
      dishModalData.orders,
      dishModalData.currentLane,
      dishModalData.targetLane,
      quantity
    );

    if (
      updateResult.orders.length > 0 ||
      updateResult.forecastEntries.length > 0
    ) {
      updateDish({
        orders: updateResult.orders,
        lanesData: updateResult.forecastEntries
      }).then(() => props.onDishMoved());
    }
  };

  const dishModal = (() => {
    const onCancel = () =>
      setDishModalState((state) => ({ ...state, open: false }));

    if (dishModalState.open) {
      switch (dishModalState.data.mode) {
        case 'info':
          return (
            <DishModal
              {...dishModalState}
              data={dishModalState.data}
              onCancel={onCancel}
            />
          );
        case 'input':
          return (
            <DishModal
              {...dishModalState}
              onCancel={onCancel}
              data={{
                ...dishModalState.data,
                onSubmit: (quantity) => {
                  if (dishModalState.data.mode === 'input') {
                    onDishModalInputSubmit(dishModalState.data, quantity);
                  }
                }
              }}
            />
          );
      }
    } else {
      return null;
    }
  })();

  const openQuantitiesReadyByModal = (
    laneId: keyof LaneData,
    orders: OrderWithLanesData[]
  ) => {
    const byReadyBy = orders.reduce<Record<number, OrderWithLanesData>>(
      (result, order) => {
        if (order.readyBy in result) {
          return {
            ...result,
            [order.readyBy]: {
              ...result[order.readyBy],
              lanesData: {
                normalPackaging: {
                  inProcessing:
                    result[order.readyBy].lanesData.normalPackaging
                      .inProcessing +
                    order.lanesData.normalPackaging.inProcessing,
                  inKitchen:
                    result[order.readyBy].lanesData.normalPackaging.inKitchen +
                    order.lanesData.normalPackaging.inKitchen,
                  inCompleted:
                    result[order.readyBy].lanesData.normalPackaging
                      .inCompleted + order.lanesData.normalPackaging.inCompleted
                },
                reusablePackaging: {
                  inProcessing:
                    result[order.readyBy].lanesData.reusablePackaging
                      .inProcessing +
                    order.lanesData.reusablePackaging.inProcessing,
                  inKitchen:
                    result[order.readyBy].lanesData.reusablePackaging
                      .inKitchen + order.lanesData.reusablePackaging.inKitchen,
                  inCompleted:
                    result[order.readyBy].lanesData.reusablePackaging
                      .inCompleted +
                    order.lanesData.reusablePackaging.inCompleted
                }
              }
            }
          };
        } else {
          return {
            ...result,
            [order.readyBy]: order
          };
        }
      },
      {}
    );

    setDishModalState({
      open: true,
      data: {
        mode: 'info',
        currentLane: laneId,
        orders: Object.values(byReadyBy)
      }
    });
  };

  const onCardArrowClick = async (
    fromLaneId: keyof LaneData,
    toLaneId: keyof LaneData,
    orders: OrderWithLanesData[]
  ) => {
    if (fromLaneId !== toLaneId) {
      const quantityInLane = orders.reduce(
        (sum, order) =>
          sum +
          order.lanesData.normalPackaging[fromLaneId] +
          order.lanesData.reusablePackaging[fromLaneId],
        0
      );

      if (quantityInLane > 1) {
        setDishModalState({
          open: true,
          data: {
            mode: 'input',
            orders,
            currentLane: fromLaneId,
            targetLane: toLaneId,
            maxQuantity: quantityInLane
          }
        });
      } else {
        const updateResult = moveOrders(orders, fromLaneId, toLaneId, 1);

        if (
          updateResult.orders.length > 0 ||
          updateResult.forecastEntries.length > 0
        ) {
          updateDish({
            orders: updateResult.orders,
            lanesData: updateResult.forecastEntries
          }).then(() => props.onDishMoved());
        }
      }
    }
  };

  const onTimeRangeSelect = (selectedTimeRangeId: string) => {
    if (selectedTimeRangeId !== 'all') {
      const timeRange = props.timeRanges.find(
        (timeRange) => timeRange.id === selectedTimeRangeId
      );

      if (timeRange) {
        setSelectedTimeRange(timeRange);
      }
    } else {
      setSelectedTimeRange(null);
    }
  };

  return (
    <div className={props.classes.wrapper}>
      <CustomSnackBar
        visible={snackState.open}
        message={(() => {
          if (snackState.open) {
            return snackState.message;
          } else {
            return snackState.message || '';
          }
        })()}
        handleClose={() =>
          setSnackState((state) => ({ ...state, open: false }))
        }
        position={false}
      />

      <SearchBar
        onSearch={setSearchQuery}
        getting={updateDishResponse.type === 'loading'}
        accumulatingData={updateDishResponse.type === 'loading'}
        onPickDate={props.onCurrentDateChange}
        currentSelectedDate={props.currentDate}
        title={props.isCatering ? 'Catering Dishes' : 'Daily Lunch Dishes'}
      />

      {updateDishResponse.type === 'failed' ? (
        <span className={props.classes.error}>
          {updateDishResponse.error.message}
        </span>
      ) : null}

      <div className={props.classes.row}>
        <div>
          <div className={props.classes.legend}>
            <div className={props.classes.pkgOptStyle} /> - Reusable Packaging
            <div className={props.classes.normalPkgStyle} /> - Normal Packaging
            <div className={props.classes.qtyStyle} /> - Total Quantity
          </div>
        </div>
        <div>
          <Select
            value={selectedTimeRange?.id || 'all'}
            onChange={(e) => onTimeRangeSelect(e.target.value as string)}
          >
            <MenuItem value="all">Whole Day</MenuItem>
            {props.timeRanges.map((timeRange) => (
              <MenuItem key={timeRange.id} value={timeRange.id}>
                {timeRange.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>

      <OrderMetricBoxes numbersData={{
        'Round': bowlTypeTotals.round,
        'Compartment': bowlTypeTotals.compartment,
        'Unknown': bowlTypeTotals.unknown,
        'None (addons)': bowlTypeTotals.addons
      }}/>

      <section className={props.classes.dishWrapper}>
        {dishLanes.map((lane) => (
          <DishLane
            title={lane.title}
            laneId={lane.laneId}
            isLoading={updateDishResponse.type === 'loading'}
            key={lane.laneId}
            orders={lane.orders}
            onShowRecipeButtonClick={setSelectedRecipeData}
            onCardArrowClick={(targetLane, orders) =>
              onCardArrowClick(lane.laneId, targetLane, orders)
            }
            onCardClick={(orders) =>
              openQuantitiesReadyByModal(lane.laneId, orders)
            }
          />
        ))}
      </section>
      <h2 className={props.classes.sectionTitle}>ADDONS</h2>
      <section className={props.classes.dishWrapper}>
        {addonLanes.map((lane) => (
          <DishLane
            title={lane.title}
            laneId={lane.laneId}
            isLoading={updateDishResponse.type === 'loading'}
            key={lane.laneId}
            orders={lane.orders}
            onShowRecipeButtonClick={setSelectedRecipeData}
            onCardArrowClick={(targetLane, orders) =>
              onCardArrowClick(lane.laneId, targetLane, orders)
            }
            onCardClick={(dish) =>
              openQuantitiesReadyByModal(lane.laneId, dish)
            }
          />
        ))}
      </section>
      {selectedRecipeData ? (
        <RecipeModal
          order={selectedRecipeData}
          onClose={() => setSelectedRecipeData(null)}
          open
        />
      ) : null}
      {dishModal}
    </div>
  );
}

const styles: Styles<Theme, {}> = {
  wrapper: {
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
    flex: 1,
    backgroundColor: uiParams.BG_THEME,
    flexDirection: 'column',
    borderRadius: uiParams.RAD_GLOBAL,
    paddingTop: '10px'
  },
  row: {
    display: 'flex',
    with: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1em'
  },
  error: {
    textAlign: 'center',
    color: 'red',
    fontSize: '13px'
  },
  title: {
    padding: '15px',
    fontSize: '28px',
    textAlign: 'center',
    backgroundColor: uiParams.BG_THEME
  },
  dateStyle: {
    fontSize: '16px',
    maxWidth: '100px',
    background: '#b3d1ff',
    borderCollapse: 'collapse',
    borderRadius: '32px',
    padding: '5px 10px 5px 10px'
  },
  boardStyle: {
    backgroundColor: uiParams.BG_THEME,
    justifyContent: 'center',
    borderRadius: uiParams.RAD_GLOBAL
  },
  sectionTitle: {
    padding: '10px 0 0 10px',
    margin: 0
  },
  dishWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '30px',
    padding: '10px 12px'
  },
  qtyStyle: {
    width: '30px',
    height: '30px',
    backgroundColor: uiParams.PRIMARY,
    color: uiParams.WHITE,
    borderRadius: '10%',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '0 0.5em 0 1em',
    paddingTop: '4px',
    textAlign: 'center',
    cursor: 'pointer'
  },
  pkgOptStyle: {
    width: '30px',
    height: '30px',
    backgroundColor: uiParams.RELEVO,
    color: uiParams.WHITE,
    borderRadius: '10%',
    fontSize: '16px',
    fontWeight: 'bold',
    marginRight: '0.5em',
    paddingTop: '4px',
    textAlign: 'center',
    cursor: 'pointer'
  },
  normalPkgStyle: {
    width: '30px',
    height: '30px',
    backgroundColor: uiParams.NORMAL_PKG,
    color: uiParams.WHITE,
    borderRadius: '10%',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '0 0.5em 0 1em',
    paddingTop: '4px',
    textAlign: 'center',
    cursor: 'pointer'
  },
  legend: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    width: '100%'
  }
};

export default withStyles(styles)(Kitchen);
