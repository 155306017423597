import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Button, Card, CircularProgress, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { signInAction } from '../../redux/actions';
import { isAuthenticated } from '../../utils/jwtUtil';
import logo from '../../assets/homelogo.svg';
import * as uiParams from '../common/uiParams';
import { getRedirectPath } from '../../utils/commonUtils';

const Auth = ({ layoutRoutes, classes, logInAction, loggingIn, error }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSignIn = () => {
    localStorage.clear();
    logInAction({ username, password });
  };

  const onEnterKey = (value) => {
    if (value === 13) {
      localStorage.clear();
      handleSignIn();
    }
  };

  if (isAuthenticated()) {
    const redirectTo = getRedirectPath(layoutRoutes);
    return <Redirect to={{ pathname: redirectTo, from: '/auth' }} />;
  }

  return (
    <div className={classes.wrapper}>
      <Card className={classes.form}>
        <img src={logo} className={classes.imageStyle} alt="" />
        <TextField
          className={classes.textField}
          label="UserName"
          margin="normal"
          variant="outlined"
          onChange={(e) => setUsername(e.target.value)}
          onKeyUp={(e) => onEnterKey(e.keyCode)}
          value={username}
        />
        <TextField
          className={classes.textField}
          type="password"
          label="Password"
          margin="normal"
          variant="outlined"
          onChange={(e) => setPassword(e.target.value)}
          onKeyUp={(e) => onEnterKey(e.keyCode)}
          value={password}
        />
        <span className={classes.error}>{error.message}</span>
        {loggingIn ? <CircularProgress color="primary" /> : null}
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleSignIn}
        >
          Sign In
        </Button>
      </Card>
    </div>
  );
};

const styles = {
  textField: {
    marginTop: '20px',
    width: '100%',
    backgroundColor: uiParams.BG_TEXT_FLD,
    borderRadius: uiParams.RAD_GLOBAL
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '350px',
    padding: '20px 50px 50px 50px',
    height: '350px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: uiParams.RAD_GLOBAL
  },
  button: {
    marginTop: 25,
    width: '100%',
    backgroundColor: uiParams.PRIMARY
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: uiParams.BG_THEME
  },
  error: {
    textAlign: 'center',
    color: 'red',
    fontSize: '13px'
  },
  imageStyle: {
    marginTop: '25px'
  }
};

const mapStateToProps = (state) => ({
  loggingIn: state.auth.loggingIn,
  error: state.auth.error
});

const mapDispatchToProps = {
  logInAction: signInAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Auth));
