export class TodoList<T> {
  private readonly eq: (a: T, b: T) => boolean;
  private readonly todo: T[];
  private readonly doing: T | null;
  private readonly done: T[];

  private constructor(
    eq: (a: T, b: T) => boolean,
    todo: T[],
    doing: T | null,
    done: T[]
  ) {
    this.eq = eq;
    this.todo = todo;
    this.doing = doing;
    this.done = done;
  }

  static make<T>(eq: (a: T, b: T) => boolean, list: T[]): TodoList<T> {
    return new TodoList(eq, list, null, []);
  }

  static fromState<T>(
    eq: (a: T, b: T) => boolean,
    done: T[],
    rest: T[]
  ): TodoList<T> {
    return new TodoList(eq, rest, null, done);
  }

  public hasTodo(item?: T): boolean {
    if (typeof item !== 'undefined') {
      return (
        typeof this.todo.find((target) => this.eq(target, item)) !== 'undefined'
      );
    } else {
      return this.todo.length > 0;
    }
  }

  public hasDone(item?: T): boolean {
    if (typeof item !== 'undefined') {
      return (
        typeof this.done.find((target) => this.eq(target, item)) !== 'undefined'
      );
    } else {
      return this.done.length > 0;
    }
  }

  public hasDoing(item?: T): boolean {
    if (this.doing === null) {
      return false;
    } else if (typeof item !== 'undefined') {
      return this.eq(item, this.doing);
    } else {
      return true;
    }
  }

  public getTodo(): T[] {
    return this.todo;
  }

  public getDoing(): T | null {
    return this.doing;
  }

  public mapDoing(mapFn: (doing: T) => T): TodoList<T> {
    if (this.doing === null) {
      throw new Error(
        'Trying to map the non existing current item of a todo list'
      );
    } else {
      return new TodoList(this.eq, this.todo, mapFn(this.doing), this.done);
    }
  }

  public getDone(): T[] {
    return this.done;
  }

  public setDoing(item: T): TodoList<T> {
    if (this.hasDoing(item) || this.hasTodo(item)) {
      return new TodoList(
        this.eq,
        this.todo.filter((target) => !this.eq(target, item)),
        item,
        this.done
      );
    } else {
      return this;
    }
  }

  public getLatestDone(): T | null {
    return this.done[0];
  }

  public setDone(item?: T): TodoList<T> {
    if (this.doing !== null && !item) {
      return new TodoList(this.eq, this.todo, null, [...this.done, this.doing]);
    } else if (item) {
      return new TodoList(
        this.eq,
        this.todo.filter((target) => !this.eq(target, item)),
        null,
        [...this.done, item]
      );
    } else {
      return this;
    }
  }

  public cancelDoing(): TodoList<T> {
    if (this.doing !== null) {
      return new TodoList(this.eq, [...this.todo, this.doing], null, this.done);
    } else {
      return this;
    }
  }

  public setUndone(item: T): TodoList<T> {
    if (this.hasDone(item)) {
      return new TodoList(
        this.eq,
        [...this.todo, item],
        this.doing,
        this.done.filter((target) => !this.eq(target, item))
      );
    } else {
      return this;
    }
  }

  public shift(): TodoList<T> {
    return new TodoList(
      this.eq,
      this.todo.slice(1),
      this.todo[0] ?? null,
      this.doing === null ? this.done : [...this.done, this.doing]
    );
  }

  public toArray(): T[] {
    return [
      ...this.todo,
      ...(this.doing === null ? [] : [this.doing]),
      ...this.done
    ];
  }
}
