import {
  MenuEntry,
  Order,
  LanesEntry,
  PackagingLaneData
} from '../../api/kitchen';
import { ForecastTimeRange } from '../../scenes/OrderForecast/api';

interface BaseOrderWithLaneData
  extends Omit<Order, 'inProcessing' | 'inKitchen' | 'completed' | 'orderId'> {
  deliveryDate: string; // SQL date
  timeRange: ForecastTimeRange;
  lanesData: PackagingLaneData;
}

export interface PlacedOrderWithLaneData extends BaseOrderWithLaneData {
  orderType: 'placed';
  orderId: string;
}

export interface ForecastedOrderWithLaneData extends BaseOrderWithLaneData {
  orderType: 'forecasted';
}

export type OrderWithLanesData =
  | PlacedOrderWithLaneData
  | ForecastedOrderWithLaneData;

export function forecastsInLanesDataToOrders(
  lanesData: LanesEntry[],
  menu: MenuEntry[],
  timeRanges: ForecastTimeRange[]
): ForecastedOrderWithLaneData[] {
  return lanesData
    .filter((entry) => entry.itemNo.startsWith('FORC-'))
    .reduce<
      Array<{
        lanesEntry: LanesEntry;
        menuEntry: MenuEntry;
        timeRange: ForecastTimeRange;
      }>
    >((result, lanesEntry) => {
      const menuEntry = menu.find(
        (menuEntry) => menuEntry.itemNo === lanesEntry.itemNo.substring(5)
      );

      const timeRange = timeRanges.find(
        (timeRange) => timeRange.id === lanesEntry.timeRangeId
      );

      if (menuEntry && timeRange) {
        result.push({ lanesEntry, menuEntry, timeRange });
      }

      return result;
    }, [])
    .map<ForecastedOrderWithLaneData>(
      ({ lanesEntry, menuEntry, timeRange }) => {
        const [readyByTimeHours, readyByTimeMins] = timeRange.endTime
          .split(':')
          .map((n) => parseInt(n, 10));

        const readyByTimeMinsFromMidnight =
          readyByTimeMins + readyByTimeHours * 60;

        return {
          orderType: 'forecasted',
          cat: menuEntry.cat,
          bowlType: menuEntry.bowlType,
          category: menuEntry.categoryName,
          lanesData: {
            normalPackaging: lanesEntry.normalPackaging,
            reusablePackaging: lanesEntry.reusablePackaging
          },
          id: menuEntry.id,
          itemNo: lanesEntry.itemNo,
          name: menuEntry.name,
          num: menuEntry.num,
          packageOption: 'reusable',
          productId: menuEntry.id,
          quantity: 1,
          readyBy: readyByTimeMinsFromMidnight,
          recipe: menuEntry.recipe,
          type: menuEntry.type,
          updatedAt: menuEntry.updatedAt,
          webUrl: menuEntry.webUrl,
          deliveryDate: lanesEntry.deliveryDate,
          timeRange
        };
      }
    );
}

export function addLanesDataToOrders(
  orders: Order[],
  lanesData: LanesEntry[],
  timeRanges: ForecastTimeRange[]
): PlacedOrderWithLaneData[] {
  return lanesData
    .filter((entry) => !entry.itemNo.startsWith('FORC-'))
    .reduce<PlacedOrderWithLaneData[]>((result, laneEntry) => {
      const timeRange = timeRanges.find(
        (timeRange) => timeRange.id === laneEntry.timeRangeId
      );

      if (!timeRange) {
        return result;
      }

      const [startTimeHours, startTimeMinutes] = timeRange.startTime
        .split(':')
        .map((_) => parseInt(_));

      const [endTimeHours, endTimeMinutes] = timeRange.endTime
        .split(':')
        .map((_) => parseInt(_));

      const startTimeMinutesToMidnight = startTimeHours * 60 + startTimeMinutes;
      const endTimeMinutesToMidnight = endTimeHours * 60 + endTimeMinutes;

      const ordersForLane = orders.filter(
        (order) =>
          order.itemNo === laneEntry.itemNo &&
          order.readyBy >= startTimeMinutesToMidnight &&
          order.readyBy <= endTimeMinutesToMidnight
      );

      const ordersWithLaneData = ordersForLane.reduce<
        PlacedOrderWithLaneData[]
      >((result, order) => {
        if (!order.orderId) {
          return result;
        }

        const lanesData: PackagingLaneData = (() => {
          switch (order.packageOption) {
            case 'mono':
              return {
                normalPackaging: {
                  inProcessing: order.inProcessing,
                  inKitchen: order.inKitchen,
                  inCompleted: order.completed
                },
                reusablePackaging: {
                  inProcessing: 0,
                  inKitchen: 0,
                  inCompleted: 0
                }
              };
            case 'reusable':
              return {
                reusablePackaging: {
                  inProcessing: order.inProcessing,
                  inKitchen: order.inKitchen,
                  inCompleted: order.completed
                },
                normalPackaging: {
                  inProcessing: 0,
                  inKitchen: 0,
                  inCompleted: 0
                }
              };
          }
        })();

        result.push({
          orderType: 'placed',
          ...order,
          orderId: order.orderId,
          timeRange,
          deliveryDate: laneEntry.deliveryDate,
          lanesData
        });

        return result;
      }, []);

      result.push(...ordersWithLaneData);
      return result;
    }, []);
}
