import { SetStateAction, useState } from 'react';
import { API_ENDPOINT } from '../../config/constants';
import { NetworkError } from './NetworkError';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

interface NetworkRequest {
  path: string;
  method: HttpMethod;
}

export function makeGetNetworkRequest(
  data: Omit<NetworkRequest, 'method'>
): NetworkRequest {
  return {
    ...data,
    method: 'GET'
  };
}

export function makePostNetworkRequest(
  data: Omit<NetworkRequest, 'method'>
): NetworkRequest {
  return {
    ...data,
    method: 'POST'
  };
}

export function makePutNetworkRequest(
  data: Omit<NetworkRequest, 'method'>
): NetworkRequest {
  return {
    ...data,
    method: 'PUT'
  };
}

export function makePatchNetworkRequest(
  data: Omit<NetworkRequest, 'method'>
): NetworkRequest {
  return {
    ...data,
    method: 'PATCH'
  };
}

export function makeDeleteNetworkRequest(
  data: Omit<NetworkRequest, 'method'>
): NetworkRequest {
  return {
    ...data,
    method: 'DELETE'
  };
}

interface ApiResponse<T> {
  status: boolean;
  data: T;
}

function sendNetworkRequest<I extends Record<string, any> | void, O>(
  request: NetworkRequest,
  input?: I | undefined
): Promise<O> {
  const query: string = (() => {
    switch (request.method) {
      case 'GET':
      case 'DELETE': {
        if (input) {
          const query = new URLSearchParams();

          Object.entries(input).forEach(([key, value]) => {
            query.append(key, value);
          });

          return '?' + query.toString();
        } else {
          return '';
        }
      }
      case 'POST':
      case 'PATCH':
      case 'PUT':
        return '';
    }
  })();

  const body: string | undefined = (() => {
    switch (request.method) {
      case 'GET':
      case 'DELETE':
        return undefined;
      case 'POST':
      case 'PATCH':
      case 'PUT': {
        if (input) {
          return JSON.stringify(input);
        } else {
          return undefined;
        }
      }
    }
  })();

  return window
    .fetch(API_ENDPOINT + request.path + query, {
      method: request.method,
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body
    })
    .then(
      (response) => {
        if (Math.floor(response.status / 100) !== 2) {
          return Promise.reject(
            new NetworkError(response.status, response.statusText, {
              request,
              input
            })
          );
        } else {
          return response.json();
        }
      },
      (error) =>
        Promise.reject(
          new NetworkError(500, 'Unable to fetch', { error, request, input })
        )
    )
    .then(
      (response: ApiResponse<O>) => {
        if (response.status) {
          return response.data;
        } else {
          // TODO: get the error message from the server response
          return Promise.reject(new NetworkError(500, 'Application error'));
        }
      },
      (error) => {
        if (error instanceof NetworkError) {
          return Promise.reject(error);
        } else {
          return Promise.reject(
            new NetworkError(500, 'Unable to parse response', {
              error,
              request,
              input
            })
          );
        }
      }
    );
}

interface IdleNetworkResponse {
  type: 'idle';
}

interface LoadingNetworkResponse<T> {
  type: 'loading';
  previousData: T | null;
}

interface SuccessfulNetworkResponse<T> {
  type: 'successful';
  data: T;
}

interface FailedNetworkResponse<E> {
  type: 'failed';
  error: E;
}

function makeIdleNetworkResponse(): IdleNetworkResponse {
  return { type: 'idle' };
}

function makeLoadingNetworkResponse<T>(
  previousData: T | null
): LoadingNetworkResponse<T> {
  return { type: 'loading', previousData };
}

function makeSuccessfulNetworkResponse<T>(
  data: T
): SuccessfulNetworkResponse<T> {
  return { type: 'successful', data };
}

function makeFailedNetworkResponse<E>(error: E): FailedNetworkResponse<E> {
  return { type: 'failed', error };
}

export type NetworkResponse<T> =
  | IdleNetworkResponse
  | LoadingNetworkResponse<T>
  | FailedNetworkResponse<NetworkError>
  | SuccessfulNetworkResponse<T>;

export function matchNetworkResponse<T, O>(
  response: NetworkResponse<T>,
  cases: {
    whenIdle: () => O;
    whenLoading: (previousData: T | null) => O;
    whenSuccessful: (data: T) => O;
    whenFailed: (error: NetworkError) => O;
  }
): O {
  switch (response.type) {
    case 'idle':
      return cases.whenIdle();
    case 'loading':
      return cases.whenLoading(response.previousData);
    case 'successful':
      return cases.whenSuccessful(response.data);
    case 'failed':
      return cases.whenFailed(response.error);
  }
}

export function mapNetworkResponse<A, B>(
  response: NetworkResponse<A>,
  mapFn: (a: A) => B
): NetworkResponse<B> {
  return matchNetworkResponse<A, NetworkResponse<B>>(response, {
    whenIdle: () => makeIdleNetworkResponse(),
    whenLoading: (previousData) => {
      if (previousData) {
        return makeLoadingNetworkResponse(mapFn(previousData));
      } else {
        return makeLoadingNetworkResponse(null);
      }
    },
    whenFailed: (error) => makeFailedNetworkResponse(error),
    whenSuccessful: (a) => makeSuccessfulNetworkResponse(mapFn(a))
  });
}

type MergedResponse<Map extends Record<string, NetworkResponse<any>>> = {
  [k in keyof Map]: Map[k] extends NetworkResponse<infer T> ? T : never;
};

export function mergeNetworkResponses<
  Map extends Record<string, NetworkResponse<any>>
>(map: Map): NetworkResponse<MergedResponse<Map>> {
  return Object.entries(map).reduce<NetworkResponse<MergedResponse<Map>>>(
    (result, [key, response]) =>
      matchNetworkResponse(result, {
        whenIdle: () => makeIdleNetworkResponse(),
        whenLoading: (previousResultData) => {
          if (previousResultData) {
            return matchNetworkResponse(response, {
              whenIdle: () => makeIdleNetworkResponse(),
              whenLoading: (previousData) => {
                if (previousData) {
                  previousResultData[key as keyof Map] = previousData;
                  return makeLoadingNetworkResponse(previousResultData);
                } else {
                  return makeLoadingNetworkResponse<MergedResponse<Map>>(null);
                }
              },
              whenFailed: (error) =>
                makeFailedNetworkResponse(error) as NetworkResponse<
                  MergedResponse<Map>
                >,
              whenSuccessful: (data) => {
                previousResultData[key as keyof Map] = data;
                return makeSuccessfulNetworkResponse(previousResultData);
              }
            });
          } else {
            return result;
          }
        },
        whenFailed: (error) => makeFailedNetworkResponse(error),
        whenSuccessful: (resultData) =>
          matchNetworkResponse(response, {
            whenIdle: () => makeIdleNetworkResponse(),
            whenLoading: (previousData) => {
              if (previousData) {
                resultData[key as keyof Map] = previousData;
                return makeLoadingNetworkResponse(resultData);
              } else {
                return makeLoadingNetworkResponse<MergedResponse<Map>>(null);
              }
            },
            whenFailed: (error) =>
              makeFailedNetworkResponse(error) as NetworkResponse<
                MergedResponse<Map>
              >,
            whenSuccessful: (data) => {
              resultData[key as keyof Map] = data;
              return makeSuccessfulNetworkResponse(resultData);
            }
          })
      }),
    makeSuccessfulNetworkResponse({} as MergedResponse<Map>)
  );
}

export function useCommand<I extends Record<string, any> | void, O>(
  request: NetworkRequest
) {
  const [response, setResponse] = useState<NetworkResponse<O>>(
    makeIdleNetworkResponse()
  );

  const sendRequest = (input?: I | undefined): Promise<O> => {
    setResponse((response) => {
      if (response.type === 'successful') {
        return makeLoadingNetworkResponse(response.data);
      } else {
        return makeLoadingNetworkResponse(null);
      }
    });

    return sendNetworkRequest<I, O>(request, input).then(
      (response) => {
        setResponse(makeSuccessfulNetworkResponse(response));
        return Promise.resolve(response);
      },
      (error) => {
        setResponse(makeFailedNetworkResponse(error));
        return Promise.reject(error);
      }
    );
  };

  return { response, sendRequest };
}

interface QueryInput<I extends Record<string, any>> {
  value: I;
  eq: (currentInput: I, nextInput: I) => boolean;
}

interface QueryReturnType<I, O> {
  response: NetworkResponse<O>;
  retry: (input?: I | undefined) => void;
}

interface QueryStateReturnType<I, O> {
  response: NetworkResponse<O>;
  retry: (input?: I | undefined) => void;
  setResponse: (response: O) => void;
}

export function useQuery<O>(request: NetworkRequest): QueryReturnType<void, O>;
export function useQuery<
  I extends Record<string, any>,
  O,
  Q extends QueryInput<I> | void = QueryInput<I>
>(request: NetworkRequest, input: Q): QueryReturnType<I, O>;
export function useQuery<
  I extends Record<string, any>,
  O,
  Q extends QueryInput<I> | void = QueryInput<I>
>(request: NetworkRequest, input?: Q | undefined) {
  const [response, setResponse] = useState<NetworkResponse<O>>(
    makeIdleNetworkResponse()
  );

  const [requestState, setRequestState] = useState({
    path: request.path,
    input: input?.value ?? null
  });

  const sendRequest = (input?: I | undefined): void => {
    setResponse((response) => {
      if (response.type === 'successful') {
        return makeLoadingNetworkResponse(response.data);
      } else {
        return makeLoadingNetworkResponse(null);
      }
    });

    setRequestState({
      path: request.path,
      input: input || null
    });

    sendNetworkRequest<I, O>(request, input).then(
      (response) => {
        setResponse(makeSuccessfulNetworkResponse(response));
      },
      (error) => {
        setResponse(makeFailedNetworkResponse(error));
      }
    );
  };

  if (response.type === 'idle') {
    sendRequest(input?.value);
  }

  const didInputChange =
    input && requestState.input && !input.eq(input.value, requestState.input);

  const didRequestPathChange = request.path !== requestState.path;

  if (response.type !== 'loading' && (didInputChange || didRequestPathChange)) {
    sendRequest(input?.value);
  }

  return { response, retry: sendRequest };
}

export function useQueryState<O>(
  request: NetworkRequest
): QueryStateReturnType<void, O>;
export function useQueryState<
  I extends Record<string, any>,
  O,
  Q extends QueryInput<I> | void = QueryInput<I>
>(request: NetworkRequest, input: Q): QueryStateReturnType<I, O>;
export function useQueryState<
  I extends Record<string, any>,
  O,
  Q extends QueryInput<I> | void = QueryInput<I>
>(request: NetworkRequest, input?: Q | undefined) {
  const [response, setResponse] = useState<NetworkResponse<O>>(
    makeIdleNetworkResponse()
  );

  const [requestState, setRequestState] = useState({
    path: request.path,
    input: input?.value ?? null
  });

  const sendRequest = (input?: I | undefined): void => {
    setResponse((response) => {
      if (response.type === 'successful') {
        return makeLoadingNetworkResponse(response.data);
      } else {
        return makeLoadingNetworkResponse(null);
      }
    });

    setRequestState({
      path: request.path,
      input: input || null
    });

    sendNetworkRequest<I, O>(request, input).then(
      (response) => {
        setResponse(makeSuccessfulNetworkResponse(response));
      },
      (error) => {
        setResponse(makeFailedNetworkResponse(error));
      }
    );
  };

  if (response.type === 'idle') {
    sendRequest(input?.value);
  }

  const didInputChange =
    input && requestState.input && !input.eq(input.value, requestState.input);

  const didRequestPathChange = request.path !== requestState.path;

  if (response.type !== 'loading' && (didInputChange || didRequestPathChange)) {
    sendRequest(input?.value);
  }

  const overrideResponseState = (setStateAction: SetStateAction<O>) => {
    setResponse((response) =>
      mapNetworkResponse(response, (currentResponse) => {
        if (typeof setStateAction === 'function') {
          const setState = setStateAction as (state: O) => O;
          return setState(currentResponse);
        } else {
          return setStateAction;
        }
      })
    );
  };

  return { response, retry: sendRequest, setResponse: overrideResponseState };
}
