import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  legal: {
    backgroundColor: '#fff',
    position: 'static',
    bottom: 0,
    width: '97.6%',
    borderTop: '1px solid #eee',
    padding: '15px',
    overflow: 'hidden',
    textAlign: 'center'
  }
});
const Footer = ({ classes }) => (
  <div className={classes.legal}>
    <div className="copyright">
      © 2020 - 2021{' '}
      <a href="https://bellabona.com/" target="blank">
        Bella &amp; Bona
      </a>
    </div>
  </div>
);

export default withStyles(styles)(Footer);
