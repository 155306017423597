export class Zipper<T> {
  private readonly previous: T[];
  private readonly current: T | null;
  private readonly next: T[];

  private constructor(previous: T[], current: T | null, next: T[]) {
    this.previous = previous;
    this.current = current;
    this.next = next;
  }

  public static fromArray<T>(array: T[]): Zipper<T> {
    return new Zipper([], array[0] ?? null, array.slice(1));
  }

  public static fromState<T>(previous: T[], rest: T[]): Zipper<T> {
    return new Zipper(previous, rest[0] ?? null, rest.slice(1));
  }

  public static fromSelf<T>(self: Zipper<T>) {
    return new Zipper(self.previous, self.current, self.next);
  }

  public hasPrevious(): boolean {
    return this.previous.length > 0;
  }

  public hasNext(): boolean {
    return this.next.length > 0;
  }

  public getCurrent(): T | null {
    return this.current;
  }

  public getPrevious(): T[] {
    return this.previous;
  }

  public getNext(): T[] {
    return this.next;
  }

  public shift(): Zipper<T> {
    if (this.current === null && this.next.length === 0) {
      return this;
    } else if (this.current === null) {
      return new Zipper([], this.next[0] ?? null, this.next.slice(1));
    } else {
      return new Zipper(
        [...this.previous, this.current],
        this.next[0] ?? null,
        this.next.slice(1)
      );
    }
  }

  public unshift(): Zipper<T> {
    if (this.current !== null) {
      return new Zipper(
        this.previous.slice(0, this.previous.length - 1),
        this.previous[this.previous.length - 1] ?? null,
        [this.current, ...this.next]
      );
    } else {
      return this;
    }
  }

  public map<A>(mapFn: (t: T) => A): Zipper<A> {
    return new Zipper(
      this.previous.map(mapFn),
      this.current === null ? null : mapFn(this.current),
      this.next.map(mapFn)
    );
  }

  public mapCurrent<A extends T>(mapFn: (t: T) => A): Zipper<T> {
    return new Zipper(
      this.previous,
      this.current === null ? null : mapFn(this.current),
      this.next
    );
  }

  public toArray(): T[] {
    return [
      ...this.previous,
      ...(this.current !== null ? [this.current] : []),
      ...this.next
    ];
  }
}
