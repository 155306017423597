import React from 'react';
import BranchCardHeader from './BranchCardHeader';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import BoxListItem from './BoxListItem';
import {
  DispatchOperation,
  DispatcherBox,
  DispatcherBranch,
  DispatcherDish
} from './domain';
import { UpdatingItems } from './Dispatcher';

interface Props {
  classes: ClassNameMap;
  index: number;
  branch: DispatcherBranch;
  deliveryDate: Date;
  updatingItems: UpdatingItems;
  onDispatchButtonClick: (operation: DispatchOperation) => void;
  onEditBoxDetailsButtonClick: (box: DispatcherBox) => void;
  onPackBoxButtonClick: (
    box: DispatcherBox,
    operation: DispatchOperation
  ) => void;
  onPackDishButtonClick: (
    boxUniqueIdentifier: string,
    dish: DispatcherDish,
    operation: DispatchOperation
  ) => void;
  onStartScanningButtonClick: () => void;
}

export default function BranchCard(props: Props) {
  return (
    <div className={props.classes.companyCard}>
      <BranchCardHeader
        classes={props.classes}
        branch={props.branch}
        index={props.index}
        deliveryDate={props.deliveryDate}
        onDispatchButtonClick={props.onDispatchButtonClick}
        isUpdating={props.updatingItems.branchIds.includes(props.branch.id)}
        onStartScanningButtonClick={props.onStartScanningButtonClick}
      />
      {props.branch.deliveryDriverName ? (
        <div
          style={{
            marginLeft: '10px',
            marginBottom: '10px',
            fontSize: '17px'
          }}
        >
          Driver Name:{' '}
          <span
            style={{
              fontWeight: 'bold',
              fontSize: '17px'
            }}
          >
            {props.branch.deliveryDriverName}
          </span>
        </div>
      ) : null}
      <div className={props.classes.listSection}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <b>DISHES</b>
        </div>
        {props.branch.boxes.map((box, index) => (
          <BoxListItem
            key={box.uniqueIdentifier}
            classes={props.classes}
            box={box}
            index={index}
            onEditBoxInfoButtonClick={() =>
              props.onEditBoxDetailsButtonClick(box)
            }
            onPackBoxButtonClick={(operation) =>
              props.onPackBoxButtonClick(box, operation)
            }
            onPackDishButtonClick={(dish, operation) =>
              props.onPackDishButtonClick(box.uniqueIdentifier, dish, operation)
            }
            isLoading={props.updatingItems.boxUniqueIdentifiers.includes(
              box.uniqueIdentifier
            )}
            updatingDishes={props.updatingItems.dishesInBoxes}
          />
        ))}
      </div>
    </div>
  );
}
