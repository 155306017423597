import React from 'react';
import MaterialTable, { Column } from 'material-table';
import { OrderForecastData } from './OrderForecast';
import * as uiParams from '../common/uiParams';
import { ForecastEntry } from '../../api/kitchen';

interface Props {
  selectedDate: Date;
  data: OrderForecastData;
  onUpdate: () => void;
}

type TableRow = {
  id: string;
  name: string;
  itemNo: string;
  stock: number;
  category: string;
} & Record<string, ForecastEntry[]>;

export default function ForecastTableWeekly(props: Props) {
  const monday = new Date(
    Date.UTC(
      props.selectedDate.getFullYear(),
      props.selectedDate.getMonth(),
      props.selectedDate.getDate() - props.selectedDate.getDay() + 1
    )
  );

  const week = new Array(5)
    .fill(null)
    .map(
      (_, index) => new Date(monday.getTime() + 1000 * 60 * 60 * 24 * index)
    );

  const columns: Column<TableRow>[] = [
    { title: 'Dish Name', field: 'name', editable: 'never' },
    { title: 'Stock', field: 'stock' },
    { title: 'Item Number', field: 'itemNo', editable: 'never' },
    ...week.map<Column<TableRow>>((day) => {
      const dateString = day.toLocaleDateString('en-US', {
        weekday: 'short',
        month: 'short',
        day: '2-digit'
      });

      const dayName = dateString.slice(0, 3);

      return {
        title: dateString,
        field: dayName,
        type: 'numeric',
        editable: 'never',
        render: (rowData) => {
          const totalQuantity =
            rowData[dayName]?.reduce((sum, entry) => sum + entry.quantity, 0) ??
            0;

          return (
            <div
              style={{
                margin: 'auto',
                padding: '3px 10px',
                width: 'fit-content',
                justifyContent: 'center',
                borderRadius: '5px',
                backgroundColor:
                  totalQuantity > 0 ? uiParams.GREEN : 'transparent'
              }}
            >
              <span>
                {typeof rowData[dayName] === 'undefined' ? '-' : totalQuantity}
              </span>
            </div>
          );
        }
      };
    })
  ];

  const mainDishes = props.data.menu
    .filter((recipe) => recipe.cat !== 'AD')
    .sort((a, b) => a.name.localeCompare(b.name));

  const addOns = props.data.menu
    .filter((recipe) => recipe.cat === 'AD')
    .sort((a, b) => a.name.localeCompare(b.name));

  const tableData = [...mainDishes, ...addOns].map((dish) => {
    const forecastedWeekData = Object.fromEntries(
      week.map((date) => {
        const dayName = date.toLocaleDateString('en-US', {
          weekday: 'short'
        });

        const dateString = date.toISOString().slice(0, 10);

        const dishForecastEntries = props.data.forecastData.filter(
          (entry) =>
            entry.itemNo.includes(dish.itemNo) &&
            entry.deliveryDate === dateString
        );

        return [dayName, dishForecastEntries];
      })
    );

    return {
      id: dish.id,
      name: dish.name,
      itemNo: dish.itemNo,
      stock: dish.stock,
      category: dish.categoryName,
      ...forecastedWeekData
    } as TableRow;
  });

  return (
    <MaterialTable
      title="Stocks & Forecasted Orders"
      columns={columns}
      data={tableData}
      options={{ paging: false }}
    />
  );
}
