import React, { useState } from 'react';
import PrintIcon from '@material-ui/icons/Print';
import { Theme, withStyles } from '@material-ui/core/styles';
import ComponentToPrint, { PrintComponentVisibility } from './ComponentToPrint';
import { Tooltip } from '../../components/common/Tooltip/Tooltip';
import { ClassNameMap, Styles } from '@material-ui/core/styles/withStyles';
import { DispatcherBranch } from './domain';

interface CommonProps {
  classes: ClassNameMap;
  deliveryDate: Date;
}

interface MultipleOrdersPrintContainerProps extends CommonProps {
  type: 'multipleOrders';
  branches: DispatcherBranch[];
}

interface SingleOrderPrintContainerProps extends CommonProps {
  type: 'singleOrder';
  branch: DispatcherBranch;
}

type Props = MultipleOrdersPrintContainerProps | SingleOrderPrintContainerProps;

function DispatcherPrintContainer(props: Props) {
  const [printComponentsVisibility, setPrintComponentsVisibility] =
    useState<PrintComponentVisibility>('hidden');

  const tooltipText = (() => {
    switch (props.type) {
      case 'multipleOrders':
        return 'Print All';
      case 'singleOrder':
        return 'Print';
    }
  })();

  return (
    <div>
      <div className={props.classes.hide}>
        {(() => {
          switch (printComponentsVisibility) {
            case 'hidden':
              return null;
            case 'visible':
              switch (props.type) {
                case 'multipleOrders':
                  return (
                    <ComponentToPrint
                      type="multipleOrders"
                      branches={props.branches}
                      printStyle={props.classes.pri}
                      deliveryDate={props.deliveryDate}
                      onClose={() => setPrintComponentsVisibility('hidden')}
                    />
                  );
                case 'singleOrder':
                  return (
                    <ComponentToPrint
                      type="singleOrder"
                      branch={props.branch}
                      printStyle={props.classes.pri}
                      deliveryDate={props.deliveryDate}
                      onClose={() => setPrintComponentsVisibility('hidden')}
                    />
                  );
              }
          }
        })()}
      </div>
      <Tooltip show tooltipText={tooltipText}>
        <PrintIcon
          onClick={() => setPrintComponentsVisibility('visible')}
          className={props.classes.printIcon}
        />
      </Tooltip>
    </div>
  );
}

const styles: Styles<Theme, {}> = {
  printIcon: {
    width: '100%',
    height: '100%'
  },
  hide: {
    display: 'none',
    color: 'red'
  }
};

export default withStyles(styles)(DispatcherPrintContainer);
