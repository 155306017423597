import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import {
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DateRangeIcon from '@material-ui/icons/DateRange';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToApp from '@material-ui/icons/ExitToApp';
import CssBaseline from '@material-ui/core/CssBaseline';
import HomeIcon from '@material-ui/icons/Home';
import BusinessIcon from '@material-ui/icons/Business';
import WarningIcon from '@material-ui/icons/Warning';
import KitchenIcon from '@material-ui/icons/Kitchen';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import CropFreeIcon from '@material-ui/icons/CropFree';
import BackupKitchenIcon from '@material-ui/icons/RemoveShoppingCart';
import { Block, Equalizer, PostAdd } from '@material-ui/icons';
import logoInvertedColor from '../../assets/homelogo_inv.svg';
import navigationConfig from '../../config/navigation';
import { getUserRole } from '../../utils/jwtUtil';
import { authHeader } from '../../api';
import { API_ENDPOINT } from '../../config/constants';
import * as uiParams from './uiParams';
import { logoutAction } from '../../redux/actions';

const drawerWidth = 210;
const iconsColor = 'white';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 32
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: uiParams.PRIMARY,
    color: iconsColor
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    background:
      'linear-gradient(to bottom, #0E6F37 0%, #288634 50%, #3F9A32 100%)',
    color: iconsColor
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8) + 1
    },
    background:
      'linear-gradient(to bottom, #0E6F37 0%, #288634 50%, #3F9A32 100%)',
    color: iconsColor
  },
  toolbar: {
    display: 'flex',
    padding: theme.spacing(0, 0),
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 0
  },
  hyperLink: {
    textDecoration: 'none',
    color: 'inherit'
  },
  logoStyle: {
    height: '45px',
    width: '258px'
  },
  innerIconsStyle: {
    color: iconsColor,
    margin: '6px', // necessary to align hamburger icon and drawer icons
    width: '24px',
    height: '24px'
  },
  toolbarStyle: {
    backgroundColor: uiParams.PRIMARY,
    display: 'flex'
  },
  alignWeeklyIcon: {
    color: iconsColor,
    marginLeft: '6px',
    marginRight: '24px'
  },
  role: {
    position: 'absolute',
    left: '45%',
    width: '100%',
    display: 'flex',
    padding: '10px',
    textTransform: 'capitalize'
  }
}));

const MainDrawer = ({ location: { pathname }, signOut }) => {
  const [kitchenName, setKitchenName] = useState('');

  useEffect(() => {
    const getUserKitchen = async () => {
      const response = await fetch(`${API_ENDPOINT}/api/v1/kitchen/meta`, {
        method: 'GET',
        ...authHeader()
      });
      const data = await response.json();
      return data.data.kitchenName;
    };

    const getName = async () => {
      const name = await getUserKitchen();
      setKitchenName(name);
    };
    getName();
  }, []);

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const userRole = getUserRole();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const getIconByName = (name) => {
    const WeeklyIcon = () => (
      <DateRangeIcon className={classes.alignWeeklyIcon}>
        <BusinessIcon className={classes.innerIconsStyle} />
      </DateRangeIcon>
    );
    const iconList = {
      home: <HomeIcon className={classes.innerIconsStyle} />,
      cancel: <Block className={classes.innerIconsStyle} />,
      kitchen: <KitchenIcon className={classes.innerIconsStyle} />,
      cateringkitchen: <FastfoodIcon className={classes.innerIconsStyle} />,
      addons: <PostAdd className={classes.innerIconsStyle} />,
      dispatcher: <BusinessIcon className={classes.innerIconsStyle} />,
      warning: <WarningIcon className={classes.innerIconsStyle} />,
      weekly: <WeeklyIcon />,
      orderForecast: <Equalizer className={classes.innerIconsStyle} />,
      kitchenCount: <AcUnitIcon className={classes.innerIconsStyle} />,
      vytalCount: <CropFreeIcon className={classes.innerIconsStyle} />,
      kitchenBackup: <BackupKitchenIcon className={classes.innerIconsStyle} />,
      logout: <ExitToApp className={classes.innerIconsStyle} />
    };
    return iconList[name];
  };

  const handleClick = (path) => {
    if (path === '/auth') {
      signOut();
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar className={classes.toolbarStyle}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}
          >
            <MenuIcon style={{ color: iconsColor }} />
          </IconButton>
          <img src={logoInvertedColor} className={classes.logoStyle} alt="" />
          <div className={classes.role}>
            <h1>{kitchenName} Kitchen Station</h1>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon className={classes.innerIconsStyle} />
            ) : (
              <ChevronLeftIcon className={classes.innerIconsStyle} />
            )}
          </IconButton>
        </div>
        <Divider />

        <MenuList>
          {navigationConfig
            .map(({ path, name, iconName, role }) => {
              if (
                role &&
                role.length > 0 &&
                (!userRole || (userRole && role.indexOf(userRole) === -1))
              ) {
                return null;
              }

              return (
                <Link key={name} to={path} className={classes.hyperLink}>
                  <MenuItem
                    button
                    selected={pathname === { path }}
                    onClick={() => handleClick(path)}
                  >
                    <ListItemIcon>{getIconByName(iconName)}</ListItemIcon>
                    <ListItemText primary={name} />
                  </MenuItem>
                </Link>
              );
            })
            .filter((item) => item !== null)}
        </MenuList>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  logoutSuccess: state.auth.logoutSuccess
});

const mapDispatchToProps = {
  signOut: logoutAction
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(MainDrawer);
