/* eslint-disable array-callback-return */
import { DispatcherBranch } from './domain';

function sortByDriverName(a: DispatcherBranch, b: DispatcherBranch): number {
  if (a.deliveryDriverName === null && b.deliveryDriverName === null) {
    return 0;
  } else if (a.deliveryDriverName === null) {
    return 1;
  } else if (b.deliveryDriverName === null) {
    return -1;
  } else {
    if (
      a.deliveryDriverName === 'Roberto' &&
      b.deliveryDriverName === 'Roberto'
    ) {
      return 0;
    } else if (a.deliveryDriverName === 'Roberto') {
      return -1;
    } else if (b.deliveryDriverName === 'Roberto') {
      return 1;
    } else {
      return a.deliveryDriverName.localeCompare(b.deliveryDriverName);
    }
  }
}

export function sortBranches(a: DispatcherBranch, b: DispatcherBranch): number {
  const driverNameComparison = sortByDriverName(a, b);

  if (driverNameComparison !== 0) {
    return driverNameComparison;
  } else {
    const shippingTimeComparison = a.leaveTime.localeCompare(b.leaveTime);

    if (shippingTimeComparison !== 0) {
      return shippingTimeComparison;
    } else {
      return a.name.localeCompare(b.name);
    }
  }
}

export function paginateList<T>(list: T[], itemsPerPage: number): T[][] {
  const result = [];

  for (let i = 0; i < list.length; i += itemsPerPage) {
    result.push(list.slice(i, i + itemsPerPage));
  }

  return result;
}
