// AUTH
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

// ORDERS
export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST';
export const GET_CANCELLED_ORDERS_REQUEST = 'GET_CANCELLED_ORDERS_REQUEST';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_CANCELLED_ORDERS_SUCCESS = 'GET_CANCELLED_ORDERS_SUCCESS';
export const GET_CANCELLED_ORDERS_FAILURE = 'GET_CANCELLED_ORDERS_FAILURE';
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE';
export const ADD_ORDER = 'ADD_ORDER';
export const ADD_UPDATE = 'ADD_UPDATE';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const GET_UPDATES_REQUEST = 'GET_UPDATES_REQUEST';
export const GET_UPDATES_SUCCESS = 'GET_UPDATES_SUCCESS';
export const GET_UPDATES_FAILURE = 'GET_UPDATES_FAILURE';
export const REMOVE_UPDATE_REQUEST = 'REMOVE_UPDATE_REQUEST';

// DISPATCHER
export const GET_COMPANIES_REQUEST = 'GET_COMPANIES_REQUEST';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAILURE = 'GET_COMPANIES_FAILURE';
export const SEARCH_COMPANY = 'SEARCH_COMPANY';

// KITCHEN
export const DISHES_REQUEST = 'DISHES_REQUEST';
export const DISHES_SUCCESS = 'DISHES_SUCCESS';
export const DISHES_FAILURE = 'DISHES_FAILURE';

export const DISHES_PATCH = 'DISHES_PATCH';
export const DISHES_PATCH_SUCCESS = 'DISHES_PATCH_SUCCESS';
export const DISHES_PATCH_FAILURE = 'DISHES_PATCH_FAILURE';
