import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface Props {
  dateChangeHandler: (date: Date) => void;
  showDate: Date;
  showDateInPicker: boolean;
}

export default function DatePicker(props: Props) {
  const handleChangeDate = (selectedDate: MaterialUiPickersDate) => {
    if (selectedDate) {
      props.dateChangeHandler(selectedDate);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoOk
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        format="dd/MM/yyyy"
        margin="dense"
        id="date-picker-inline"
        label="Choose Day"
        value={props.showDateInPicker ? props.showDate : null}
        onChange={handleChangeDate}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        InputProps={{ readOnly: true }}
        InputAdornmentProps={{ position: 'start' }}
      />
    </MuiPickersUtilsProvider>
  );
}
