import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Theme,
  withStyles
} from '@material-ui/core';
import { CheckCircleRounded, QueryBuilderRounded } from '@material-ui/icons';
import HotPlate from '../../assets/plate.svg';
import { ClassNameMap, Styles } from '@material-ui/core/styles/withStyles';

interface Props {
  open: boolean;
  onClose: (
    event: React.SyntheticEvent,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => void;
  classes: ClassNameMap;
}

function DispatcherLegend(props: Props) {
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="md">
      <DialogTitle id="dispatcherDialog">Dispatcher Legend</DialogTitle>
      <DialogContent>
        <div>
          <p style={{ fontWeight: 'bold' }}>Box Config</p>
          <p>Big: 24</p>
          <p>Medium: Hot Dishes - 6, Cold Dishes - 12</p>
          <p>Small: 4</p>
        </div>
        <div>
          <div
            style={{ display: 'flex', alignItems: 'center', margin: '10px' }}
          >
            <QueryBuilderRounded
              className={props.classes.icon}
              style={{ fill: 'red' }}
            />
            <span>Preparing in Kitchen</span>
          </div>
          <div
            style={{ display: 'flex', alignItems: 'center', margin: '10px' }}
          >
            <CheckCircleRounded
              className={props.classes.icon}
              style={{ fill: 'green' }}
            />
            <span>Completed in kitchen</span>
          </div>
          <div
            style={{ display: 'flex', alignItems: 'center', margin: '10px' }}
          >
            <div className={props.classes.iconWrapper}>
              <img
                src={HotPlate}
                className={props.classes.plateIcon}
                alt="Hot Plate"
              />
            </div>
            <span>Hot plate added to box</span>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const styles: Styles<Theme, {}> = {
  iconWrapper: {
    width: '30px',
    height: '30px',
    padding: '2px',
    borderRadius: '3px',
    cursor: 'pointer',
    backgroundColor: '#f47b50',
    marginRight: '10px'
  },
  plateIcon: {
    width: '25px',
    height: '25px',
    color: 'white'
  },
  icon: {
    marginRight: '10px'
  }
};

export default withStyles(styles)(DispatcherLegend);
