import React, { CSSProperties } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import DispatcherPrintContainer from './DispatcherPrintContainer';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { DispatchOperation, DispatcherBranch } from './domain';
import * as uiParams from '../common/uiParams';

interface Props {
  classes: ClassNameMap;
  branch: DispatcherBranch;
  deliveryDate: Date;
  index: number;
  isUpdating: boolean;
  onDispatchButtonClick: (operation: DispatchOperation) => void;
  onStartScanningButtonClick: () => void;
}

export default function BranchCardHeader(props: Props) {
  const now = new Date();

  const todayUTC = new Date(
    Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
  );

  const isToday =
    props.deliveryDate.toISOString().slice(0, 10) ===
    todayUTC.toISOString().slice(0, 10);

  const totalDishesCount = props.branch.boxes.reduce(
    (sum, box) =>
      sum +
      box.dishes.reduce(
        (sum, dish) =>
          sum + dish.users.reduce((sum, user) => sum + user.orderedQuantity, 0),
        0
      ),
    0
  );

  const totalMainDishesCount = props.branch.boxes.reduce(
    (sum, box) =>
      sum +
      box.dishes
        .filter((dish) => dish.label !== 'addons')
        .reduce(
          (sum, dish) =>
            sum +
            dish.users.reduce((sum, user) => sum + user.orderedQuantity, 0),
          0
        ),
    0
  );

  const totalBowlCodesCount = props.branch.boxes
    .flatMap((box) => box.dishes)
    .reduce((sum, dish) => sum + dish.bowlCodes.length, 0);

  const scansCountElementStyle: CSSProperties =
    totalBowlCodesCount < totalMainDishesCount
      ? {
          color: uiParams.DARK_RED
        }
      : {
          color: uiParams.BLACK
        };

  const hotBoxesCount = props.branch.boxes.filter(
    (box) => box.type === 'hot'
  ).length;

  const coldBoxesCount = props.branch.boxes.filter(
    (box) => box.type === 'cold'
  ).length;

  return (
    <>
      {props.isUpdating ? (
        <div className={props.classes.overlayLoaderContainer}>
          <CircularProgress />
        </div>
      ) : null}
      {props.branch.isDispatched ? (
        <div className={props.classes.greyOverlay} />
      ) : null}
      <div className={props.classes.companyHeader}>
        <div className={props.classes.companyName}>{props.branch.name}</div>
        <div className={props.classes.print}>
          <DispatcherPrintContainer
            type="singleOrder"
            branch={props.branch}
            deliveryDate={props.deliveryDate}
          />
        </div>
      </div>
      <div className={props.classes.totalQuantity}>
        <p>Total Quantity: {totalDishesCount}</p>
        <p>Total main dishes: {totalMainDishesCount}</p>
        <p style={scansCountElementStyle}>Total scans: {totalBowlCodesCount}</p>
      </div>
      <Button
        className={props.classes.scanButton}
        onClick={() => props.onStartScanningButtonClick()}
        disabled={!isToday}
      >
        Start scanning
      </Button>
      <div className={props.classes.boxSection}>
        {hotBoxesCount > 0 && coldBoxesCount > 0 ? (
          <ul className={props.classes.listStyleUlBox}>
            {hotBoxesCount > 0 ? (
              <li className={props.classes.listStyleBox}>
                Hot boxes: {hotBoxesCount}
              </li>
            ) : null}
            {coldBoxesCount > 0 ? (
              <li className={props.classes.listStyleBox}>
                Cold boxes: {coldBoxesCount}
              </li>
            ) : null}
          </ul>
        ) : null}
      </div>
      <div className={props.classes.detailSection}>
        <div className={props.classes.timesSection}>
          <div className={props.classes.timeLabel}>
            READY BY
            <div className={props.classes.time}>{props.branch.readyTime}</div>
          </div>
          <div className={props.classes.timeLabel}>
            LEAVING BY
            <div className={props.classes.time}>{props.branch.leaveTime}</div>
          </div>
        </div>
        <div
          className={[
            props.classes.dispatchAllSection,
            props.classes.hoverEffect
          ].join(' ')}
          role="button"
          tabIndex={props.index}
          onClick={() =>
            props.onDispatchButtonClick(
              props.branch.isDispatched ? 'unpack' : 'pack'
            )
          }
        >
          {props.branch.isDispatched ? (
            <SettingsBackupRestoreIcon className={props.classes.bulkMark} />
          ) : (
            <DoneAllIcon className={props.classes.bulkMark} />
          )}
        </div>
      </div>
    </>
  );
}
