import React from 'react';
import { Provider } from 'react-redux';
import './App.css';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { teal } from '@material-ui/core/colors';
import * as Sentry from '@sentry/browser';
import Routes from './Router';
import reduxStore from './redux/store';
import { verifyToken } from './services/tokenService';
import { ENABLE_SENTRY } from './config/constants';
import { Store } from 'redux';

const theme = createTheme({
  typography: {
    fontFamily: 'Nunito'
  },
  palette: {
    primary: teal
  },
  overrides: {
    MuiMenuItem: {
      root: {
        '&$selected': {
          backgroundColor: '#136d32'
        }
      }
    }
  }
});

if (ENABLE_SENTRY === '1') {
  Sentry.init({
    dsn: 'https://27b7bfde75e74437a1be0ec6487b0d86@o393785.ingest.sentry.io/5247665'
  });
}

reduxStore.dispatch(verifyToken);

interface Props {
  store: Store;
}

export default function App(props: Props) {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={props.store}>
        <Routes />
      </Provider>
    </MuiThemeProvider>
  );
}
