// THEME
export const PRIMARY = '#0E6F37';
export const SECONDARY = '#3F9A32';
export const GRADIENT_SEC = '#619239';
export const BG_THEME = '#F5F5F5';
export const GREY1 = '#757575';
export const GREY2 = '#9e9e9e';
export const GREY3 = '#e0e0e0';
export const GREY4 = '#f3f3f3';
export const BLACK = '#212121';
export const WHITE = '#fff';
export const THEME_WHITE = '#bfc5d2';
export const THEME_GREEN_TEXT = '#2e4d40';
export const DARK_RED = '#ce2b37';
export const BLUE = '#A5F2F3';

// TEXT FIELDS:
export const BG_TEXT_FLD = '#F2F2F2';
export const COLOR_TEXT_FLD = '#FFF';
export const COLOR_PLACEHOLDER = '#A7A7A7';

// CARDS
export const BG_CARD = '#FFF';
export const COLOR_TXT_CARD = '#000';
export const COLOR_BORDER_CARD = '#EBEBEB';

// BUTTONS
export const BG_BTN = '#409A32';
export const COLOR_TXT_BTN = '#FFF';

// DIMENSIONS
export const RAD_GLOBAL = '7px';
export const HORIZONTAL_MARGIN = '32px';
export const VERTICAL_MARGIN = '20px';
export const DISPATCHER_CARD_WIDTH = '360px';
export const DISPATCHER_CARD_HEIGHT = '400px';

export const LANE_WIDTH = 340;
export const LANE_MARGIN = '10px';
export const INTERNAL_TABLE_ROW_HEIGHT = '30px';

// alternate
export const RELEVO = '#ffb300';
export const NORMAL_PKG = '#2D8CFF';

// green
// export const GREEN = '#b6e59f';
export const GREEN = '#d5f0c7';
export const LIGHT_BLUE = '#edf0f6';
export const LIGHT_RED = '#FAE1DC';
