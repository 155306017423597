import MaterialTable from 'material-table';
import moment from 'moment';
import React, { useContext } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import ReactTooltip from 'react-tooltip';
import * as uiParams from '../common/uiParams';
import { USER_SELECTED_DATE } from '../../config/constants';
import { getCustomColumns } from '../../utils/kitchenCount';
import { setLocalStorage } from '../../utils/storageUtils';
import SearchBar from '../common/SearchBar';
import KitchenCountContext from './Context';
import './kitchenCount.css';

export const KitchenCount = () => {
  const { dateState, tableDataState } = useContext(KitchenCountContext);
  const [selectedDate, setSelectedDate] = dateState;
  const [tableData] = tableDataState;

  const onDateChanged = (date) => {
    setSelectedDate(date);
    setLocalStorage(USER_SELECTED_DATE, moment(date));
  };

  return (
    <div className="wrapper">
      <SearchBar
        onPickDate={onDateChanged}
        currentSelectedDate={selectedDate}
        onSearch={() => {}}
        title="Kitchen Orders Count"
      />
      <div style={{ marginLeft: '30px' }}>
        <div
          style={{
            margin: '2px',
            padding: '3px 10px',
            width: 'fit-content',
            justifyContent: 'center',
            borderRadius: '5px'
          }}
        >
          <InfoIcon data-tip data-for="kitchenCountInfo" />
          <ReactTooltip id="kitchenCountInfo" type="light">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  margin: '2px',
                  padding: '3px 10px',
                  width: 'fit-content',
                  justifyContent: 'center',
                  borderRadius: '5px',
                  cursor: 'help'
                }}
              >
                <span>10 </span>
              </div>
              <div>
                <span>:- the sum of actual orders (Dispatcher screen)</span>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  margin: '2px',
                  padding: '3px 10px',
                  width: 'fit-content',
                  justifyContent: 'center',
                  borderRadius: '5px',
                  backgroundColor: uiParams.GREEN
                }}
              >
                <span>15 </span>
              </div>
              <div>
                <span>
                  :- the sum of actual orders and forecasted orders (Kitchen
                  screen)
                </span>
              </div>
            </div>
          </ReactTooltip>
        </div>
      </div>

      <div className="screen_content">
        <MaterialTable
          title="Total Orders"
          columns={getCustomColumns(selectedDate)}
          data={tableData}
          options={{ paging: false }}
          onSelectionChange={() => {}}
        />
      </div>
    </div>
  );
};

export default KitchenCount;
