import React from 'react';
import { createRoot } from 'react-dom/client';
import { createStore, applyMiddleware } from 'redux';
import './fonts/Nunito-Regular.ttf';
import './index.css';
import thunk from 'redux-thunk';
import App from './App';
import * as serviceWorker from './serviceWorker';
import rootReducer from './redux/reducers';

const initialState = {};
const store = createStore(rootReducer, initialState, applyMiddleware(thunk));
const container = document.getElementById('root');

if (!container) {
  throw new Error('#app element not found in DOM');
}

const root = createRoot(container);

serviceWorker.registerServiceWorker();

root.render(
  <React.StrictMode>
    <App store={store} />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
