import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
// import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import * as uiParams from '../common/uiParams';
import SearchBar from '../common/SearchBar';
// import DatePicker from '../common/datePicker';
import { getLocalStorage, setLocalStorage } from '../../utils/storageUtils';
import {
  DELIVERY_DETAILS,
  DELIVERY_FETCH_FAILED,
  USER_SELECTED_DATE,
  WEEKLY_ACTIONS_KEY,
  UNDO_ACTIONS_KEY,
  REDO_ACTIONS_KEY
} from '../../config/constants';
import { commonStyles } from '../common/commonStyle';
import { DeliveryGroup } from './helper/DeliveryGroup';
import { getDeliveryByDate } from '../../api';
import { transform } from './helper/transform';
import { search } from '../../utils/search';
import CustomSnackbar from '../common/CustomSnackbar';
import ls from '../../utils/common/sessionStorageHelper';

const Weekly = ({ classes }) => {
  const [showSnack, setShowSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [selectedDate, setSelectedDate] = useState();
  const [driverUpdated, setDriverUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deliveries, setDeliveries] = useState();
  const [filteredDeliveries, setFilteredDeliveries] = useState();
  const [searchWord, setSearchWord] = useState('');
  const [undoEnabled, setUndoEnabled] = useState(false);
  const [redoEnabled, setRedoEnabled] = useState(false);

  const getDeliveriesByDate = async (pDate) => {
    await setLoading(true);
    setShowSnack(false);
    const weekStart = moment(pDate).startOf('week').valueOf();
    const weekEnd = moment(pDate).endOf('week').valueOf();
    getDeliveryByDate(weekStart, weekEnd)
      .then((tmpDeliveries) => {
        setFilteredDeliveries(null);
        setDeliveries(
          tmpDeliveries.deliveryData && tmpDeliveries.deliveryData.length > 0
            ? transform(tmpDeliveries.deliveryData)
            : null
        );
      })
      .catch(() => {
        setSnackMessage(DELIVERY_FETCH_FAILED);
        setShowSnack(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!selectedDate) {
      let globalSelectedDate = getLocalStorage(USER_SELECTED_DATE);
      if (!globalSelectedDate) {
        globalSelectedDate = moment();
      }
      getDeliveriesByDate(globalSelectedDate);
      setSelectedDate(globalSelectedDate);
    } else {
      getDeliveriesByDate(selectedDate);
    }
    setDriverUpdated(false);
  }, [selectedDate, driverUpdated]);

  useEffect(() => {
    const undoActions = ls.get(UNDO_ACTIONS_KEY);
    if (
      undoActions &&
      undoActions[WEEKLY_ACTIONS_KEY][DELIVERY_DETAILS].length > 0
    ) {
      setUndoEnabled(true);
    }
  }, [undoEnabled]);

  useEffect(() => {
    const redoActions = ls.get(REDO_ACTIONS_KEY);
    if (
      redoActions &&
      redoActions[WEEKLY_ACTIONS_KEY][DELIVERY_DETAILS].length > 0
    ) {
      setRedoEnabled(true);
    }
  }, [redoEnabled]);

  const onSearch = (word) => {
    if (!deliveries || deliveries.size === 0) {
      return;
    }
    setSearchWord(word);
    const matchingDeliveries = new Map();
    deliveries.forEach((deliveryArray, key) => {
      deliveryArray.forEach((value) => {
        if (search(word, value)) {
          let matchingDeliveryArray = matchingDeliveries.get(key);
          if (!matchingDeliveryArray) {
            matchingDeliveryArray = [];
          }
          matchingDeliveryArray.push(value);
          matchingDeliveries.set(key, matchingDeliveryArray);
        }
      });
    });
    setFilteredDeliveries(matchingDeliveries);
  };

  const onDateChanged = async (date) => {
    setSelectedDate(date);
    setLocalStorage(USER_SELECTED_DATE, moment(date));
  };

  const gridTitles = {
    day: 'DAY',
    orderType: 'TYPE',
    companyName: 'COMPANY',
    readyFor: 'READY',
    deliveryTime: 'DELIVERY',
    driver: 'DRIVER',
    address: 'ADDRESS',
    status: 'STATUS'
  };

  const renderDeliveryGroup = () => (
    <DeliveryGroup
      deliveries={searchWord ? filteredDeliveries : deliveries}
      commonClasses={classes}
      driverUpdated={setDriverUpdated}
      enableUndo={setUndoEnabled}
      enableRedo={setRedoEnabled}
    />
  );

  const handleCloseSnack = () => {
    setShowSnack(false);
  };

  return (
    <div className={classes.wrapper}>
      <CustomSnackbar
        visible={showSnack}
        message={snackMessage}
        handleClose={handleCloseSnack}
      />

      <SearchBar
        onSearch={onSearch}
        getting={loading}
        onPickDate={onDateChanged}
        currentSelectedDate={selectedDate}
        title="Weekly Overview"
      />
      <div className={classes.container}>
        <div className={classes.titles}>
          <div className={classes.titleDay}>{gridTitles.day}</div>
          <div
            className={[classes.titleField, classes.dataOrderType].join(' ')}
          >
            {gridTitles.orderType}
          </div>
          <div
            className={[classes.titleField, classes.dataCompanyName].join(' ')}
          >
            {gridTitles.companyName}
          </div>
          <div className={[classes.titleField, classes.dataReadyFor].join(' ')}>
            {gridTitles.readyFor}
          </div>
          <div
            className={[classes.titleField, classes.dataDeliveryTime].join(' ')}
          >
            {gridTitles.deliveryTime}
          </div>
          <div className={[classes.titleField, classes.dataDriver].join(' ')}>
            {gridTitles.driver}
          </div>
          <div className={[classes.titleField, classes.dataAddress].join(' ')}>
            {gridTitles.address}
          </div>
          <div
            className={[classes.titleField, classes.orderStatusTitle].join(' ')}
          >
            {gridTitles.status}
          </div>
        </div>
        {renderDeliveryGroup()}
      </div>
    </div>
  );
};

const styles = {
  ...commonStyles,
  titles: {
    height: '40px',
    width: '100%',
    backgroundColor: uiParams.WHITE,
    display: 'flex',
    alignItems: 'center'
  },
  titleDay: {
    display: 'inline-block',
    marginLeft: '40px',
    marginRight: '12px',
    width: '180px',
    textAlign: 'center',
    fontSize: '16px',
    letterSpacing: '2.1px',
    color: uiParams.THEME_WHITE
  },
  titleField: {
    display: 'inline-block',
    color: uiParams.THEME_WHITE,
    textAlign: 'left',
    fontSize: '16px',
    letterSpacing: '2.1px'
  },
  dataOrderType: {
    display: 'inline-block',
    width: '60px',
    textAlign: 'center'
  },
  dataCompanyName: {
    display: 'inline-block',
    width: '240px'
  },
  dataReadyFor: {
    display: 'inline-block',
    width: '80px'
  },
  dataDeliveryTime: {
    display: 'inline-block',
    width: '120px'
  },
  dataDriver: {
    display: 'inline-block',
    width: '160px',
    marginTop: '1px'
  },
  orderStatusTitle: {
    display: 'inline-block',
    textAlign: 'center',
    width: '160px',
    marginTop: '1px',
    marginLeft: '60px'
  },
  orderStatus: {
    display: 'inline-block',
    textAlign: 'right',
    width: '160px',
    marginTop: '1px',
    marginLeft: '10px',
    textTransform: 'uppercase'
  },
  doneAllIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '60px',
    cursor: 'pointer'
  },
  dataAddress: {
    display: 'inline-block',
    minWidth: '180px'
  }
};

export default withStyles(styles)(Weekly);
