import moment from 'moment';
import React from 'react';
import { weekData } from '../../config/constants';
import * as uiParams from '../../scenes/common/uiParams';

export const getCustomColumns = (selectedDate) => {
  const columns = [
    { title: 'Dish Name', field: 'name', editable: 'never' },
    { title: 'Item Number', field: 'itemNo', editable: 'never' }
  ];
  weekData.map((d) => {
    const day = moment(selectedDate).startOf('isoWeek');
    return columns.push({
      title: `${d.name} (${day.add(d.id, 'day').format('YYYY-MM-DD')})`,
      field: d.name,
      cellStyle: (cellValue) => {
        if (cellValue.isToday) {
          return { backgroundColor: uiParams.GREY4 };
        }
        return { backgroundColor: 'transparent' };
      },
      render: (rowData) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{
              margin: '2px',
              padding: '3px 10px',
              width: 'fit-content',
              justifyContent: 'center',
              borderRadius: '5px'
            }}
          >
            <span>{rowData?.[d?.name]?.actualOrderQuantity}</span>
          </div>
          <div
            style={{
              margin: '2px',
              padding: '3px 10px',
              width: 'fit-content',
              justifyContent: 'center',
              borderRadius: '5px',
              backgroundColor: uiParams.GREEN
            }}
          >
            <span>
              {rowData?.[d?.name]?.actualOrderQuantity +
                rowData?.[d?.name]?.quantity}
            </span>
          </div>
        </div>
      ),
      type: 'numeric'
    });
  });
  return columns;
};
