import moment from 'moment';
import { API_ENDPOINT } from '../config/constants';
import { authHeader } from './auth';
import { handleResponse } from './index';

export const getKitchenMeta = async () => {
  const options = {
    method: 'GET',
    ...authHeader()
  };

  return window
    .fetch(`${API_ENDPOINT}/api/v1/kitchen/meta`, options)
    .then(handleResponse)
    .then((res) => res.data);
};

type BowlType = 'round' | 'compartment' | null;

export interface Order {
  cat: string;
  bowlType: BowlType;
  category: string;
  completed: number;
  id: string;
  inKitchen: number;
  inProcessing: number;
  itemNo: string;
  name: string;
  num?: string | undefined;
  orderId?: string | undefined;
  packageOption: 'mono' | 'reusable';
  productId: string;
  quantity: number;
  readyBy: number;
  recipe: string[] | null;
  type: 'hot' | 'cold';
  updatedAt: string;
  webUrl: string;
}

export interface KitchenOrders {
  dishes: Order[];
}

export async function getDishesByDate(
  date: Date,
  addonGroup?: string | undefined
): Promise<KitchenOrders> {
  const options = {
    method: 'GET',
    ...authHeader()
  };

  const dayStart = moment(date).startOf('day').valueOf();
  const dayEnd = moment(date).endOf('day').valueOf();

  const response = await fetch(
    `${API_ENDPOINT}/api/v1/kitchen/orders/dishes?deliveryFromDate=${dayStart}&deliveryToDate=${dayEnd}&limit=500&page=1${
      addonGroup ? `&addonGroup=${addonGroup}` : ''
    }`,
    options
  );

  const orders = await handleResponse<KitchenOrders>(response);
  return orders.data;
}

export const getDishesOrdersForWeek = async (date: any) => {
  const options = {
    method: 'GET',
    ...authHeader()
  };

  const startOfWeek = moment(date).startOf('week').valueOf();
  const endOfWeek = moment(date).endOf('week').valueOf();

  return fetch(
    `${API_ENDPOINT}/api/v1/kitchen/orders/dishes?deliveryFromDate=${startOfWeek}&deliveryToDate=${endOfWeek}&limit=500&page=1`,
    options
  )
    .then(handleResponse)
    .then((res) => res.data);
};

export const getStickerData = async (date: any) => {
  const options = {
    method: 'GET',
    ...authHeader()
  };
  const dayStart = moment(date).startOf('day').format('YYYY-MM-DD');

  return fetch(
    `${API_ENDPOINT}/api/v1/kitchen/orders/stickersData?deliveryDate=${dayStart}`,
    options
  )
    .then(handleResponse)
    .then((res) => res.data);
};

export interface UpdateDishBoxingData {
  id: string;
  packed: number;
}

export interface UpdateDishKitchenData {
  id: string;
  itemNo: string;
  inProcessing: number;
  inKitchen: number;
  completed: number;
  orderId: string;
  timeRangeId: string;
}

export interface UpdatedForecastEntry extends PackagingLaneData {
  deliveryDate: string;
  itemNo: string;
  timeRangeId: string;
}

export interface UpdateDishBoxingRequest {
  orders: UpdateDishBoxingData[];
}

export type UpdateKitchenDishesRequest = {
  orders: UpdateDishKitchenData[];
  lanesData: UpdatedForecastEntry[];
};

export const updateDish = async (data: UpdateKitchenDishesRequest) => {
  const bodyData = JSON.stringify(data);

  const options = {
    method: 'PATCH',
    ...authHeader(),
    body: bodyData
  };

  return fetch(`${API_ENDPOINT}/api/v1/kitchen/orders/dishes/update`, options)
    .then(handleResponse)
    .then((res) => res.data);
};

export const updateDishPackingStatus = async (
  data: UpdateDishBoxingRequest
) => {
  const bodyData = JSON.stringify(data);

  const options = {
    method: 'PATCH',
    ...authHeader(),
    body: bodyData
  };

  return fetch(
    `${API_ENDPOINT}/api/v1/kitchen/orders/dishes/packing/update`,
    options
  )
    .then(handleResponse)
    .then((res) => res.data);
};

export interface Branch {
  branchId: string;
  branchIndex: number;
}

export interface TiramizooDriverData {
  driverData: { [branchId: string]: string };
}

export interface TiramizooLabelPackageDetails {
  state: string;
  width: number;
  height: number;
  length: number;
  weight: number;
  barcode: {
    url: string;
    data: string;
    symbology: string;
  };
  category: null;
  quantity: number;
  identifier: string;
  unpackable: boolean;
  description: string;
  external_id: string | null;
  non_rotatable: boolean;
}

export interface TiramizooLabel {
  branchId: string;
  branchIndex: number;
  data: {
    labelsUrl: string;
    courierName: string;
    delivery: {
      eta: string;
      city: string;
      name: string;
      after: string;
      email: string;
      before: string;
      information: string | null;
      postal_code: string;
      address_line: string;
      country_code: string;
      phone_number: string;
      requirements: Array<{
        type: string;
        value?: string;
      }>;
      allowed_delay: number;
      contact_identifier: string | null;
    };
    packageDetails: TiramizooLabelPackageDetails[];
  };
}

export interface TiramizooLabelData {
  labelData: TiramizooLabel[];
}

type TiramizooData = TiramizooDriverData | TiramizooLabelData;

export async function getTiramizooData(
  branchIds: Branch[],
  deliveryDate: Date,
  apiId: 'driver'
): Promise<TiramizooDriverData>;
export async function getTiramizooData(
  branchIds: Branch[],
  deliveryDate: Date,
  apiId: 'label'
): Promise<TiramizooLabelData>;
export async function getTiramizooData(
  branchIds: Branch[],
  deliveryDate: Date,
  apiId: 'label' | 'driver' = 'driver'
): Promise<TiramizooData> {
  const bodyData = JSON.stringify({ branchIds, deliveryDate });

  const response = await window.fetch(
    `${API_ENDPOINT}/api/v1/kitchen/orders/tiramizoo/${apiId}/data`,
    {
      method: 'PATCH',
      ...authHeader(),
      body: bodyData
    }
  );

  const tiramizooData = await handleResponse<TiramizooData>(response);
  return tiramizooData.data;
}

export const getTiramizooMockData = async (
  branchIds: any,
  deliveryDate: any,
  apiId = 'driver'
) => {
  const bodyData = JSON.stringify({ branchIds, deliveryDate });
  const options = {
    method: 'PATCH',
    ...authHeader(),
    body: bodyData
  };
  return fetch(
    `${API_ENDPOINT}/api/v1/kitchen/orders/tiramizoo/${apiId}/data/mock`,
    options
  )
    .then(handleResponse)
    .then((res) => res.data);
};

export interface LaneData {
  inProcessing: number;
  inKitchen: number;
  inCompleted: number;
}

export interface PackagingLaneData {
  normalPackaging: LaneData;
  reusablePackaging: LaneData;
}

export interface LanesEntry extends PackagingLaneData {
  id: string;
  deliveryDate: string; // SQL date string
  itemNo: string;
  updatedAt: string;
  kitchenId: string;
  timeRangeId: string;
}

export async function getPackagingLaneData(deliveryDate: string) {
  const response = await fetch(
    `${API_ENDPOINT}/api/v1/kitchen/orders/lanes/${deliveryDate}`,
    {
      method: 'GET',
      ...authHeader()
    }
  );

  const packagingLaneData = await handleResponse<LanesEntry[]>(response);

  return packagingLaneData.data;
}

export const updatePackagingLaneData = async (entries: any) => {
  const requestPayload = JSON.stringify(entries);
  const options = {
    method: 'PUT',
    ...authHeader(),
    body: requestPayload
  };
  return fetch(`${API_ENDPOINT}/api/v1/kitchen/orders/lanes/update`, options)
    .then(handleResponse)
    .then((res) => res.data);
};

export interface MenuEntry {
  colour: string;
  discountPrice: number;
  packaging: 'mono';
  recipeUrl: string | null;
  stock: number;
  num?: string | undefined;
  id: string;
  recipeId: string;
  variantName: string;
  localization: {
    de: {
      description: string;
    };
  };
  webUrl: string;
  sku: string | null;
  isSpecial: 0 | 1;
  initialPrice: number;
  specialPrice: number;
  type: 'hot' | 'cold';
  isActive: 0 | 1;
  diet: string;
  portion: string;
  description: string;
  allergens: string[];
  mobileUrl: string;
  spiciness: 0 | 1;
  station: string;
  cat: string;
  nutritionalinfo: string;
  totalCostofRecipeIngredients: number;
  updatedAt: string;
  createdAt: string;
  premadeIngredients: null;
  deletedAt: string | null;
  tags: Array<string | null>;
  fallbackIngredients: string[];
  fallbackRecipe: string | null;
  itemNo: string;
  nestedIngredients: string[];
  nestedDiets: string[];
  nestedAllergens: string[];
  categoryName: string;
  germanName: string;
  germanDescription: string;
  ingredients: string[];
  marketingName: string;
  name: string;
  nutritional: Record<string, never>;
  recipe: string[] | null;
  bowlType: BowlType;
}

export async function getMenu(deliveryDate: string) {
  const response = await fetch(
    `${API_ENDPOINT}/api/v1/kitchen/orders/menu/${deliveryDate}`,
    {
      method: 'GET',
      ...authHeader()
    }
  );

  const menu = await handleResponse<MenuEntry[]>(response);
  return menu.data;
}

export interface ForecastEntry {
  createdAt: string;
  deliveryDate: string;
  forecastedQuantity: number;
  id: string;
  itemNo: string;
  kitchenId: string;
  quantity: number;
  updatedAt: string;
  timeRangeId: string;
}

export interface DishTableRowDayData {
  deliveryDate: string; // ISO date or "unknown"
  quantity: number;
  totalQuantity: number;
  isDisabled: boolean;
  itemNo?: string | undefined;
  timeRangeId: string;
}

export async function getForecastData(deliveryDate: string) {
  const response = await fetch(
    `${API_ENDPOINT}/api/v1/kitchen/orders/forecasting/${deliveryDate}`,
    {
      method: 'GET',
      ...authHeader()
    }
  );

  const unknown = await handleResponse<ForecastEntry[]>(response);
  return unknown.data;
}

export function createForecastEntry(data: DishTableRowDayData[]) {
  return fetch(`${API_ENDPOINT}/api/v1/kitchen/orders/forecasting`, {
    method: 'POST',
    ...authHeader(),
    body: JSON.stringify(data)
  });
}

export function updateStockValue(data: Array<{ id: string; stock: number }>) {
  return fetch(`${API_ENDPOINT}/api/v1/kitchen/orders/dish/stock`, {
    method: 'PUT',
    ...authHeader(),
    body: JSON.stringify(data)
  });
}

export const notifyKitchenCountMismatch = async (data: any) => {
  const requestPayload = JSON.stringify(data);
  const options = {
    method: 'POST',
    ...authHeader(),
    body: requestPayload
  };
  return fetch(
    `${API_ENDPOINT}/api/v1/kitchen/orders/notifyKitchenCountMismatch`,
    options
  );
};

export const getQrData = async (deliveryDate: any) => {
  const options = {
    method: 'GET',
    ...authHeader()
  };
  return fetch(
    `${API_ENDPOINT}/api/v1/kitchen/orders/qr/data/?date=${deliveryDate}`,
    options
  )
    .then(handleResponse)
    .then((res) => res.data);
};
