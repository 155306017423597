import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { InputAdornment, IconButton, Theme } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import * as uiParams from './uiParams';
import { ClassNameMap, Styles } from '@material-ui/core/styles/withStyles';

interface Props {
  classes: ClassNameMap;
  onTextChanged: (text: string) => void;
  placeholderLabel: string;
  isSearch: boolean;
  isPassword: boolean;
}

function CustomTextField(props: Props) {
  const [userText, setUserText] = useState('');

  const onChangeText = (text: string) => {
    setUserText(text);
    props.onTextChanged(text);
  };

  const onEnterKeyed = (val: number) => {
    if (val === 13) {
      props.onTextChanged(userText);
    }
  };

  const inputProps = props.isSearch
    ? {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        )
      }
    : undefined;

  return (
    <form className={props.classes.container} noValidate autoComplete="on">
      <TextField
        label={props.placeholderLabel}
        type={props.isPassword ? 'password' : 'text'}
        className={props.classes.textField}
        onChange={(e) => onChangeText(e.target.value)}
        onKeyUp={(e) => onEnterKeyed(e.keyCode)}
        margin="normal"
        variant="outlined"
        InputProps={inputProps}
      />
    </form>
  );
}

const styles: Styles<Theme, {}> = {
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    width: '275px',
    '& fieldset': {
      borderRadius: uiParams.RAD_GLOBAL // Outline radius
    },
    borderRadius: uiParams.RAD_GLOBAL, // Field radius
    backgroundColor: uiParams.BG_TEXT_FLD
  }
};

export default withStyles(styles)(CustomTextField);
