import React from 'react';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { CircularProgress } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { ADDONS_CAT } from '../../config/constants';
import { capitalizeWord } from '../../utils/commonUtils';
import HotPlate from '../../assets/plate.svg';
import { DishListItem } from './DishListItem';
import { DispatchOperation, DispatcherBox, DispatcherDish } from './domain';

interface Props {
  classes: ClassNameMap;
  box: DispatcherBox;
  index: number;
  isLoading: boolean;
  onEditBoxInfoButtonClick: () => void;
  onPackBoxButtonClick: (operation: DispatchOperation) => void;
  onPackDishButtonClick: (
    dish: DispatcherDish,
    operation: DispatchOperation
  ) => void;
  updatingDishes: string[];
}

export default function BoxListItem(props: Props) {
  const isAddonsBox = props.box.uniqueIdentifier.includes(ADDONS_CAT) ?? false;
  const hasPlate = props.box.plateId !== null;

  const title = ((): string => {
    if (props.box.name) {
      return props.box.name;
    } else if (isAddonsBox) {
      return `Addons Box ${props.index + 1}`;
    } else {
      return `${capitalizeWord(props.box.size)} Box ${
        props.index + 1
      } ${capitalizeWord(props.box.type)}`;
    }
  })();

  const isHotBox = props.box.type === 'hot' && !isAddonsBox;

  const totalDishesCount = props.box.dishes.reduce(
    (sum, dish) =>
      sum + dish.users.reduce((sum, user) => sum + user.orderedQuantity, 0),
    0
  );

  const totalPackedDishesCount = props.box.dishes.reduce(
    (sum, dish) =>
      sum + dish.users.reduce((sum, user) => sum + user.packedQuantity, 0),
    0
  );

  const totalUnpackedDishesCount = totalDishesCount - totalPackedDishesCount;
  const isBoxPacked = totalDishesCount === totalPackedDishesCount;

  return (
    <div className={props.classes.box}>
      {props.isLoading && (
        <div className={props.classes.overlayLoaderContainer}>
          <CircularProgress />
        </div>
      )}
      <div className={props.classes.boxHeader}>
        <div
          className={props.classes.headerSectionCold}
          role="button"
          tabIndex={props.index}
          onClick={props.onEditBoxInfoButtonClick}
          onKeyDown={() => {}}
        >
          <Edit className={props.classes.editBoxName} />
          {title}
        </div>
        {isHotBox ? (
          <div
            className={[
              props.classes.iconWrapper,
              hasPlate ? props.classes.platePresent : props.classes.disabled
            ].join(' ')}
            role="button"
            tabIndex={props.index}
            onKeyDown={() => {}}
            onClick={props.onEditBoxInfoButtonClick}
          >
            <img
              src={HotPlate}
              className={props.classes.plateIcon}
              alt="Hot Plate"
            />
          </div>
        ) : null}
        <div style={{ display: 'flex' }}>
          <div
            className={[
              props.classes.dishQuantity,
              props.classes.hoverEffect
            ].join(' ')}
            onClick={() => props.onPackBoxButtonClick('pack')}
          >
            {totalUnpackedDishesCount}
          </div>
          <div
            className={[
              props.classes.dishQuantity,
              props.classes.dishQuantityPacked,
              props.classes.hoverEffect
            ].join(' ')}
            onClick={() => props.onPackBoxButtonClick('unpack')}
          >
            {totalPackedDishesCount}
          </div>
        </div>
      </div>
      <div>
        {isBoxPacked ? (
          <div
            className={props.classes.packedBoxOverlay}
            style={{
              height: `${props.box.dishes.length * 48}px`
            }}
          />
        ) : null}
        {props.box.dishes.map((dish, index) => (
          <DishListItem
            key={dish.id}
            index={index}
            classes={props.classes}
            dish={dish}
            onPackButtonClick={(operation) =>
              props.onPackDishButtonClick(dish, operation)
            }
            isUpdating={props.updatingDishes.includes(
              `${props.box.uniqueIdentifier}-${dish.id}`
            )}
          />
        ))}
      </div>
    </div>
  );
}
