/* global Notification:readonly */
import firebase from '@firebase/app';
import '@firebase/messaging';
import 'firebase/analytics';
import { firebaseConfig } from '../config/firebaseConfig';
import { FirebaseMessaging } from '@firebase/messaging-types';

const firebaseApp = firebase.initializeApp(firebaseConfig);

const messaging: FirebaseMessaging | null = (() => {
  if (firebase.messaging.isSupported()) {
    return firebaseApp.messaging();
  } else {
    return null;
  }
})();

if (messaging) {
  messaging.usePublicVapidKey('Key');
}

// The firebase/analytics package most likely extends firebase without declaring the types
// @ts-ignore
const analytics = firebase.analytics();

export async function requestFirebaseNotificationPermission(): Promise<string> {
  if (messaging) {
    const getNotificationToken = async (): Promise<string> => {
      const token = await messaging.getToken({
        vapidKey: process.env.REACT_APP_FIREBASE_PUBLIC_VAPID_KEY
      });

      analytics.logEvent('token issued!');
      return token;
    };

    switch (Notification.permission) {
      case 'granted':
        return getNotificationToken();
      case 'default':
        const permission = await Notification.requestPermission();

        if (permission === 'granted') {
          return getNotificationToken();
        } else {
          return Promise.reject(
            new Error('Permission for notifications was denied')
          );
        }
      case 'denied':
        return Promise.reject(Notification.permission);
    }
  } else {
    return Promise.reject(new Error('Firebase messaging is not supported'));
  }
}
