import { DispatcherBox, DispatcherBranch, DispatcherDish } from '../domain';
import { TodoList } from './TodoList';
import { Zipper } from './Zipper';

type ColdDispatcherBox = Omit<DispatcherBox, 'type'> & {
  type: 'cold';
};

type HotDispatcherBox = Omit<DispatcherBox, 'type'> & {
  type: 'hot';
};

type DispatcherDishState = Omit<DispatcherDish, 'users'> & {
  totalQty: number;
  packedQty: number;
};

function dishStateEq(a: DispatcherDishState, b: DispatcherDishState): boolean {
  return a.id === b.id;
}

function dispatcherDishStateFromDispatcherDish(
  dish: DispatcherDish
): DispatcherDishState {
  const { users, ...rest } = dish;

  return {
    ...rest,
    totalQty: users.reduce((sum, user) => sum + user.orderedQuantity, 0),
    packedQty: users.reduce((sum, user) => sum + user.packedQuantity, 0)
  };
}

type IdleScanningBranchState = {
  type: 'Idle';
  branch: DispatcherBranch;
};

function getBoxState(
  branch: DispatcherBranch,
  boxes: Zipper<DispatcherBox>
): EmptyDishScanningBranchState | null {
  const box = boxes.getCurrent();

  if (box === null) return null;

  const dishes = box.dishes.map(dispatcherDishStateFromDispatcherDish);
  const doneDishes = dishes.filter((dish) => dish.totalQty === dish.packedQty);
  const todo = dishes.filter((dish) => dish.totalQty > dish.packedQty);

  if (todo.length === 0) {
    return null;
  } else {
    return {
      type: 'EmptyDish',
      dishes: TodoList.fromState(dishStateEq, doneDishes, todo),
      boxes,
      box,
      branch
    };
  }
}

function getNextBoxState(
  branch: DispatcherBranch,
  boxes: Zipper<DispatcherBox>
): ScanningBranchState {
  let nextBoxesState = Zipper.fromSelf(boxes);
  let box: DispatcherBox | null;

  while ((box = nextBoxesState.getCurrent()) !== null) {
    switch (box.type) {
      case 'cold':
        if (box.name === null) {
          return {
            type: 'BoxName',
            boxes: nextBoxesState,
            box,
            branch
          };
        } else {
          const boxState = getBoxState(branch, nextBoxesState);

          if (boxState !== null) {
            return boxState;
          } else {
            nextBoxesState = nextBoxesState.shift();
            continue;
          }
        }
      case 'hot':
        if (box.name === null) {
          return {
            type: 'BoxName',
            boxes: nextBoxesState,
            box,
            branch
          };
        } else if (box.plateId === null) {
          return {
            type: 'BoxPlateId',
            boxes: nextBoxesState,
            box: box as HotDispatcherBox,
            branch,
            boxName: box.name
          };
        } else {
          const boxState = getBoxState(branch, nextBoxesState);

          if (boxState !== null) {
            return boxState;
          } else {
            nextBoxesState = nextBoxesState.shift();
            continue;
          }
        }
    }
  }

  return {
    type: 'Idle',
    branch
  };
}

export function initialState(branch: DispatcherBranch): ScanningBranchState {
  return getNextBoxState(branch, Zipper.fromArray(branch.boxes));
}

type BoxNameScanningBranchState = {
  type: 'BoxName';
  branch: DispatcherBranch;
  boxes: Zipper<DispatcherBox>;
  box: DispatcherBox;
};

type BoxPlateIdScanningBranchState = {
  type: 'BoxPlateId';
  branch: DispatcherBranch;
  boxes: Zipper<DispatcherBox>;
  box: HotDispatcherBox;
  boxName: string;
};

type ScannedColdBoxScanningBranchState = {
  type: 'ScannedColdBox';
  branch: DispatcherBranch;
  boxes: Zipper<DispatcherBox>;
  box: ColdDispatcherBox;
  boxName: string;
};

type ScannedHotBoxScanningBranchState = {
  type: 'ScannedHotBox';
  branch: DispatcherBranch;
  boxes: Zipper<DispatcherBox>;
  box: DispatcherBox;
  boxName: string;
  boxPlateId: string;
};

export type ScannedBoxScanningBranchState =
  | ScannedColdBoxScanningBranchState
  | ScannedHotBoxScanningBranchState;

type EmptyDishScanningBranchState = {
  type: 'EmptyDish';
  branch: DispatcherBranch;
  boxes: Zipper<DispatcherBox>;
  box: DispatcherBox;
  dishes: TodoList<DispatcherDishState>;
};

type DishBowlCodeScanningBranchState = {
  type: 'DishBowlCode';
  branch: DispatcherBranch;
  boxes: Zipper<DispatcherBox>;
  box: DispatcherBox;
  dishes: TodoList<DispatcherDishState>;
  dish: DispatcherDishState;
  dishLabel: string;
};

type DuplicateBowlCodeErrorScanningBranchState = {
  type: 'DuplicateBowlCodeError';
  previousState: EmptyDishScanningBranchState | DishBowlCodeScanningBranchState;
};

type DishLabelScanningBranchState = {
  type: 'DishLabel';
  branch: DispatcherBranch;
  boxes: Zipper<DispatcherBox>;
  box: DispatcherBox;
  dishes: TodoList<DispatcherDishState>;
  dishBowlCode: string;
};

type WrongLabelErrorScanningBranchState = {
  type: 'WrongLabel';
  previousState: EmptyDishScanningBranchState | DishLabelScanningBranchState;
};

export type ScannedDishScanningBranchState = {
  type: 'ScannedDish';
  branch: DispatcherBranch;
  boxes: Zipper<DispatcherBox>;
  box: DispatcherBox;
  dishes: TodoList<DispatcherDishState>;
  dish: DispatcherDishState;
  dishLabel: string;
  dishBowlCode: string;
};

export type BoxScanningBranchState =
  | BoxNameScanningBranchState
  | BoxPlateIdScanningBranchState
  | ScannedBoxScanningBranchState;

export type DishScanningBranchState =
  | EmptyDishScanningBranchState
  | DishBowlCodeScanningBranchState
  | DishLabelScanningBranchState
  | ScannedDishScanningBranchState
  | DuplicateBowlCodeErrorScanningBranchState
  | WrongLabelErrorScanningBranchState;

export type ScanningBranchState =
  | IdleScanningBranchState
  | BoxScanningBranchState
  | DishScanningBranchState;

type UpdateBoxNameAction = {
  type: 'UpdateBoxName';
  boxName: string;
};

export function updateBoxName(boxName: string): UpdateBoxNameAction {
  return {
    type: 'UpdateBoxName',
    boxName
  };
}

type UpdateBoxPlateIdAction = {
  type: 'UpdateBoxPlateId';
  boxPlateId: string;
};

export function updateBoxPlateId(boxPlateId: string): UpdateBoxPlateIdAction {
  return {
    type: 'UpdateBoxPlateId',
    boxPlateId
  };
}

type UpdateDishLabelAction = {
  type: 'ScanDishLabel';
  dishLabel: string;
};

export function updateDishLabel(dishLabel: string): UpdateDishLabelAction {
  return {
    type: 'ScanDishLabel',
    dishLabel
  };
}

type UpdateDishBowlCodeAction = {
  type: 'ScanDishBowlCode';
  bowlCode: string;
  allBowlCodes: string[];
};

export function updateDishBowlCode(
  bowlCode: string,
  allBowlCodes: string[]
): UpdateDishBowlCodeAction {
  return {
    type: 'ScanDishBowlCode',
    bowlCode: bowlCode,
    allBowlCodes: allBowlCodes
  };
}

type UpdateBoxAction = {
  type: 'UpdateBox';
};

export function updateBox(): UpdateBoxAction {
  return {
    type: 'UpdateBox'
  };
}

type UpdateDishAction = {
  type: 'UpdateDish';
};

export function updateDish(): UpdateDishAction {
  return {
    type: 'UpdateDish'
  };
}

type SkipBoxAction = {
  type: 'SkipBox';
};

export function skipBox(): SkipBoxAction {
  return {
    type: 'SkipBox'
  };
}

type ResetDishScanAction = {
  type: 'ResetDishScan';
};

export function resetDishScan(): ResetDishScanAction {
  return {
    type: 'ResetDishScan'
  };
}

export type ScanningBranchPopupAction =
  | UpdateBoxNameAction
  | UpdateBoxPlateIdAction
  | UpdateBoxAction
  | UpdateDishLabelAction
  | UpdateDishBowlCodeAction
  | UpdateDishAction
  | SkipBoxAction
  | ResetDishScanAction;

export function scanningBranchPopupReducer(
  state: ScanningBranchState,
  action: ScanningBranchPopupAction
): ScanningBranchState {
  switch (action.type) {
    case 'UpdateBoxName':
      switch (state.type) {
        case 'BoxName':
          const boxes = state.boxes.mapCurrent((box) => ({
            ...box,
            name: action.boxName
          }));

          const box = boxes.getCurrent();

          if (box === null) {
            return state;
          } else if (box.type === 'cold') {
            return {
              type: 'ScannedColdBox',
              boxes,
              box: box as ColdDispatcherBox,
              boxName: action.boxName,
              branch: state.branch
            };
          } else {
            return {
              type: 'BoxPlateId',
              boxes,
              box: box as HotDispatcherBox,
              boxName: action.boxName,
              branch: state.branch
            };
          }
        default:
          return state;
      }
    case 'UpdateBoxPlateId':
      switch (state.type) {
        case 'BoxPlateId':
          const boxes = state.boxes.mapCurrent((box) => ({
            ...box,
            plateId: action.boxPlateId
          }));

          const box = boxes.getCurrent();

          if (box === null) {
            return state;
          } else {
            return {
              type: 'ScannedHotBox',
              boxes,
              box,
              boxName: state.boxName,
              boxPlateId: action.boxPlateId,
              branch: state.branch
            };
          }
        default:
          return state;
      }
    case 'UpdateBox':
      switch (state.type) {
        case 'ScannedColdBox':
        case 'ScannedHotBox':
          const box = state.boxes.getCurrent();

          if (box === null) {
            return state;
          } else {
            return {
              type: 'EmptyDish',
              boxes: state.boxes,
              dishes: TodoList.make(
                dishStateEq,
                box.dishes.map(dispatcherDishStateFromDispatcherDish)
              ),
              box,
              branch: state.branch
            };
          }
        default:
          return state;
      }
    case 'ScanDishLabel':
      switch (state.type) {
        case 'EmptyDish':
        case 'DishLabel':
        case 'WrongLabel':
          const data = (() => {
            switch (state.type) {
              case 'EmptyDish':
              case 'DishLabel':
                return state;
              case 'WrongLabel':
                return state.previousState;
            }
          })();

          const allUnpackedBoxLabels = data.dishes
            .getTodo()
            .flatMap((dish) => dish.label);

          if (allUnpackedBoxLabels.includes(action.dishLabel)) {
            const dish = data.dishes
              .getTodo()
              .find((dish) => dish.label === action.dishLabel);

            if (typeof dish === 'undefined') return state;

            switch (data.type) {
              case 'EmptyDish':
                return {
                  type: 'DishBowlCode',
                  dish,
                  dishes: data.dishes.setDoing(dish),
                  dishLabel: action.dishLabel,
                  box: data.box,
                  boxes: data.boxes,
                  branch: data.branch
                };
              case 'DishLabel':
                return {
                  type: 'ScannedDish',
                  dish,
                  dishes: data.dishes.setDone(dish),
                  dishLabel: action.dishLabel,
                  dishBowlCode: data.dishBowlCode,
                  box: data.box,
                  boxes: data.boxes,
                  branch: data.branch
                };
              default:
                return state;
            }
          } else {
            return {
              type: 'WrongLabel',
              previousState: data
            };
          }
        default:
          return state;
      }
    case 'ScanDishBowlCode':
      switch (state.type) {
        case 'EmptyDish':
        case 'DishBowlCode':
        case 'DuplicateBowlCodeError':
          const data = (() => {
            switch (state.type) {
              case 'EmptyDish':
              case 'DishBowlCode':
                return state;
              case 'DuplicateBowlCodeError':
                return state.previousState;
            }
          })();

          if (action.allBowlCodes.includes(action.bowlCode)) {
            return {
              type: 'DuplicateBowlCodeError',
              previousState: data
            };
          } else {
            switch (data.type) {
              case 'EmptyDish':
                return {
                  type: 'DishLabel',
                  dishes: data.dishes,
                  dishBowlCode: action.bowlCode,
                  box: data.box,
                  boxes: data.boxes,
                  branch: data.branch
                };
              case 'DishBowlCode':
                return {
                  type: 'ScannedDish',
                  dish: data.dish,
                  dishes: data.dishes.setDone(data.dish),
                  dishLabel: data.dishLabel,
                  dishBowlCode: action.bowlCode,
                  box: data.box,
                  boxes: data.boxes,
                  branch: data.branch
                };
              default:
                return state;
            }
          }
        default:
          return state;
      }
    case 'UpdateDish':
      switch (state.type) {
        case 'ScannedDish':
          const currentBox = state.boxes.getCurrent();

          if (currentBox === null) return state;

          const boxes = state.boxes.mapCurrent((box) => ({
            ...box,
            dishes: box.dishes.map((dish) => {
              if (dish.id === state.dish.id) {
                let didPack = false;

                return {
                  ...dish,
                  bowlCodes: [...dish.bowlCodes, state.dishBowlCode],
                  users: dish.users.map((user) => {
                    if (
                      !didPack &&
                      user.orderedQuantity > user.packedQuantity
                    ) {
                      didPack = true;

                      return {
                        ...user,
                        packedQuantity: user.packedQuantity + 1
                      };
                    } else {
                      return user;
                    }
                  })
                };
              } else {
                return dish;
              }
            })
          }));

          return getNextBoxState(
            {
              ...state.branch,
              boxes: boxes.toArray()
            },
            boxes
          );
        default:
          return state;
      }
    case 'ResetDishScan':
      switch (state.type) {
        case 'DishBowlCode':
        case 'DishLabel':
          return {
            type: 'EmptyDish',
            box: state.box,
            boxes: state.boxes,
            branch: state.branch,
            dishes: state.dishes.cancelDoing()
          };
        case 'DuplicateBowlCodeError':
        case 'WrongLabel':
          return {
            type: 'EmptyDish',
            box: state.previousState.box,
            boxes: state.previousState.boxes,
            branch: state.previousState.branch,
            dishes: state.previousState.dishes.cancelDoing()
          };
        default:
          return state;
      }
    case 'SkipBox':
      switch (state.type) {
        case 'BoxName':
        case 'BoxPlateId':
        case 'EmptyDish':
        case 'DishLabel':
        case 'DishBowlCode':
          return getNextBoxState(state.branch, state.boxes.shift());
        default:
          return state;
      }
  }
}
