import moment from 'moment';
import React, { useEffect, useState } from 'react';
import OrderForecastContext from '..';
import { getQrData } from '../../../../api/kitchen';
import { getInitialSelectedDate } from '../../../../utils/commonUtils';

const VytalCountContextProvider = ({ children }) => {
  const [selectedDate, setSelectedDate] = useState(getInitialSelectedDate());
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getQrData(moment(selectedDate).format('YYYY-MM-DD')).then((res) => {
      setTableData(
        res.map((ele) => {
          ele.count = ele?.qr?.length;
          return ele;
        })
      );
    });
  }, [selectedDate]);

  return (
    <OrderForecastContext.Provider
      value={{
        tableDataState: [tableData, setTableData],
        dateState: [selectedDate, setSelectedDate]
      }}
    >
      {children}
    </OrderForecastContext.Provider>
  );
};

export default VytalCountContextProvider;
