import {
  ROLE_KITCHEN,
  ROLE_CHEF_SALAD,
  ROLE_CHEF_COLDBOWL,
  ROLE_CHEF_CLASSIC,
  ROLE_CHEF_CATERING,
  ROLE_CHEF_HOTBOWL,
  ROLE_STAFF
} from './constants';

const navigationList = [
  {
    path: '/kitchen',
    name: 'Kitchen',
    iconName: 'kitchen',
    role: [
      ROLE_KITCHEN,
      ROLE_STAFF,
      ROLE_CHEF_SALAD,
      ROLE_CHEF_COLDBOWL,
      ROLE_CHEF_CLASSIC,
      ROLE_CHEF_CATERING,
      ROLE_CHEF_HOTBOWL
    ]
  },
  {
    path: '/cateringkitchen',
    name: 'Catering Kitchen',
    iconName: 'cateringkitchen',
    role: [
      ROLE_KITCHEN,
      ROLE_STAFF,
      ROLE_CHEF_SALAD,
      ROLE_CHEF_COLDBOWL,
      ROLE_CHEF_CLASSIC,
      ROLE_CHEF_CATERING,
      ROLE_CHEF_HOTBOWL
    ]
  },
  // {
  // 	path: '/addOns',
  // 	name: 'Add-on Orders',
  // 	iconName: 'addons',
  // 	role: [ROLE_KITCHEN]
  // },
  {
    path: '/dispatcher',
    name: 'Dispatcher',
    iconName: 'dispatcher',
    role: [ROLE_KITCHEN, ROLE_STAFF]
  },
  {
    path: '/weekly',
    name: 'Weekly',
    iconName: 'weekly',
    role: [ROLE_KITCHEN]
  },
  {
    path: '/orderForecast',
    name: 'Order Forecast',
    iconName: 'orderForecast',
    role: [ROLE_KITCHEN]
  },
  {
    path: '/kitchenCount',
    name: 'Kitchen Count',
    iconName: 'kitchenCount',
    role: [ROLE_KITCHEN]
  },
  {
    path: '/vytalCount',
    name: 'Vytal Count ',
    iconName: 'vytalCount',
    role: [ROLE_KITCHEN]
  },
  {
    path: '/auth',
    name: 'Log Out',
    iconName: 'logout',
    role: []
  }
];
export default navigationList;
