import { AuthTokens } from '../api/auth';

interface StorageMap {
  USER_SELECTED_DATE: Date;
  user: AuthTokens;
}

export function setLocalStorage<K extends keyof StorageMap>(
  key: K,
  value: StorageMap[K]
): void {
  if (typeof value === 'string') {
    localStorage.setItem(key, value);
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }
}

export function getLocalStorage<K extends keyof StorageMap>(
  key: K
): StorageMap[K] | null {
  const data = localStorage.getItem(key);

  if (data) {
    switch (key) {
      case 'USER_SELECTED_DATE':
        return new Date(data.substring(1, data.length - 1)) as StorageMap[K];
      case 'user':
        try {
          return JSON.parse(data);
        } catch (e) {
          return null;
        }
      default:
        return null;
    }
  } else {
    return null;
  }
}

export function clearLocalStorage<K extends keyof StorageMap>(key: K): void {
  localStorage.removeItem(key);
}
